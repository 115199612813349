import axios from "axios";
// #429: avoid display error caused by delay
var CancelToken = axios.CancelToken;
let rpc_cancel, rbg_cancel, tt_cancel, trbc_cancel;

import {
  GET_USER_KPI_SUMMARY,
  GET_ALLOCATED_BY_CHAIN,
  GET_ALLOCATED_BY_CLINIC,
  GET_REFERENCES_CHAIN,
  GET_REFERENCES_CLINIC,
  GET_CHAIN_KPI_PLANNING_DATA,
  UPDATE_CHAIN_PROPOSE_KPI,
  GET_CLINIC_KPI_PLANNING_DATA,
  UPDATE_CLINIC_PROPOSE_KPI,
  GET_DOCTOR_KPI_PLANNING_DATA,
  UPDATE_DOCTOR_CONFIRMED_KPI,
  UPDATE_CLINIC_CONFIRMED_KPI,
  UPDATE_CHAIN_CONFIRMED_KPI,
  GET_DOCTOR_PERFORMANCE_SUMMARY_COMPONENTS,
  GET_ALL_DOCTORS_TOTAL_REVENUE,
  GET_DOCTOR_PRS_CLINICS,
  GET_DOCTOR_PRS_TREATMENT,
  GET_TOTAL_REVENUE_BAR_GRAPH,
  GET_TOTAL_REVENUE_BAR_CHART,
  GET_TOTAL_REVENUE_PIE_CHART,
  GET_TREATMENT_TARGET,
  KPI_ERROR,
  POST_CHAIN_PROPOSE_KPI,
} from "../constants/actionsTypes";
import ENVIRONMENT_VARIABLES from "../config";
import { disconnect } from "../socket";
import { createBrowserHistory } from "history";
import { Routing } from "routes";
import { dispatch } from "d3-dispatch";
import swal from "sweetalert";

const history = createBrowserHistory();

export const saveChainKpiData = (data) => {
  localStorage.setItem("_kpi_chain_data", JSON.stringify(data));
};

export const getChainKpiData = () => {
  const data = localStorage.getItem("_kpi_chain_data");
  if (data) {
    return JSON.parse(data);
  }

  return null;
};

export const getTreatmentTarget = (
  chainId,
  selectedClinics,
  selectedTreatments,
  selectedDateRange
) => {
  try {
    // #429: avoid display error caused by delay
    tt_cancel && tt_cancel();
    return (dispatch) => {
      const token = "Bearer " + localStorage.getItem("accessToken");
      let api = {
        method: "POST",
        headers: { Authorization: token },
        url:
          ENVIRONMENT_VARIABLES.Base_API_URL + "/Treatments/getTreatmentTarget",
        data: {
          chainId,
          selectedClinics,
          selectedTreatments,
          filterStartDate: selectedDateRange.startDate,
          filterEndDate: selectedDateRange.endDate,
        },
        cancelToken: new CancelToken(function executor(c) {
          tt_cancel = c;
        })
      };
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: GET_TREATMENT_TARGET,
              data: response.data,
            });
          } else {
            swal({
              title: "Something wrong!",
              text: response.data.user_msg,
              icon: "warning",
              buttons: true,
              dangerMode: true,
            });
          }
        })
        .catch((error) => {
          console.log("#429 ERROR: ", error);
          if (
            error &&
            error.response &&
            (error.response.status === 400 ||
              error.response.status === 403 ||
              error.response.status === 401)
          ) {
            dispatch({
              type: KPI_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: KPI_ERROR,
              data: { error_msg: error.message },
            });
          }
        });
    };
  } catch (error) {
    swal({
      title: "Something wrong!",
      text: error.message,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });
  }
};
export const getTotalRevenueBarGraph = (
  chainId,
  selectedClinics,
  selectedTreatments,
  selectedDateRange
) => {
  try {
    // #429: avoid display error caused by delay
    rbg_cancel && rbg_cancel()
    return (dispatch) => {
      const token = "Bearer " + localStorage.getItem("accessToken");
      let api = {
        method: "POST",
        headers: { Authorization: token },
        url:
          ENVIRONMENT_VARIABLES.Base_API_URL +
          "/Graphs/getTotalRevenueBarGraph",
        data: {
          chainId,
          selectedClinics,
          selectedTreatments,
          filterStartDate: selectedDateRange.startDate,
          filterEndDate: selectedDateRange.endDate,
        },
        cancelToken: new CancelToken(function executor(c) {
          rbg_cancel = c;
        })
      };
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: GET_TOTAL_REVENUE_BAR_GRAPH,
              data: response.data,
            });
          } else {
            swal({
              title: "Something wrong!",
              text: response.data.user_msg,
              icon: "warning",
              buttons: true,
              dangerMode: true,
            });
          }
        })
        .catch((error) => {
          console.log("#429 ERROR: ", error);
          if (
            error &&
            error.response &&
            (error.response.status === 400 ||
              error.response.status === 403 ||
              error.response.status === 401)
          ) {
            dispatch({
              type: KPI_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: KPI_ERROR,
              data: { error_msg: error.message },
            });
          }
        });
    };
  } catch (error) {
    swal({
      title: "Something wrong!",
      text: error.message,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });
  }
};
export const getTotalRevenueBarChart = (
  chainId,
  selectedClinics,
  selectedTreatments,
  selectedDateRange
) => {
  try {
    // #429: avoid display error caused by delay
    trbc_cancel && trbc_cancel()
    return (dispatch) => {
      const token = "Bearer " + localStorage.getItem("accessToken");
      let api = {
        method: "POST",
        headers: { Authorization: token },
        url:
          ENVIRONMENT_VARIABLES.Base_API_URL +
          "/Graphs/getTotalRevenueBarChart",
        data: {
          chainId,
          selectedClinics,
          selectedTreatments,
          filterStartDate: selectedDateRange.startDate,
          filterEndDate: selectedDateRange.endDate,
        },
        cancelToken: new CancelToken(function executor(c) {
          trbc_cancel = c;
        })
      };
      console.log("#419 ERROR api: ", api);
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: GET_TOTAL_REVENUE_BAR_CHART,
              data: response.data,
            });
          } else {
            swal({
              title: "Something wrong!",
              text: response.data.user_msg,
              icon: "warning",
              buttons: true,
              dangerMode: true,
            });
          }
        })
        .catch((error) => {
          console.log("#429 ERROR: ", error);
          if (
            error &&
            error.response &&
            (error.response.status === 400 ||
              error.response.status === 403 ||
              error.response.status === 401)
          ) {
            dispatch({
              type: KPI_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: KPI_ERROR,
              data: { error_msg: error.message },
            });
          }
        });
    };
  } catch (error) {
    swal({
      title: "Something wrong!",
      text: error.message,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });
  }
};
export const getTotalRevenuePieChart = (
  chainId,
  selectedClinics,
  selectedTreatments,
  selectedDateRange
) => {
  try {
    // #429: avoid display error caused by delay
    rpc_cancel && rpc_cancel()
    return (dispatch) => {
      const token = "Bearer " + localStorage.getItem("accessToken");
      let api = {
        method: "POST",
        headers: { Authorization: token },
        url:
          ENVIRONMENT_VARIABLES.Base_API_URL +
          "/Graphs/getTotalRevenuePieChart",
        data: {
          chainId,
          selectedClinics,
          selectedTreatments,
          filterStartDate: selectedDateRange.startDate,
          filterEndDate: selectedDateRange.endDate,
        },
        cancelToken: new CancelToken(function executor(c) {
          rpc_cancel = c;
        })
      };
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: GET_TOTAL_REVENUE_PIE_CHART,
              data: response.data.data,
            });
          } else {
            swal({
              title: "Something wrong!",
              text: response.data.user_msg,
              icon: "warning",
              buttons: true,
              dangerMode: true,
            });
          }
        })
        .catch((error) => {
          console.log("#429 ERROR: ", error);
          if (
            error &&
            error.response &&
            (error.response.status === 400 ||
              error.response.status === 403 ||
              error.response.status === 401)
          ) {
            dispatch({
              type: KPI_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: KPI_ERROR,
              data: { error_msg: error.message },
            });
          }
        });
    };
  } catch (error) {
    swal({
      title: "Something wrong!",
      text: error.message,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });
  }
};
export const getDoctorsTtlRevenue = (chainId = 1) => {
  try {
    return (dispatch) => {
      const token = "Bearer " + localStorage.getItem("accessToken");
      let api = {
        method: "POST",
        headers: { Authorization: token },
        url:
          ENVIRONMENT_VARIABLES.Base_API_URL +
          "/Doctors/getAllDoctorsTtlRevenue",
        data: { chainId },
      };
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: GET_ALL_DOCTORS_TOTAL_REVENUE,
              data: response.data.data,
            });
          } else {
            swal({
              title: "Something wrong!",
              text: response.data.user_msg,
              icon: "warning",
              buttons: true,
              dangerMode: true,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 ||
              error.response.status === 403 ||
              error.response.status === 401)
          ) {
            dispatch({
              type: KPI_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: KPI_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};
export const getDoctorPRSTreatment = () => {
  try {
    return (dispatch) => {
      const token = "Bearer " + localStorage.getItem("accessToken");
      let api = {
        method: "GET",
        headers: { Authorization: token },
        url:
          ENVIRONMENT_VARIABLES.Base_API_URL +
          "/Treatments/getTreatments/100/1",
      };
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: GET_DOCTOR_PRS_TREATMENT,
              data: response.data.data,
            });
          } else {
            swal({
              title: "Something wrong!",
              text: response.data.user_msg,
              icon: "warning",
              buttons: true,
              dangerMode: true,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 ||
              error.response.status === 403 ||
              error.response.status === 401)
          ) {
            dispatch({
              type: KPI_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: KPI_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};
export const getDoctorPRSClinic = (chainId = 1) => {
  try {
    return (dispatch) => {
      const token = "Bearer " + localStorage.getItem("accessToken");
      let api = {
        method: "POST",
        headers: { Authorization: token },
        url: ENVIRONMENT_VARIABLES.Base_API_URL + "/Clinics",
        data: { chainId },
      };
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: GET_DOCTOR_PRS_CLINICS,
              data: response.data.data,
            });
          } else {
            swal({
              title: "Something wrong!",
              text: response.data.user_msg,
              icon: "warning",
              buttons: true,
              dangerMode: true,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 ||
              error.response.status === 403 ||
              error.response.status === 401)
          ) {
            dispatch({
              type: KPI_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: KPI_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    alert(error.message.toString());
  }
};

export const getDRPSComponent = (chainId = 1, clinicId, doctorId) => {
  try {
    return (dispatch) => {
      const token = "Bearer " + localStorage.getItem("accessToken");
      let api = {
        method: "POST",
        headers: { Authorization: token },
        url: ENVIRONMENT_VARIABLES.Base_API_URL + "/Doctors/getDrPRComponents",
        data: { chainId, clinicId, doctorId },
      };
      axios(api)
        .then((response) => {
          if (response.data.status === 200) {
            dispatch({
              type: GET_DOCTOR_PERFORMANCE_SUMMARY_COMPONENTS,
              data: response.data.data,
            });
          } else {
            swal({
              title: "Something wrong!",
              text: response.data.user_msg,
              icon: "warning",
              buttons: true,
              dangerMode: true,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 ||
              error.response.status === 403 ||
              error.response.status === 401)
          ) {
            dispatch({
              type: KPI_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: KPI_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    swal({
      title: "Something wrong!",
      text: error.message.toString(),
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });
  }
};

export const updateChainConfirmKPI = (chainId = 1, kpiStatus) => {
  try {
    return (dispatch) => {
      const token = "Bearer " + localStorage.getItem("accessToken");
      let api = {
        method: "PUT",
        headers: { Authorization: token },
        url:
          ENVIRONMENT_VARIABLES.Base_API_URL + "/Kpi/updateChainConfirmedKpi",
        data: { chainId, kpiStatus },
      };
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: UPDATE_CHAIN_CONFIRMED_KPI,
              data: response.data.data,
            });
          } else {
            swal({
              title: "Something wrong!",
              text: response.data.user_msg,
              icon: "warning",
              buttons: true,
              dangerMode: true,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 ||
              error.response.status === 403 ||
              error.response.status === 401)
          ) {
            dispatch({
              type: KPI_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: KPI_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    swal({
      title: "Something wrong!",
      text: error.message.toString(),
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });
  }
};

export const updateClinicConfirmKPI = (datas) => {
  try {
    return (dispatch) => {
      const token = "Bearer " + localStorage.getItem("accessToken");
      let api = {
        method: "PUT",
        headers: { Authorization: token },
        url:
          ENVIRONMENT_VARIABLES.Base_API_URL + "/Kpi/updateClinicConfirmedKpi",
        data: { datas },
      };
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: UPDATE_CLINIC_CONFIRMED_KPI,
              data: response.data.data,
            });
          } else {
            swal({
              title: "Something wrong!",
              text: response.data.user_msg,
              icon: "warning",
              buttons: true,
              dangerMode: true,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 ||
              error.response.status === 403 ||
              error.response.status === 401)
          ) {
            dispatch({
              type: KPI_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: KPI_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    swal({
      title: "Something wrong!",
      text: error.message.toString(),
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });
  }
};

export const updateDoctorConfirmKPI = (doctorId, kpiStatus) => {
  try {
    return (dispatch) => {
      const token = "Bearer " + localStorage.getItem("accessToken");
      let api = {
        method: "PUT",
        headers: { Authorization: token },
        url:
          ENVIRONMENT_VARIABLES.Base_API_URL + "/Kpi/updateDoctorConfirmedKpi",
        data: { doctorId, kpiStatus },
      };
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: UPDATE_DOCTOR_CONFIRMED_KPI,
              data: response.data.data,
            });
          } else {
            swal({
              title: "Something wrong!",
              text: response.data.user_msg,
              icon: "warning",
              buttons: true,
              dangerMode: true,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 ||
              error.response.status === 403 ||
              error.response.status === 401)
          ) {
            dispatch({
              type: KPI_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: KPI_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    swal({
      title: "Something wrong!",
      text: error.message.toString(),
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });
  }
};
export const updateClinicProposeKPI = (datas) => {
  try {
    return (dispatch) => {
      const token = "Bearer " + localStorage.getItem("accessToken");
      let api = {
        method: "PUT",
        headers: { Authorization: token },
        url: ENVIRONMENT_VARIABLES.Base_API_URL + "/Kpi/updateClinicProposeKpi",
        data: datas,
      };
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: UPDATE_CLINIC_PROPOSE_KPI,
              data: response.data.data,
            });
          } else {
            swal({
              title: "Something wrong!",
              text: response.data.user_msg,
              icon: "warning",
              buttons: true,
              dangerMode: true,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 ||
              error.response.status === 403 ||
              error.response.status === 401)
          ) {
            dispatch({
              type: KPI_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: KPI_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    swal({
      title: "Something wrong!",
      text: error.message.toString(),
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });
  }
};
export const updateChainProposeKPI = (datas) => {
  try {
    return (dispatch) => {
      const token = "Bearer " + localStorage.getItem("accessToken");
      let api = {
        method: "PUT",
        headers: { Authorization: token },
        url: ENVIRONMENT_VARIABLES.Base_API_URL + "/Kpi/updateChainProposeKpi",
        data: datas,
      };
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: UPDATE_CHAIN_PROPOSE_KPI,
              data: response.data.data,
            });
          } else {
            swal({
              title: "Something wrong!",
              text: response.data.user_msg,
              icon: "warning",
              buttons: true,
              dangerMode: true,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 ||
              error.response.status === 403 ||
              error.response.status === 401)
          ) {
            dispatch({
              type: KPI_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: KPI_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    swal({
      title: "Something wrong!",
      text: error.message.toString(),
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });
  }
};

export const postChainProposeKPI = async (datas) => {
  try {
    const token = "Bearer " + localStorage.getItem("accessToken");
    let api = {
      method: "POST",
      headers: { Authorization: token },
      url: ENVIRONMENT_VARIABLES.Base_API_URL + "/Kpi/postChainProposeKpi",
      data: datas,
    };
    const response = await axios(api);

    if (response.status === 200) {
      return response.data;
    } else {
      await swal({
        title: "Something wrong!",
        text: response.data.user_msg,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      });
    }
  } catch (error) {
    await swal({
      title: "Something wrong!",
      text: error.message.toString(),
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });
  }
};

export const getUserKPISummary = (userID, clinicId, kpiYear) => {
  try {
    return (dispatch) => {
      const token = "Bearer " + localStorage.getItem("accessToken");
      let api = {
        method: "POST",
        headers: { Authorization: token },
        url: ENVIRONMENT_VARIABLES.Base_API_URL + "/Kpi/getUserKPISummary",
        data: { userID: userID, year: kpiYear, clinicId: clinicId },
      };

      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: GET_USER_KPI_SUMMARY,
              data: response.data,
            });
          } else {
            swal({
              title: "Something wrong!",
              text: response.data.user_msg,
              icon: "warning",
              buttons: true,
              dangerMode: true,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 ||
              error.response.status === 403 ||
              error.response.status === 401)
          ) {
            dispatch({
              type: KPI_ERROR,
              data: { user_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: KPI_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    swal({
      title: "Something wrong!",
      text: error.message.toString(),
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });
  }
};

export const getAllocatedByChain = (chainID = 1, clinicId) => {
  try {
    return (dispatch) => {
      const token = "Bearer " + localStorage.getItem("accessToken");
      let api = {
        method: "POST",
        headers: { Authorization: token },
        url: ENVIRONMENT_VARIABLES.Base_API_URL + "/Allocate/getAllocatesBy",
        data: { chainID, clinicId },
      };
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: GET_ALLOCATED_BY_CHAIN,
              data: response.data.resData,
            });
          } else {
            swal({
              title: "Something wrong!",
              text: response.data.user_msg,
              icon: "warning",
              buttons: true,
              dangerMode: true,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 ||
              error.response.status === 403 ||
              error.response.status === 401)
          ) {
            dispatch({
              type: KPI_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: KPI_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    swal({
      title: "Something wrong!",
      text: error.message.toString(),
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });
  }
};

export const getAllocatedByClinic = (clinicId) => {
  try {
    return (dispatch) => {
      const token = "Bearer " + localStorage.getItem("accessToken");
      let api = {
        method: "POST",
        headers: { Authorization: token },
        url: ENVIRONMENT_VARIABLES.Base_API_URL + "/Allocate/getAllocatesBy",
        data: { clinicId },
      };
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: GET_ALLOCATED_BY_CLINIC,
              data: response.data.resData,
            });
          } else {
            swal({
              title: "Something wrong!",
              text: response.data.user_msg,
              icon: "warning",
              buttons: true,
              dangerMode: true,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 ||
              error.response.status === 403 ||
              error.response.status === 401)
          ) {
            dispatch({
              type: KPI_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: KPI_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    swal({
      title: "Something wrong!",
      text: error.message.toString(),
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });
  }
};

export const getDoctorKPIPlanningData = (doctorId, referenceId, _id = 0, year) => {
  try {
    return (dispatch) => {
      const token = "Bearer " + localStorage.getItem("accessToken");
      let api = {
        method: "POST",
        headers: { Authorization: token },
        url:
          ENVIRONMENT_VARIABLES.Base_API_URL + "/Kpi/getDoctorKpiPlanningData",
        data: {
          _id,
          chainId: 1,
          doctorId,
          referenceId: referenceId ? referenceId : 1,
          year
        },
      };
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: GET_DOCTOR_KPI_PLANNING_DATA,
              data: response.data,
            });
          } else {
            swal({
              title: "Something wrong!",
              text: response.data.user_msg,
              icon: "warning",
              buttons: true,
              dangerMode: true,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 ||
              error.response.status === 403 ||
              error.response.status === 401)
          ) {
            dispatch({
              type: KPI_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: KPI_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    swal({
      title: "Something wrong!",
      text: error.message.toString(),
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });
  }
};

export const getClinicKPIPlanningData = (
  clinicId = 1,
  referenceId,
  _id = 0,
  year
) => {
  try {
    return (dispatch) => {
      const token = "Bearer " + localStorage.getItem("accessToken");
      let api = {
        method: "POST",
        headers: { Authorization: token },
        url:
          ENVIRONMENT_VARIABLES.Base_API_URL + "/Kpi/getClinicKpiPlanningData",
        // TODO Tran Remove hard code chain id 1
        data: {
          _id,
          chainId: 1,
          clinicId,
          referenceId: referenceId ? referenceId : 1,
          year,
        },
      };
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: GET_CLINIC_KPI_PLANNING_DATA,
              data: response.data,
            });
          } else {
            swal({
              title: "Something wrong!",
              text: response.data.user_msg,
              icon: "warning",
              buttons: true,
              dangerMode: true,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 ||
              error.response.status === 403 ||
              error.response.status === 401)
          ) {
            dispatch({
              type: KPI_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: KPI_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    swal({
      title: "Something wrong!",
      text: error.message.toString(),
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });
  }
};

export const getChainKPIPlanningData = (chainId = 1, referenceId, _id = 0, year) => {
  try {
    return (dispatch) => {
      const token = "Bearer " + localStorage.getItem("accessToken");
      let api = {
        method: "POST",
        headers: { Authorization: token },
        url:
          ENVIRONMENT_VARIABLES.Base_API_URL + "/Kpi/getChainKpiPlanningData",
        data: { chainId, referenceId: referenceId ? referenceId : 1, _id: _id || 0, year },
      };
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: GET_CHAIN_KPI_PLANNING_DATA,
              data: response.data,
            });
          } else {
            swal({
              title: "Something wrong!",
              text: response.data.user_msg,
              icon: "warning",
              buttons: true,
              dangerMode: true,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 ||
              error.response.status === 403 ||
              error.response.status === 401)
          ) {
            dispatch({
              type: KPI_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: KPI_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    swal({
      title: "Something wrong!",
      text: error.message.toString(),
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });
  }
};

export const getReferenceByClinic = (chainID = 1, clinicID) => {
  try {
    return (dispatch) => {
      const token = "Bearer " + localStorage.getItem("accessToken");
      let api = {
        method: "POST",
        headers: { Authorization: token },
        url: ENVIRONMENT_VARIABLES.Base_API_URL + "/References/getReferences",
        data: { chainID, clinicID },
      };
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: GET_REFERENCES_CLINIC,
              data: response.data.resData,
            });
          } else {
            swal({
              title: "Something wrong!",
              text: response.data.user_msg,
              icon: "warning",
              buttons: true,
              dangerMode: true,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 ||
              error.response.status === 403 ||
              error.response.status === 401)
          ) {
            dispatch({
              type: KPI_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: KPI_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    swal({
      title: "Something wrong!",
      text: error.message.toString(),
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });
  }
};

export const getReferenceByChain = (chainID = 1, clinicId) => {
  try {
    return (dispatch) => {
      const token = "Bearer " + localStorage.getItem("accessToken");
      let api = {
        method: "POST",
        headers: { Authorization: token },
        url: ENVIRONMENT_VARIABLES.Base_API_URL + "/References/getReferences",
        data: { chainID, clinicId },
      };
      axios(api)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: GET_REFERENCES_CHAIN,
              data: response.data.resData,
            });
          } else {
            swal({
              title: "Something wrong!",
              text: response.data.user_msg,
              icon: "warning",
              buttons: true,
              dangerMode: true,
            });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            (error.response.status === 400 ||
              error.response.status === 403 ||
              error.response.status === 401)
          ) {
            dispatch({
              type: KPI_ERROR,
              data: { error_msg: error.response.data.user_msg },
            });
          } else {
            dispatch({
              type: KPI_ERROR,
              data: { error_msg: error.message.toString() },
            });
          }
        });
    };
  } catch (error) {
    swal({
      title: "Something wrong!",
      text: error.message.toString(),
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });
  }
};

export const postClinicProposeKpi = async (datas) => {
  try {
    const token = "Bearer " + localStorage.getItem("accessToken");
    let api = {
      method: "POST",
      headers: { Authorization: token },
      url: ENVIRONMENT_VARIABLES.Base_API_URL + "/Kpi/postClinicProposeKpi",
      data: datas,
    };
    const response = await axios(api);

    if (response.status === 200) {
      return response.data;
    } else {
      swal({
        title: "Something wrong!",
        text: response.data.user_msg,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      });
    }
  } catch (error) {
    swal({
      title: "Something wrong!",
      text: error.message.toString(),
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });
  }
};

export const postDoctorProposeKpi = async (datas) => {
  try {
    const token = "Bearer " + localStorage.getItem("accessToken");
    let api = {
      method: "POST",
      headers: { Authorization: token },
      url: ENVIRONMENT_VARIABLES.Base_API_URL + "/Kpi/postDoctorProposeKpi",
      data: datas,
    };
    const response = await axios(api);

    if (response.status === 200) {
      return response.data;
    } else {
      swal({
        title: "Something wrong!",
        text: response.data.user_msg,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      });
    }
  } catch (error) {
    swal({
      title: "Something wrong!",
      text: error.message.toString(),
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });
  }
};

export const postUpdateChainKpiConfirm = async (datas) => {
  try {
    const token = "Bearer " + localStorage.getItem("accessToken");
    let api = {
      method: "PUT",
      headers: { Authorization: token },
      url: ENVIRONMENT_VARIABLES.Base_API_URL + "/Kpi/updateChainKpi",
      data: datas,
    };
    const response = await axios(api);

    if (response.status === 200) {
      return response.data;
    } else {
      swal({
        title: "Something wrong!",
        text: response.data.user_msg,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      });
    }
  } catch (error) {
    swal({
      title: "Something wrong!",
      text: error.message.toString(),
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });
  }
};

export const postUpdateClinicKpiConfirm = async (datas) => {
  try {
    const token = "Bearer " + localStorage.getItem("accessToken");
    let api = {
      method: "PUT",
      headers: { Authorization: token },
      url: ENVIRONMENT_VARIABLES.Base_API_URL + "/Kpi/updateClinicKpi",
      data: datas,
    };
    const response = await axios(api);

    if (response.status === 200) {
      return response.data;
    } else {
      swal({
        title: "Something wrong!",
        text: response.data.user_msg,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      });
    }
  } catch (error) {
    swal({
      title: "Something wrong!",
      text: error.message.toString(),
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });
  }
};

export const postUpdateDoctorKpiConfirm = async (datas) => {
  try {
    const token = "Bearer " + localStorage.getItem("accessToken");
    let api = {
      method: "PUT",
      headers: { Authorization: token },
      url: ENVIRONMENT_VARIABLES.Base_API_URL + "/Kpi/updateDoctorKpi",
      data: datas,
    };
    const response = await axios(api);

    if (response.status === 200) {
      return response.data;
    } else {
      swal({
        title: "Something wrong!",
        text: response.data.user_msg,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      });
    }
  } catch (error) {
    swal({
      title: "Something wrong!",
      text: error.message.toString(),
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });
  }
};

export const confirmKpi = async (id) => {
  try {
    const token = "Bearer " + localStorage.getItem("accessToken");
    let api = {
      method: "POST",
      headers: { Authorization: token },
      url: ENVIRONMENT_VARIABLES.Base_API_URL + "/Kpi/confirmKpi",
      data: {
        _id: id,
      },
    };
    const response = await axios(api);
    if (response.status === 200) {
      return response.data;
    } else {
      swal({
        title: "Something wrong!",
        text: response.data.user_msg,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      });
    }
  } catch (error) {
    swal({
      title: "Something wrong!",
      text: error.message.toString(),
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });
  }
};

export const getKpiProgressStatus = async (id = 0, year) => {
  try {
    const queryParams = new URLSearchParams(window.location.search);
    const clinicId = queryParams.get("clinicId");
    const doctorId = queryParams.get("doctorId");
    const token = "Bearer " + localStorage.getItem("accessToken");
    let api = {
      method: "POST",
      headers: { Authorization: token },
      url:
        ENVIRONMENT_VARIABLES.Base_API_URL +
        "/Kpi/getKpiProgressStatus" +
        (clinicId ? "?clinicId=" + clinicId : "") +
        (doctorId ? "&doctorId=" + doctorId : ""),
      data: {
        _id: id || 0,
        year,
      },
    };
    const response = await axios(api);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {}
};
