import './style.css';
import { Routing } from 'routes';
import { connect } from 'react-redux';
import { useChainId } from 'hooks/useChainId';
import { Card, Container } from 'react-bootstrap';
import { useState, useEffect, useRef } from 'react';
import { useRouteMatch, Switch, Route } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import axios from 'axios';
import Select from 'react-select';
import SlotConfirmed from './SlotConfirmed';
import RecommendedSlots from './RecommendedSlots';
import * as AuthActions from 'actions/authActions';
import * as PatientsActions from 'actions/patientActions';
import { bindActionCreators } from 'redux';
import DaytimePreference, { daytimePref } from './daytimePreference/daytimePreference.component';
import AgegenderPreference, { agegenderPref } from './agegenderPreference/agegenderPreference.component';
import { Radio, RadioGroup, FormControlLabel } from '@mui/material';
import { set } from 'lodash';

const ScheduleRecommender = (props) => {
  const routeMatch = useRouteMatch();
  return (
    <>
      <Switch>
        <Route exact path={`${routeMatch.path}`}>
          <Renderer
            index={true}
            breadcrumbs={[
              {
                label: 'Schedule Recommender',
                link: `${routeMatch.path}`,
              },
            ]}
            component={Index}
            props={props}
          ></Renderer>
        </Route>
        <Route path={`${routeMatch.path}${Routing.ClinicScheduleRecommender.RecommendedSlots.relativePath}`}>
          <Renderer
            breadcrumbs={[
              {
                label: 'Schedule Recommender',
                link: `${routeMatch.path}`,
              },
              {
                label: 'Recommended Slots',
                link: `${routeMatch.path}${Routing.ClinicScheduleRecommender.RecommendedSlots.relativePath}`,
              },
            ]}
            component={RecommendedSlots}
          />
        </Route>
        <Route path={`${routeMatch.path}${Routing.ClinicScheduleRecommender.SlotConfirmed.relativePath}`}>
          <Renderer
            breadcrumbs={[
              {
                label: 'Schedule Recommender',
                link: `${routeMatch.path}`,
              },
              {
                label: 'Recommended Slots',
                link: `${routeMatch.path}${Routing.ClinicScheduleRecommender.SlotConfirmed.relativePath}`,
              },
            ]}
            component={SlotConfirmed}
          />
        </Route>
      </Switch>
    </>
  );
};
const Renderer = ({ actions, index, breadcrumbs, component: Component, props, children }) => {
  const [displayScreen] = useState(true);
  const [loading] = useState(false);

  const closeWindow = () => {
    window.close();
  };

  return (
    <Container className="mt-3" fluid={true}>
      <Card border="0" className="table-wrapper table-responsive shadow w-100">
        <IconButton onClick={closeWindow} style={{ position: 'absolute', top: 0, right: 0 }}>
          <CloseIcon />
        </IconButton>
        <Card.Body>
          {displayScreen ? (
            <div>
              {/* common screen head */}
              <div></div>
              <div>
                <Component props={props} />
              </div>
            </div>
          ) : (
            loading !== undefined && (
              <div
                className="app-font-class-0 position-absolute"
                style={{
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                }}
              >
                Access Restricted
              </div>
            )
          )}
        </Card.Body>
      </Card>
    </Container>
  );
};

const Index = ({ props }) => {
  let routeMatch = useRouteMatch();
  const agegenderSubmitRef = useRef();
  const daytimeSubmitRef = useRef();
  const chainId = useChainId();

  const [treatmentsList, setTreatmentsList] = useState([]);
  const [doctorsList, setDoctorsList] = useState([]);
  const [clinicsList, setClinicsList] = useState([]);
  const [doctorPreference, setDoctorPreference] = useState(false);
  const [agegenderFilled, setAgegenderFilled] = useState(false);
  const [daytimeFilled, setDaytimeFilled] = useState(false);
  const [hideDoctor, setHideDoctor] = useState(false);
  const [hideAgegender, setHideAgegender] = useState(false);

  const [selectedBranch, setSelectedBranch] = useState('');
  const [selectedTreatment, setSelectedTreatment] = useState('');
  const [selectedDoctor, setSelectedDoctor] = useState('');

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    props.actions.PatientsActions.getPatientTreatments(chainId);
    props.actions.PatientsActions.getPatientDoctors();
    props.actions.AuthActions.getOpenClinicsList();
    localStorage.removeItem('schedule_optimizer_result');
    console.log(props.actions);
  }, []);

  useEffect(() => {
    console.log("selectedBranch ERROR: ", selectedBranch)
  }, [selectedBranch])

  const onTreatmentSelected = (e) => {
    console.log("selectedTreatment before ERROR: ", selectedTreatment);

    console.log("e.value treatment ERROR: ", e.value);
    setSelectedTreatment(e.value);
    console.log("selectedTreatment after ERROR: ", selectedTreatment)
  };

  const onDoctorSelected = (e) => {
    console.log("selectedDoctor before ERROR: ", selectedDoctor);

    console.log("e.value doctor ERROR: ", e.value);
    setSelectedDoctor(e.value);
    console.log("selectedDoctor after ERROR: ", selectedDoctor);
  };

  const onBranchSelected = async (e) => {
    await axios({
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/Doctors/clinics/${e.id}`,
    })
      .then(({ data }) => {
        let doctors = [];
        data.data.forEach((doctor) => {
          doctors.push({
            value: doctor.doctorId,
            label: doctor.name,
          });
        });
        console.log(doctors);
        setDoctorsList(doctors);
      })
      .catch((err) => {
        console.error(err);
      });
    
    console.log("selectedBranch before ERROR: ", selectedBranch);

    console.log("e.value branch ERROR: ", e.value);
    setSelectedBranch(e.value);
    console.log("selectedBranch after ERROR: ", selectedBranch);
  };

  useEffect(() => {
    if (props.doctors && props.doctors.length) {
      let doctors = [];
      props.doctors.forEach((doctor) => {
        doctors.push({
          value: doctor.doctorId,
          label: doctor.name,
        });
      });
      setDoctorsList(doctors);
    }
  }, [props.doctors]);

  useEffect(() => {
    if (props.treatments && props.treatments.length) {
      let treatments = [];
      props.treatments.forEach((treatment) => {
        treatments.push({
          value: treatment.treatmentId,
          label: treatment.treatmentName,
        });
      });
      setTreatmentsList(treatments);
    }
  }, [props.treatments]);

  useEffect(() => {
    if (props.clinics && props.clinics.length) {
      let clinics = [];
      props.clinics.forEach((clinic) => {
        clinics.push({
          id: clinic._id,
          value: clinic.clinicId,
          label: clinic.clinicId,
        });
      });
      setClinicsList(clinics);
    }
  }, [props.clinics]);

  const handleDoctorPreferenceChange = (e) => {
    setDoctorPreference(e.target.value);
  };

  const clickHandler = (event) => {
    event.preventDefault();
    agegenderSubmitRef.current.click();
    daytimeSubmitRef.current.click();
    if (selectedTreatment == '') {
      alert('Please select a treatment');
      return;
    } else if (selectedBranch == '') {
      alert('Please select a clinic branch');
      return;
    } else if (agegenderPref == undefined) {
      alert('Please Input a age');
      return;
    } else if (daytimePref == undefined) {
      alert('Please select a time slot');
      return;
    }
    const input = {
      Period: daytimePref.periodPreference, // today, 1 week
      Days: daytimePref.daysPreference, // ['Monday', 'Thursday']
      Time: daytimePref.timePreference,
      DMY: daytimePref?.dmyPreference, // dd-mm-yyyy
      Age: agegenderPref.age,
      Gender: agegenderPref.gender,
      Treatment: selectedTreatment,
      Clinic: selectedBranch,
      Doctor: selectedDoctor,
    };
    console.log(input);
    // console.log(daytimePref.timePreference);
    requestData(input);
  };

  const requestData = async (data) => {
    setLoading(true);
    // setLoading(false);
    // props.history.push(`${routeMatch.path}${Routing.ClinicScheduleRecommender.RecommendedSlots.relativePath}`);
    await axios({
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      method: 'POST',
      url: `${process.env.REACT_APP_API_URL}/schedule/optimize`,
      data,
    })
      .then(({ data }) => {
        localStorage.setItem('schedule_optimizer_result', JSON.stringify({ message: 'success', data }));
      })
      .catch((err) => {
        if (err) {
          localStorage.setItem(
            'schedule_optimizer_result',
            JSON.stringify({
              message: 'error',
            })
          );
        }
      })
      .finally(() => {
        setLoading(false);
        props.history.push(`${routeMatch.path}${Routing.ClinicScheduleRecommender.RecommendedSlots.relativePath}`);
      });
  };

  return (
    <>
      <section className="mt-3 mb-2">
        <label>Purpose of Visit</label>
        <Select
          className="me-2"
          placeholder="Display Treatment Types"
          components={{
            IndicatorSeparator: () => null,
          }}
          on
          onChange={onTreatmentSelected}
          options={treatmentsList}
        />
      </section>
      <section className="mb-2">
        <label>Clinic Branch</label>
        <Select
          className="me-2"
          placeholder="Branch"
          components={{
            IndicatorSeparator: () => null,
          }}
          onMenuOpen={() => {
            setHideDoctor(true);
            // setHideAgegender(true);
          }}
          onMenuClose={() => {
            setHideDoctor(false);
            // setHideAgegender(false);
          }}
          onChange={onBranchSelected}
          options={clinicsList}
        />
      </section>
      {!hideDoctor ? (
        <section className="mb-2">
          {/* #386: "Doctor's Preference" -> "Preferred Doctor" */}
          <label style={{ marginBottom: 0 }}>Preferred Doctor</label>
          <RadioGroup row defaultValue={doctorPreference} onChange={(e) => handleDoctorPreferenceChange(e)}>
            <FormControlLabel value="false" control={<Radio />} label="No" />
            <FormControlLabel value="true" control={<Radio />} label="Yes" />
          </RadioGroup>

          {/* #386: "Doctor Assigned" -> "Select a Doctor" */}
          {doctorPreference === 'true' ? (
            <Select
              className="me-2"
              placeholder="Select a Doctor"
              components={{
                IndicatorSeparator: () => null,
              }}
              onChange={onDoctorSelected}
              options={doctorsList}
            />
          ) : (
            <></>
          )}
        </section>
      ) : null}
      {/* {hideAgegender ? null : (
        <AgegenderPreference
          agegenderFilled={agegenderFilled}
          setAgegenderFilled={setAgegenderFilled}
          agegenderSubmitRef={agegenderSubmitRef}
        />
      )} */}
      {/* #414: always show component to maintain state */}
      <AgegenderPreference
          agegenderFilled={agegenderFilled}
          setAgegenderFilled={setAgegenderFilled}
          agegenderSubmitRef={agegenderSubmitRef}
      />

      <section className="mt-3 mb-2">
        <DaytimePreference
          daytimeFilled={daytimeFilled}
          setDaytimeFilled={setDaytimeFilled}
          dayTimeSubmitRef={daytimeSubmitRef}
        />
      </section>

      <button
        className={`btn btn-md mt-3 mb-0 mx-0 ${loading ? 'disabled' : null}`}
        style={{ color: '#FFFFFF', backgroundColor: '#00657B', borderRadius: '8px' }}
        onClick={clickHandler}
      >
        {loading ? 'Loading...' : 'Submit'}
      </button>
      {/* </Link> */}
    </>
  );
};

const mapStateToProps = (state) => {
  const { adminAuthReducer, patientReducer } = state;
  return {
    doctors: patientReducer.doctors,
    treatments: patientReducer.treatments,
    clinics: adminAuthReducer.clinics,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    PatientsActions: bindActionCreators(PatientsActions, dispatch),
    AuthActions: bindActionCreators(AuthActions, dispatch),
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(ScheduleRecommender);
