import React, { useEffect, useState, useMemo } from "react";
import { Form, Card, Button, Row, Col, Table } from "react-bootstrap";

import { Routing } from "routes";
import { bindActionCreators } from 'redux';
import { connect, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import * as kpiPlannerAction from "actions/kpiPlannerAction";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { makeStyles } from "@mui/styles";
import { Slider } from "@mui/material";
import Loader from "components/Loader"
import { numberWithCommas, roundToTwo } from "utils/parser.js"
import swal from 'sweetalert';
import decode from "jwt-decode";
import _ from "lodash";
import clsx from 'clsx'

const useStyles = makeStyles(() => ({
    root: {
        "& .Mui-active .MuiStepIcon-root": { color: "white" },
        "& .Mui-completed .MuiStepIcon-root": { color: "#2f4858" },
        "& .Mui-disabled .MuiStepIcon-root": { color: "#dfe4ed" },
        "& .MuiStepLabel-root .MuiStepLabel-label": { fontFamily: 'Poppins', fontSize: '1em', paddingBottom: '1em', fontWeight: '500', color: '#2f4858' }
    },
    cols: {
        paddingLeft: '2em',
        paddingRight: '2em'
    }
}));
const DoctorLevel = ({ step, nextStep, setStepPage, setName, prevStep, handleFormData, values, getDoctorKPIPlanningData, updateDocktorKpi, singleProcess, viewMode, getReferences, getAllocates, year }) => {
    const classes = useStyles();
    const history = useHistory();
    const doctorKPIData = useSelector(state => state.kpiPlannerReducer.doctorKPIData);
    const doctorReference = useSelector((state) => state.kpiPlannerReducer.referencesClinic);
    const allocate = useSelector((state) => state.kpiPlannerReducer.allocatedByClinic)
    const [treatmentsData, setTreatmentsData] = useState([])
    const [loading, setLoading] = useState(true)
    const steps = [
        'Propose Chain Target',
        'Propose Clinic Target',
        'Propose Doctor Target',
        'Confirm Doctor Target',
        'Confirm Clinic Target',
        'Confirm Chain Target'
    ];
    const [optionReference, setOptionReference] = useState();
    const [allocateBy, setAllocateBy] = useState();
    const [isClickSelectReference, setIsClickSelectReference] = useState(false)
    const submitFormData = (e) => {
        e.preventDefault();
        updateDocktorKpi(1, "confirmed");
        setTimeout(() => { prevStep1() }, 5000);
        // prevStep1();
    }
    const prevStep1 = () => {
        history.push(Routing.KpiPlanner.path);
    }
    const marks = [
        {
            value: 0,
            label: '-100%',
        },
        {
            value: 100,
            label: 'Max',
        },
    ];

    const [targetTotalRevenue, setTargetTotalRevenue] = useState('')
    const [targetTotalHours, setTargetTotalHours] = useState('')
    const [targetRevenueHours, setTargetRevenueHours] = useState(0)

    const doctorId = useMemo(() => {
        const accessToken = localStorage.getItem("accessToken")
        if (accessToken !== "undefined" && accessToken !== null) {
            const userProfile = decode(accessToken)
            const user = userProfile.user;
            if (user.doctor) {
                return user.doctor.doctorId;
            }
        }
    }, [])

    const [errors, setErrors] = useState({
        targetTotalRevenue: null,
        targetTotalHours: null
    });

    const validateFormData = (data) => {
        let errors = {};
        let formIsValid = true;
        // inputAmount

        if (!data?.targetTotalRevenue) {
            errors.targetTotalRevenue = "Please type total hours"
        }

        // referenceId

        if (!data?.targetTotalHours) {
            errors.targetTotalHours = "Please type  total hours"
        }

        // allocateId

        if (Object.keys(errors)?.length) {
            formIsValid = false;
            setErrors(errors)
        } else {
            setErrors({
                targetTotalRevenue: null,
                targetTotalHours: null
            })
        }

        return formIsValid
    }

    const handleInputTargetTotalRevenue = (e) => {
        let { value } = e.target
        let shallowTreatmensData = _.cloneDeep(treatmentsData)
        const totalRevenueHours = Number(targetTotalHours) ? value / targetTotalHours : 0
        setTargetTotalRevenue(e.target.value)
        shallowTreatmensData = changeDataTreatementAcordingToAllocateId(shallowTreatmensData, Number(value), Number(targetTotalHours), totalRevenueHours, Number(allocateBy))
        shallowTreatmensData = _.sortBy(shallowTreatmensData, ['referenceTotalRevenue']).reverse()
        setTreatmentsData(shallowTreatmensData)
        setTargetRevenueHours(totalRevenueHours)
    }

    const handleInputTargetTotalHours = (e) => {
        let { value } = e.target
        const totalRevenueHours = Number(value) ? targetTotalRevenue / value : 0
        let shallowTreatmensData = _.cloneDeep(treatmentsData)
        setTargetTotalHours(e.target.value)
        shallowTreatmensData = changeDataTreatementAcordingToAllocateId(shallowTreatmensData, Number(targetTotalRevenue), Number(value), totalRevenueHours, Number(allocateBy))
        shallowTreatmensData = _.sortBy(shallowTreatmensData, ['referenceTotalRevenue']).reverse()
        setTreatmentsData(shallowTreatmensData)
        setTargetRevenueHours(totalRevenueHours)
    }

    const handleChangeAllocateInput = (e) => {
        setAllocateBy(e.target.value)
        const totalRevenueHours = Number(targetTotalHours) ? Number(targetTotalRevenue) / Number(targetTotalHours) : 0
        let shallowTreatmensData = _.cloneDeep(treatmentsData)
        shallowTreatmensData = changeDataTreatementAcordingToAllocateId(shallowTreatmensData, Number(targetTotalRevenue), Number(targetTotalHours), totalRevenueHours, Number(e.target.value))
        shallowTreatmensData = _.sortBy(shallowTreatmensData, ['referenceTotalRevenue']).reverse()
        setTreatmentsData(shallowTreatmensData)

    }

    const changeDataTreatementAcordingToAllocateId = (tempTreatmentData, totalRevenue, totalHours, totalRevenueHours, allocateId) => {
        let shallowListTreatments = _.cloneDeep(tempTreatmentData)
        if (allocateId === 1) {
            return shallowListTreatments.map(el => {
                return {
                    ...el,
                    percentage: 50,
                    targetTotalRevenue: 50 * totalRevenue / 100,
                    targetTotalHours: 50 * totalHours / 100,
                    targetRevenueHours: 50 * totalRevenueHours / 100
                }
            })
        } else if (allocateId === 2 && doctorKPIData?.refRevHrs) {
            return shallowListTreatments.map(el => {
                return {
                    ...el,
                    percentage: (el.referenceRevenueHours / doctorKPIData.refRevHrs * 100).toFixed(),
                    targetTotalRevenue: el.referenceTotalRevenue / doctorKPIData.refTtlRev * totalRevenue,
                    targetTotalHours: el.referenceTotalHours / doctorKPIData.refTtlHrs * totalHours,
                    targetRevenueHours: el.referenceRevenueHours / doctorKPIData.refRevHrs * totalRevenueHours
                }
            })
        } else if (allocateId === 4) {
            return shallowListTreatments.map(el => {
                return {
                    ...el,
                    targetTotalRevenue: el.percentage / 100 * totalRevenue,
                    targetTotalHours: el.percentage / 100 * totalHours,
                    targetRevenueHours: el.percentage / 100 * totalRevenueHours
                }
            })
        }
        return shallowListTreatments
    }

    const handleChangeRanges = (e) => {
        const { name: index, value } = e.target
        let shallowTreatments = _.cloneDeep(treatmentsData)
        if (!targetTotalRevenue && !targetTotalHours && !targetRevenueHours) {
            return
        } else {
            shallowTreatments[index].percentage = value
        }
        if (targetTotalRevenue) {
            shallowTreatments[index].targetTotalRevenue = targetTotalRevenue * value / 100
        }
        if (targetTotalHours) {
            shallowTreatments[index].targetTotalHours = targetTotalHours * value / 100
        }
        if (targetRevenueHours) {
            shallowTreatments[index].targetRevenueHours = targetRevenueHours * value / 100
        }
        shallowTreatments = _.sortBy(shallowTreatments, ['referenceTotalRevenue']).reverse()
        setTreatmentsData(shallowTreatments)
        setAllocateBy("4")
    }

    const handleTotalRevenue = (data) => {
        if (data && data.length) {
            const total = data.reduce((previousValue, currentValue) => {
                return previousValue + Number(currentValue.targetTotalRevenue)
            }, 0)
            return numberWithCommas(roundToTwo(total))
        } else {
            return 0
        }
    }

    const handleChangeOptionsReference = (e) => {
        const optionReference = e.target.value
        setOptionReference(optionReference)
        setLoading(true)
        setIsClickSelectReference(true)
        getDoctorKPIPlanningData(doctorId, Number(optionReference), year);
    }


    const sendAPiPostDoctorProposeKpi = (e) => {
        const dataRequest = {
            "chainId": 1,
            "chainKpiId": 11,
            "doctorId": doctorId,
            "referenceId": Number(optionReference),
            "allocateId": Number(allocateBy),
            "referenceTotalRevenue": doctorKPIData?.refTtlRev,
            "referenceTotalHours": doctorKPIData?.refTtlHrs,
            "targetTotalHours": targetTotalHours,
            "targetTotalRevenue": targetTotalRevenue,
            "referenceRevenueHours": doctorKPIData?.refRevHrs,
            "targetRevenueHours": targetRevenueHours,
            "treatments": treatmentsData,
            "year": year
        }
        e.preventDefault();
        if (validateFormData(dataRequest)) {
            setLoading(true);
            kpiPlannerAction.postDoctorProposeKpi(dataRequest).then(response => {
                setLoading(false)
                if (response) {
                    swal("Update Doctor KPI is successfully!", {
                        icon: "success",
                    }).then(() => {
                        prevStep1()
                    });
                }
            })
        }
    }

    useEffect(() => {
        setName(doctorKPIData?.doctorId);
        getReferences()
        getAllocates()
    }, []);

    useEffect(() => {
        if (!_.isEmpty(doctorKPIData)) {
            if (doctorKPIData._id) {
                setStepPage(4)
                return
            }
            let shallowTreatment = _.cloneDeep(doctorKPIData.treatments)
            shallowTreatment = shallowTreatment.map(el => {
                return {
                    treatmentId: el.treatmentId,
                    treatmentName: el.treatmentName,
                    referenceTotalRevenue: el.trtmntRefTtlRev,
                    referenceTotalHours: el.trtmntRefTtlHrs,
                    referenceRevenueHours: el.trtmntRefRevHrs,
                    targetTotalRevenue: 0,
                    targetTotalHours: 0,
                    targetRevenueHours: 0,
                    percentage: 0
                }
            })
            if (isClickSelectReference) {
                const totalRevenueHours = Number(targetTotalHours) ? Number(targetTotalRevenue) / Number(targetTotalHours) : 0
                shallowTreatment = changeDataTreatementAcordingToAllocateId(shallowTreatment, Number(targetTotalHours), Number(targetTotalRevenue), totalRevenueHours, Number(allocateBy))
                setIsClickSelectReference(false)
            }
            shallowTreatment = _.sortBy(shallowTreatment, ['referenceTotalRevenue']).reverse()
            setTreatmentsData(shallowTreatment)
            setLoading(false)
        }
    }, [doctorKPIData])

    useEffect(() => {
        if (allocate && allocate.allocateBy && allocate.allocateBy.length) {
            setAllocateBy(allocate.allocateBy[0].allocateById)
        }
    }, [allocate])

    useEffect(() => {
        if (doctorReference && doctorReference.references && doctorReference.references.length) {
            setOptionReference(doctorReference.references[0].referenceId)
            getDoctorKPIPlanningData(doctorId, Number(doctorReference.references[0].referenceId), year);
        }
    }, [doctorReference])


    if (loading) {
        return <Loader></Loader>
    }

    return (
        <>
            {updateDocktorKpi?.success &&
                <div className="alert alert-success" role="alert">
                    {updateDocktorKpi.message}
                </div>
            }

            <Box sx={{ width: '100%' }}>
                <div className="row">
                    <div className="col-12 mb-3">
                        <div className="wrapper-progressBar">
                            <ul className="progressBar">
                                {steps.map((el, index) =>
                                    <li key={index} className={clsx({'selected-progress': el === 'Propose Doctor Target'})}>
                                        {el}
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
            </Box>
            <Form onSubmit={submitFormData} style={{ height: '66vh' }}>
                <Card className="table-wrapper shadow mb-3">
                    <Card.Body>
                        <Row className="chain-level" style={{ fontFamily: 'Poppins', fontWeight: '500' }}>
                            <Col xs={12} lg={4} className={classes.cols}>
                                <Form.Group id="Reference" className="mb-4">
                                    <Form.Label> Select reference: </Form.Label>
                                    <Form.Select
                                        className="mb-4" id="financialYears" value={optionReference} disabled={viewMode ? true : null}
                                        onChange={(e) => handleChangeOptionsReference(e)}
                                    >
                                        {doctorReference?.references.map((item, index) => (
                                            <option key={index} value={item.referenceId}>{item.referenceName}</option>
                                        ))}

                                    </Form.Select>
                                </Form.Group>
                            </Col>

                            <Col xs={12} xl={4}>
                                <Form.Label>{doctorKPIData?.doctorName}: KPI</Form.Label>
                                <Table className="align-items-center table-bordered">
                                    <thead>
                                        <tr className="table-active">
                                            <th></th>
                                            <th>Total Revenue</th>
                                            <th>Total Hours</th>
                                            <th>Revenue /Hours</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="table-active">
                                            <td>Reference:</td>
                                            <td>${numberWithCommas(roundToTwo(doctorKPIData?.refTtlRev))}</td>
                                            <td>{numberWithCommas(roundToTwo(doctorKPIData?.refTtlHrs))}</td>
                                            <td>${numberWithCommas(roundToTwo(doctorKPIData?.refRevHrs))}</td>
                                        </tr>
                                        <tr className="table-active">
                                            <td>Input Target:</td>
                                            <td >
                                                <Form.Group style={{ display: 'flex', alignItems: 'center' }} controlId="inputTarget">
                                                    <span style={{ paddingRight: '5px' }}>$</span>
                                                    <Form.Control
                                                        type="number"
                                                        required={true}
                                                        value={targetTotalRevenue}
                                                        onChange={(e) => handleInputTargetTotalRevenue(e)}
                                                    />
                                                </Form.Group>
                                                {
                                                    errors?.targetTotalRevenue && <span style={{ color: 'red', fontSize: '15px' }}>
                                                        {errors.targetTotalRevenue}
                                                    </span>
                                                }
                                            </td>
                                            <td>
                                                <Form.Group controlId="inputTarget">
                                                    <Form.Control
                                                        type="number"
                                                        required={true}
                                                        value={targetTotalHours}
                                                        onChange={(e) => handleInputTargetTotalHours(e)}
                                                    />
                                                </Form.Group>
                                                {
                                                    errors?.targetTotalHours && <span style={{ color: 'red', fontSize: '15px' }}>
                                                        {errors.targetTotalHours}
                                                    </span>
                                                }
                                            </td>
                                            <td >
                                                {numberWithCommas(roundToTwo(targetRevenueHours))}$
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                <Card className="table-wrapper shadow" style={{ overflow: 'scroll', height: '33vh' }}>
                    <Card.Body>
                        {treatmentsData.map((item, index) => (
                            <Row key={index} className="chain-level pt-5 pb-5" style={{ fontFamily: 'Poppins', fontWeight: '500' }}>
                                <Col xs={12} md={12} lg={8} xl={8} className="table-responsive">
                                    <Form.Label>{item.treatmentName}</Form.Label>
                                    <Table className="align-items-center table-bordered">
                                        <thead>
                                            <tr className="table-active">
                                                <th></th>
                                                <th>Total Revenue</th>
                                                <th>Total Hours</th>
                                                <th>Revenue /Hours</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className="table-active">
                                                <td>Reference:</td>
                                                <td>${numberWithCommas(roundToTwo(item.referenceTotalRevenue))}</td>
                                                <td>{numberWithCommas(roundToTwo(item.referenceTotalHours))}</td>
                                                <td>${numberWithCommas(roundToTwo(item.referenceRevenueHours))}</td>
                                            </tr>
                                            <tr className="table-active">
                                                <td>Target:</td>
                                                <td>${numberWithCommas(roundToTwo(item.targetTotalRevenue))}</td>
                                                <td>{numberWithCommas(roundToTwo(item.targetTotalHours))}</td>
                                                <td>${numberWithCommas(roundToTwo(item.targetRevenueHours))}</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Col>
                                <Col xs={12} md={12} lg={1} xl={1}></Col>
                                <Col xs={12} md={12} lg={3} xl={3} className="mt-6">
                                    <Slider
                                        value={item.percentage}
                                        onChange={handleChangeRanges}
                                        name={index}
                                        min={0} max={100}
                                        marks={marks}
                                        valueLabelDisplay="on"
                                        disabled={viewMode ? true : null} />
                                </Col>
                            </Row>
                        ))}

                        <Row className="justify-content-center clinic-chain-level pt-1 pb-1" style={{ fontFamily: 'Poppins', fontWeight: '500' }}>
                            <Col xs={12} lg={4} className={classes.cols}>
                                <Form.Group id="AllocatedBy" className="mb-4">
                                    <Form.Label> Allocate By: </Form.Label>
                                    <Form.Select value={allocateBy}
                                        onChange={handleChangeAllocateInput}
                                        className="mb-4" id="financialYears" disabled={viewMode ? true : null}>
                                        {allocate?.allocateBy.map((item, index) => (
                                            <option key={index} value={item.allocateById}>{item.allocateByName}</option>

                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col xs={12} lg={8}></Col>
                        </Row>

                        <Row className="justify-content-center pt-5 pb-5" style={{ fontFamily: 'Poppins', fontWeight: '500' }}>
                            <Col xs={12} lg={10}></Col>
                            <Col xs={12} lg={2} className="button-color">
                                <Table className="align-items-center mb-2">
                                    <tbody>
                                        <tr className="table-active">
                                            <td>Total:</td>
                                            <td>${handleTotalRevenue(treatmentsData)}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                                <div style={{ display: "flex", justifyContent: "space-around" }}>
                                    {/* <Button variant="primary" className="animate-up-2 btn btn-gray-800 btn-lg" onClick={updateDocktorKpi(1,"Confirmed")}> Back </Button> */}

                                    <Button onClick={sendAPiPostDoctorProposeKpi} variant="primary" className="animate-up-2 btn btn-gray-800 btn-lg" type="submit" disabled={viewMode ? true : null}> Propose </Button>
                                </div>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Form>
        </>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        getDoctorKPIPlanningData: (doctorId, referenceId, year) => dispatch(kpiPlannerAction.getDoctorKPIPlanningData(doctorId, referenceId, 0, year)),
        getReferences: () => dispatch(kpiPlannerAction.getReferenceByClinic()),
        getAllocates: () => dispatch(kpiPlannerAction.getAllocatedByClinic())
    }
};

export default connect(null, mapDispatchToProps)(DoctorLevel)
