/**
 * Bubble chart data and render configs where x=recency, y=frequency, z (size)=monetary
 */

import { Routing } from "routes";
import { useEffect, useState } from "react";

import segmentOrderConfig from "./PatientSegmentationOrderConfig";
import { formatAmount } from "utils/parser";

import { Row, Col } from "react-bootstrap";
import Chart from 'react-apexcharts';
import { IoMdInformationCircleOutline } from "react-icons/io";

import "./style.css"
import RFMInfoModal from "./RFMInfoModal";



const RFMBubbleChart = (props) => {

    // 
    // STATES
    // 

    // info modal toggle
    const [ infoToggle, setInfoToggle ] = useState(false);
    // props child -> to pass to the modal component so they can access other props
    const childProps = Object.assign({}, props, {
        infoToggle
    });

    // chart options/config
    const baseOptions = {
        chart: {
            type: 'bubble',
            toolbar: {
                show: false
            },
            zoom: {
                enabled: true
            },
            animations: {
                enabled: false
            }
        },
        dataLabels: {
            enabled: false
        },
        responsive: [{}],
        plotOptions: {
            // scale z (bubble size) to be within the range since the actual monetary values have a very wide range 
            bubble: {
                zScaling: true,
                minBubbleRadius: 10,
                maxBubbleRadius: 100
            }
        },
        xaxis: {},
        yaxis: {},
        legend: {
            show: !props.isEmbedded,
            position: 'bottom',
            horizontalAlign: 'center',
            offsetY: 10
        },
        tooltip: {},
        fill: {
            opacity: 1
        },
        colors: []
    };
    const [ chartOptions, setChartOptions ] = useState(baseOptions);

    // chart series
    const [ chartSeries, setChartSeries ] = useState([]);

    // 
    // USE EFFECTS
    // 

    // on first render
    useEffect(() => {
        if(!props.isEmbedded) {
            window.history.replaceState(
                null,
                "Medtrik",
                Routing.RFMBubbleChart.path
            );
            window.scrollTo(0, 0);
        }
    }, []);

    // on API data update
    useEffect(() => {
        if (props.rfmBubbleChart && (Array.isArray(props.rfmBubbleChart) && props.rfmBubbleChart.length>0)) {
            // get series ([x, y, z] format) and x-only series (then get unique x values to decide tickAmount in x-axis)
            let xSeries = [];
            let ySeries = [];
            let series = props.rfmBubbleChart.map(cat => { 
                return { 
                    name: cat.segmentCategory, 
                    data: cat.segmentData.map( data => { xSeries.push(data.recency); ySeries.push(data.frequency); return [data.recency, data.frequency, data.monetary] }) 
                } 
            });
            const xUniqueSeries = [...new Set(xSeries)];
            const xUniqueCount = xUniqueSeries.length;
            const yUniqueSeries = [...new Set(ySeries)];
            const yUniqueMax = Math.max(...yUniqueSeries);
            console.log("ERROR yUniqueMax: ", yUniqueMax);

            // get colors array according to the categories' names
            let colors = series.map(obj => segmentOrderConfig[obj.name].color);

            if (series.filter(obj => obj.data.length>0).length>0) {
                // set chart config and series
                setChartOptions({
                    ...baseOptions,
                    xaxis: {
                        type: 'numeric',
                        title: {
                            text: 'Recency'
                        },
                        tickAmount: xUniqueCount ? (xUniqueCount>25 ? 25 : xUniqueCount) : null,
                    },
                    yaxis: {
                        type: 'numeric',
                        title: {
                            text: 'Frequency'
                        },
                        max: yUniqueMax ? yUniqueMax + Math.ceil(0.3 * yUniqueMax) : null
                    },
                    colors: colors,
                    tooltip: {
                        custom: ({series, seriesIndex, dataPointIndex, w}) => {
                            return (
                                `<div>
                                    <div class="highlight">
                                    <div class="circle" style="background-color:${segmentOrderConfig[w.config.series[seriesIndex].name].color}"></div>
                                    <p class="adjusted-fontsize-tooltip series-text"> <b> ${w.config.series[seriesIndex].name} </b> </p>
                                    </div>
                                    <div>
                                        <p class="adjusted-fontsize-tooltip"> Recency period: <b> ${w.config.series[seriesIndex].data[dataPointIndex][0]} days </b> </p>
                                        <p class="adjusted-fontsize-tooltip"> Visitation frequency: <b> ${w.config.series[seriesIndex].data[dataPointIndex][1]} visits </b> </p>
                                        <p class="adjusted-fontsize-tooltip"> Monetary value: <b> ${formatAmount(w.config.series[seriesIndex].data[dataPointIndex][2])} </b> </p>
                                    </div>
                                </div>`
                            );
                        }
                    }
                })
                setChartSeries(series);
            } else {
                setChartSeries([]);
                setChartOptions({
                    ...baseOptions,
                    xaxis: {
                        type: 'numeric',
                        title: {
                            text: "Recency"
                        }
                    },
                    yaxis: {
                        type: 'numeric',
                        title: {
                            text: "Frequency"
                        }
                    }
                });
            }
        }
    }, [props.rfmBubbleChart])



    // 
    // RENDER UI
    // 
    
    return (
        <Row className={!props.isEmbedded ? 'pb-5' : 'pb-4'}>
            {/* GRAPH */}
            <Col xs={12} lg={12}>
                <Chart options={chartOptions} series={chartSeries} type="bubble" height={props.isEmbedded ? 800 : 1000} />
            </Col>
            {/* INFO MODAL */}
            <div className="info-wrapper" style={{ marginTop: props.isEmbedded ? "-40px" : "0px" }}>
                <div>
                    <IoMdInformationCircleOutline className="info-icon" fontSize={"30px"} onMouseOver={() => setInfoToggle(true)} onMouseOut={() => setInfoToggle(false)}/>
                </div>
                <RFMInfoModal {...childProps} infoToggle={infoToggle}/>
            </div>
        </Row>
    )
};

export default RFMBubbleChart;