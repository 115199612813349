import { useEffect, useState } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { Routing } from 'routes';

const RecommendedSlots = () => {
  const itemsPerPage = 5;
  const [scheduleList, setScheduleList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [status, setStatus] = useState(); //result status from schedule recommender

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString().substr(-2);
    return `${day}/${month}/${year}`;
  }

  const openNewWindow = (link, title) => {
    window.open(link, title, `width=800,height=600,left=200,top=200`);
  };

  useEffect(() => {
    const results = JSON.parse(localStorage.getItem('schedule_optimizer_result'));
    // setStatus(results.message === 'success' ? true : false);
    // setStatus(true);
    const check = results.data.prediction;
    // console.log(typeof check);
    // console.log(check);
    if (typeof check == 'string') {
      setStatus(false);
      return;
    } else if (typeof check == 'object') {
      setStatus(true);
    } else if (typeof check == 'undefined') {
      setStatus(false);
      return;
    } else {
      setStatus(false);
      return;
    }

    if (status) {
      const datas = results.data.prediction;
      // const datas = {
      //   dr: {
      //     0: 'LET',
      //     1: 'LET',
      //   },
      //   date: {
      //     0: '2023-04-26',
      //     1: '2023-04-26',
      //   },
      //   branch: {
      //     0: 'Orchard',
      //     1: 'Orchard',
      //   },
      //   show_rate: {
      //     0: 0.961848258972168,
      //     1: 0.961848258972168,
      //   },
      //   starttime: {
      //     0: '13:15:00',
      //     1: '13:00:00',
      //   },
      //   endtime: {
      //     0: '14:15:00',
      //     1: '14:00:00',
      //   },
      // };
      if (datas) {
        let result = [];

        const keys = Object.keys(datas['date']);
        // console.log(Object.keys(datas['date']).length);
        for (let i = 0; i < keys.length; i++) {
          const newObj = {
            rank: i + 1,
            date: datas.date[i],
            daysOfWeek: new Date(datas.date[i]).toLocaleDateString('en-US', { weekday: 'long' }),
            timing: `${datas.starttime[i]} - ${datas.endtime[i]}`,
            doctor: datas.dr[i],
          };
          result.push(newObj);
        }

        // Object.entries(datas).forEach(([key, value], i) => {
        //   const el = datas[key];
        //   result.push({
        //     rank: i + 1,
        //     date: formatDate(el.date),
        //     timing: `${el.starttime} - ${el.endtime}`,
        //     doctor: el.dr,
        //   });
        // });

        // for (const [key, value] of Object.entries(datas)) {
        //   result.push({
        //     key: value,
        //   });
        // }
        // console.log(result);
        setScheduleList(result);
      }
    }
  }, [status]);

  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;
  const currentItems = scheduleList.slice(firstIndex, lastIndex);
  const totalPages = Math.ceil(scheduleList.length / itemsPerPage);

  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  const handlePreviousClick = () => {
    if (currentPage > 1) {
      let number = currentPage;
      setCurrentPage(number - 1);
    } else {
      setCurrentPage(1);
    }
  };

  const handleNextClick = () => {
    if (currentPage < totalPages) {
      let number = currentPage;
      setCurrentPage(number + 1);
    } else {
      setCurrentPage(totalPages);
    }
  };

  const getRows = (list = []) => {
    return currentItems.map((item) => (
      <tr key={item.rank}>
        <td>{item.rank}</td>
        <td>{item.date}</td>
        <td>{item.daysOfWeek}</td>
        <td>{item.timing}</td>
        <td>{item.doctor}</td>
      </tr>
    ));
  };

  return (
    <div>
      <div className="app-font-class-0" style={{ fontWeight: '700' }}>
        Recommended Slots
      </div>
      <button
        className="btn btn-primary btn-sm mt-3 mb-3"
        onClick={() => openNewWindow(Routing.ClinicScheduleRecommender.path, 'Schedule Recommender')}
      >
        Return to Search
      </button>
      {status && scheduleList.length > 0 ? (
        <>
          <table className="mt-3 table table-wrapper table-responsive table-striped table-borderless">
            <thead>
              <tr>
                <th>Rank</th>
                <th>Date</th>
                <th>Days of the week</th>
                <th>Timing</th>
                <th>Doctor</th>
              </tr>
            </thead>
            <tbody>{getRows(scheduleList)}</tbody>
          </table>
          <Container fluid className="mt-4 p-0">
            <Row>
              <Col sm={12} md={6}>
                <nav className="d-inline-block flex-start">
                  <ul className="pagination pagination-sm" id="recommended-slot-pagination">
                    <li className={`page-item ${currentPage === 1 ? 'disabled' : null}`}>
                      <a className="page-link" tabIndex="-1" onClick={handlePreviousClick}>
                        Previous
                      </a>
                    </li>
                    {pageNumbers.map((number) => (
                      <li className={`page-item ${currentPage === number ? 'active' : null}`} key={number}>
                        <a className="page-link" onClick={() => setCurrentPage(number)}>
                          {number}
                        </a>
                      </li>
                    ))}
                    <li className={`page-item ${currentPage === totalPages ? 'disabled' : null}`}>
                      <a className="page-link" onClick={handleNextClick}>
                        Next
                      </a>
                    </li>
                  </ul>
                </nav>
              </Col>
            </Row>
          </Container>
        </>
      ) : !status ? (
        <p>no available time slot</p>
      ) : (
        <p>no available time slot</p>
      )}
    </div>
  );
};

export default RecommendedSlots;
