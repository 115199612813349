/**
 * Actions to get API responses for Patient Segmentation dashboard
 */

import axios from "axios";

import {
    PATIENT_SEGMENTATION_ERROR,
    GET_SEGMENT_CATEGORY_HORIZONTAL_BAR_CHART,
    GET_SEGMENT_PATIENT_EXPORT,
    GET_RFM_SCATTER_PLOT,
    GET_RFM_BUBBLE_CHART,
    PATIENT_EXPORT_START,
    PATIENT_BAR_START
} from '../constants/actionsTypes';
import ENVIRONMENT_VARIABLES from "../config";

const baseURL = `${ENVIRONMENT_VARIABLES.Base_API_URL}/PatientSegmentation`;
const token = "Bearer " + localStorage.getItem('accessToken');



// 
// General function to do API call
// 

const processRequest = (api, type) => {
    try {
        return (dispatch) => {
            if (type === GET_SEGMENT_PATIENT_EXPORT) {
                dispatch({
                    type: PATIENT_EXPORT_START,
                });
            } else if (type === GET_SEGMENT_CATEGORY_HORIZONTAL_BAR_CHART) {
                dispatch({
                    type: PATIENT_BAR_START,
                });
            }
            axios(api).then((response) => {
                if (response.status === 200) {
                    console.log("ERROR ", type, "response: ", response);
                    dispatch({
                        type,
                        data: response.data.data
                    });
                }
            }).catch((error) => {
                if (error && error.response) {
                    console.log("ERROR error.response: ", error.response);
                    dispatch({ type: PATIENT_SEGMENTATION_ERROR, data: { errorMessage: error.response.data.user_msg } });
                } else {
                    console.log("ERROR error.message.toString(): ", error.message.toString());
                    dispatch({ type: PATIENT_SEGMENTATION_ERROR, data: { errorMessage: error.message.toString() } });
                }
            });
        }
    } catch(error) {
        alert(error.message.toString())
    }
}

// getSegmentCategoryHorizontalBarChart

export const getSegmentCategoryHorizontalBarChart = (startDate, endDate, segmentCategories) => {
    let api = {
        method: 'POST',
        headers: { 'Authorization': token },
        url: baseURL + "/getSegmentCategoryHorizontalBarChart",
        data: { startDate, endDate, segmentCategories }
    };
    return processRequest(api, GET_SEGMENT_CATEGORY_HORIZONTAL_BAR_CHART);
};

// getSegmentPatientExport

export const getSegmentPatientExport = (startDate, endDate, segmentCategories) => {
    let api = {
        method: 'POST',
        headers: { 'Authorization': token },
        url: baseURL + "/getSegmentPatientDataExportCSV",
        data: { startDate, endDate, segmentCategories }
    };
    return processRequest(api, GET_SEGMENT_PATIENT_EXPORT);
};

// getRFMScatterPlot

export const getRFMScatterPlot = (startDate, endDate, segmentCategories, xAxis, yAxis) => {
    let api = {
        method: 'POST',
        headers: { 'Authorization': token },
        url: baseURL + "/getRFMScatterPlot",
        data: { startDate, endDate, segmentCategories, xAxis, yAxis }
    };
    return processRequest(api, GET_RFM_SCATTER_PLOT);
};

// getRFMBubbleChart

export const getRFMBubbleChart = (startDate, endDate, segmentCategories) => {
    let api = {
        method: 'POST',
        headers: { 'Authorization': token },
        url: baseURL + "/getRFMBubbleChart",
        data: { startDate, endDate, segmentCategories }
    };
    return processRequest(api, GET_RFM_BUBBLE_CHART);
};