import React, { useState, useEffect } from 'react';
import { Col, Row, Card, Form, Button, ButtonGroup, Breadcrumb, InputGroup, Dropdown } from 'react-bootstrap';
import { AdjustmentsIcon, CheckIcon, CogIcon, HomeIcon, PlusIcon, SearchIcon } from '@heroicons/react/solid';

import { Routing } from 'routes';
import { bindActionCreators } from 'redux';
import { connect, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import swal from 'sweetalert';
import * as authAction from 'actions/authActions';
import Loader from '../Loader';

import './style.css';

const AddUser = (props) => {
  const history = useHistory();
  const { state } = props.location;
  const [editUser, seteditUser] = useState(false);
  const [userId, setuserID] = useState('');
  const [userFullName, setuserFullName] = useState('');
  const [userName, setuserName] = useState('');
  const [userEmail, setuserEmail] = useState('');
  const [roles, setRoles] = useState([]);
  const [userRole, setUserRole] = useState('');
  const [clinic, setClinic] = useState([]);
  const [doctor, setDoctor] = useState([]);
  const [userDoctor, setUserDoctor] = useState('');
  const [userChain, setUserChain] = useState(1);
  const [userClinic, setUserClinic] = useState('');
  const [userStatus, setUserStatus] = useState('false');

  useEffect(() => {
    props.actions.auth.getChains();

    // TODO Tran Check Chain ID Hardcode 1
    props.actions.auth.getClinics(1);
    props.actions.auth.getDoctors();
    props.actions.auth.getroles();
    if (state) {
      console.log('STATE', state);
      setuserID(state._id);
      setuserFullName(state.fullName);
      setuserName(state.userName);
      setuserEmail(state.email);
      setUserRole(state.Role.id);
      seteditUser(true);
      if (state.chain) {
        setUserChain(state.chain._id);
      }
      if (state.doctor) {
        setUserDoctor(state.doctor._id);
      }
      if (state.clinic) {
        setUserClinic(state.clinic);
      }
      if (state.isActive) {
        setUserStatus('true');
      }
    }
  }, []);

  const adminAuthReducer = useSelector((state) => ({
    isLoading: state.adminAuthReducer.loading,
    isUpdated: state.adminAuthReducer.isUpdated,
    error_msg: state.adminAuthReducer.error_msg,
    userUpdated: state.adminAuthReducer.updateUser,
    clinics: state.adminAuthReducer.clinics,
    doctorsDetails: state.adminAuthReducer.doctorsDetails,
    chains: state.adminAuthReducer.chains,
    doctorClinic: state.adminAuthReducer.doctorClinic,
    roleDetail: state.adminAuthReducer.roleDetail,
  }));

  useEffect(() => {
    if (adminAuthReducer.error_msg !== null) {
      swal('Oops...', adminAuthReducer.error_msg.toString(), 'error');
    }
  }, adminAuthReducer.error_msg);

  useEffect(() => {
    if (adminAuthReducer.roleDetail.length) {
      setRoles(adminAuthReducer.roleDetail);
    }
  }, [adminAuthReducer.roleDetail]);

  const onUserRoleChange = (value) => {
    if (value) {
      setUserRole(roles.find((u) => u._id === Number(value)));
      setUserChain(1);
      setUserDoctor(0);
      setUserClinic(0);
    }
  };

  useEffect(() => {
    if (adminAuthReducer.doctorsDetails.length) {
      setDoctor(adminAuthReducer.doctorsDetails);
    }
  }, [adminAuthReducer.doctorsDetails]);

  const onDoctorChange = (value) => {
    if (value) {
      setUserDoctor(doctor.filter((u) => u._id === Number(value))[0]);
      props.actions.auth.getDoctorClinics(value);
    }
  };

  useEffect(() => {
    if (adminAuthReducer.clinics.length) {
      setClinic(adminAuthReducer.clinics);
    }
  }, [adminAuthReducer.clinics]);

  useEffect(() => {
    if (adminAuthReducer.isUpdated) {
      history.push(Routing.Users.path);
    }
  }, [adminAuthReducer.isUpdated]);

  const RequiredLabel = ({ label }) => (
    <Form.Label>
      {label}
      <span className="text-danger"> * </span>
    </Form.Label>
  );

  const redirectUrl = (url) => {
    history.push(url);
  };

  function handleSubmit(event) {
    event.preventDefault();
    if (editUser) {
      let updateDetail = {};
      updateDetail = {
        userId,
        userRole,
        userChain,
        userDoctor,
        userClinic,
        userFullName,
        userName,
        userEmail,
        userStatus,
      };
      props.actions.auth.updateUser(updateDetail);
    } else {
      let AddUserDetail = {};
      AddUserDetail = { userRole, userChain, userDoctor, userClinic, userFullName, userName, userEmail };
      props.actions.auth.createUsers(AddUserDetail);
    }
  }

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          {!editUser ? (
            <>
              <Breadcrumb
                className="d-none d-md-inline-block"
                listProps={{ className: 'breadcrumb-dark breadcrumb-transparent' }}
              >
                <Breadcrumb.Item onClick={() => redirectUrl(Routing.Dashboard.path)}>
                  <HomeIcon className="icon icon-xs" />
                </Breadcrumb.Item>
                <Breadcrumb.Item onClick={() => redirectUrl(Routing.Users.path)}>Users</Breadcrumb.Item>
                <Breadcrumb.Item active>User Management</Breadcrumb.Item>
              </Breadcrumb>
              <h4>User Management</h4>
            </>
          ) : (
            <>
              <Breadcrumb
                className="d-none d-md-inline-block"
                listProps={{ className: 'breadcrumb-dark breadcrumb-transparent' }}
              >
                <Breadcrumb.Item onClick={() => redirectUrl(Routing.Dashboard.path)}>
                  <HomeIcon className="icon icon-xs" />
                </Breadcrumb.Item>
                <Breadcrumb.Item onClick={() => redirectUrl(Routing.Users.path)}>Users</Breadcrumb.Item>
                <Breadcrumb.Item active>Edit User</Breadcrumb.Item>
              </Breadcrumb>
              <h4>Edit User</h4>
            </>
          )}
        </div>
      </div>
      <Card border="0" className="shadow p-3 pb-4 mb-4">
        <Card.Body className="p-0 p-md-4">
          <Form className="mt-4" onSubmit={handleSubmit}>
            <Row className="justify-content-center">
              {!editUser ? (
                <>
                  <Col xs={12} lg={6}>
                    <Form.Label key="userRole" htmlFor="userRole">
                      Role here
                    </Form.Label>
                    <Form.Select
                      defaultValue={userRole}
                      onChange={(e) => onUserRoleChange(e.target.value)}
                      className="mb-4"
                      id="userRole"
                    >
                      <option value="">Choose...</option>
                      {roles.map((row, index) => {
                        return (
                          <>
                            {' '}
                            <option key={index} value={row._id}>
                              {row.name}
                            </option>{' '}
                          </>
                        );
                      })}
                    </Form.Select>
                  </Col>
                  {userRole._id != 4 && userRole._id != 3 && userRole._id != 2 && <Col xs={12} lg={6}></Col>}
                  {userRole._id == 2 && (
                    <>
                      <Col xs={12} lg={6}>
                        <Form.Label key="userClinic" htmlFor="userClinic">
                          Chain
                        </Form.Label>
                        <Form.Select
                          defaultValue={userChain}
                          onChange={(e) => setUserChain(e.target.value)}
                          className="mb-4"
                          id="userClinic"
                        >
                          <option value="">Choose...</option>
                          {adminAuthReducer.chains &&
                            adminAuthReducer.chains.map((row, index) => {
                              return (
                                <>
                                  {' '}
                                  <option key={index} value={row._id}>
                                    {row.name}
                                  </option>{' '}
                                </>
                              );
                            })}
                        </Form.Select>
                      </Col>
                    </>
                  )}
                  {userRole._id == 4 && (
                    <Col xs={12} lg={6}>
                      <Form.Label key="userDoctor" htmlFor="userDoctor">
                        Doctor id.
                      </Form.Label>
                      <Form.Select
                        defaultValue={userDoctor}
                        onChange={(e) => onDoctorChange(e.target.value)}
                        className="mb-4"
                        id="userDoctor"
                      >
                        <option value="">Choose...</option>
                        {doctor.map((row, index) => {
                          return (
                            <>
                              {' '}
                              <option key={index} value={row._id}>
                                {row.doctorId}
                              </option>{' '}
                            </>
                          );
                        })}
                      </Form.Select>
                    </Col>
                  )}

                  {userRole._id == 3 && (
                    <>
                      <Col xs={12} lg={6}>
                        <Form.Label key="userClinic" htmlFor="userClinic">
                          Clinics
                        </Form.Label>
                        <Form.Select
                          defaultValue={userClinic}
                          onChange={(e) => setUserClinic(e.target.value)}
                          className="mb-4"
                          id="userClinic"
                        >
                          <option value="">Choose...</option>
                          {adminAuthReducer.clinics &&
                            adminAuthReducer.clinics.map((row, index) => {
                              return (
                                <>
                                  {' '}
                                  <option key={index} value={row._id}>
                                    {row.clinicId}
                                  </option>{' '}
                                </>
                              );
                            })}
                        </Form.Select>
                      </Col>
                    </>
                  )}
                </>
              ) : (
                <>
                  <Col xs={12} lg={6}>
                    <Form.Label key="userRole" htmlFor="userRole">
                      Role
                    </Form.Label>
                    <Form.Select
                      value={userRole}
                      onChange={(e) => onUserRoleChange(e.target.value)}
                      className="mb-4"
                      id="userRole"
                    >
                      <option value="">Choose...</option>
                      {roles.map((row, index) => {
                        return (
                          <>
                            {' '}
                            <option key={index} value={row._id}>
                              {row.name}
                            </option>{' '}
                          </>
                        );
                      })}
                    </Form.Select>
                  </Col>

                  {userRole._id != 4 && userRole._id != 3 && userRole._id != 2 && <Col xs={12} lg={6}></Col>}
                  {userRole == 2 && (
                    <>
                      <Col xs={12} lg={6}>
                        <Form.Label key="userChain" htmlFor="userChain">
                          Chain
                        </Form.Label>
                        <Form.Select
                          value={userChain}
                          defaultValue={userChain}
                          disabled={true}
                          onChange={(e) => setUserChain(e.target.value)}
                          className="mb-4"
                          id="userChain"
                        >
                          <option value="">Choose...</option>
                          {adminAuthReducer.chains &&
                            adminAuthReducer.chains.map((row, index) => {
                              return (
                                <>
                                  {' '}
                                  <option key={index} value={row._id}>
                                    {row.name}
                                  </option>{' '}
                                </>
                              );
                            })}
                        </Form.Select>
                      </Col>
                    </>
                  )}
                  {userRole == 4 && (
                    <>
                      <Col xs={12} lg={6}>
                        <Form.Label key="userDoctor" htmlFor="userDoctor">
                          Doctor id.
                        </Form.Label>
                        <Form.Select
                          value={userDoctor}
                          disabled={true}
                          onChange={(e) => onDoctorChange(e.target.value)}
                          className="mb-4"
                          id="userDoctor"
                        >
                          <option value="">Choose...</option>
                          {doctor.map((row, index) => {
                            return (
                              <>
                                <option key={index} value={row._id}>
                                  {row.doctorId}
                                </option>
                              </>
                            );
                          })}
                        </Form.Select>
                      </Col>
                    </>
                  )}
                  {userRole === 3 && (
                    <>
                      <Col xs={12} lg={6}>
                        <Form.Label key="userClinic" htmlFor="userClinic">
                          Clinics
                        </Form.Label>
                        <Form.Select
                          defaultValue={userClinic._id}
                          disabled={true}
                          onChange={(e) => setUserClinic(e.target.value)}
                          className="mb-4"
                          id="userClinic"
                        >
                          <option value={userClinic._id}>{userClinic.clinicId}</option>
                        </Form.Select>
                      </Col>
                      <Col xs={12} lg={6}></Col>
                    </>
                  )}
                </>
              )}

              {!editUser ? (
                <>
                  <Col xs={12} lg={9}>
                    <Form.Group id="userFullName" className="mb-4">
                      <Form.Label>Full Name</Form.Label>
                      <Form.Control
                        autoFocus
                        required
                        type="text"
                        value={userFullName}
                        onChange={(e) => setuserFullName(e.target.value)}
                        placeholder="FirstName + LastName"
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12} lg={3}></Col>
                  <Col xs={12} lg={9}>
                    <Form.Group id="userName" className="mb-4">
                      <Form.Label>Username</Form.Label>
                      <Form.Control
                        autoFocus
                        required
                        type="text"
                        value={userName}
                        onChange={(e) => setuserName(e.target.value)}
                        placeholder="Username"
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12} lg={3}></Col>
                  <Col xs={12} lg={9}>
                    <Form.Group id="userEmail" className="mb-4">
                      <RequiredLabel label="Email address" />
                      <Form.Control
                        autoFocus
                        required
                        type="text"
                        value={userEmail}
                        onChange={(e) => setuserEmail(e.target.value)}
                        placeholder="Email"
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12} lg={3}></Col>
                </>
              ) : (
                <>
                  <Col xs={12} lg={6}>
                    <Form.Group id="userFullName" className="mb-4">
                      <Form.Label>Full Name</Form.Label>
                      <Form.Control
                        autoFocus
                        required
                        type="text"
                        value={userFullName}
                        onChange={(e) => setuserFullName(e.target.value)}
                        placeholder="FirstName + LastName"
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12} lg={6}>
                    <Form.Group id="userName" className="mb-4">
                      <Form.Label>Username</Form.Label>
                      <Form.Control
                        autoFocus
                        required
                        disabled={true}
                        type="text"
                        value={userName}
                        onChange={(e) => setuserName(e.target.value)}
                        placeholder="Username"
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12} lg={6}>
                    <Form.Group id="userEmail" className="mb-4">
                      <RequiredLabel label="Email address" />
                      <Form.Control
                        autoFocus
                        required
                        type="text"
                        value={userEmail}
                        onChange={(e) => setuserEmail(e.target.value)}
                        placeholder="Email"
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12} lg={6}>
                    <Form.Label key="userStatus" htmlFor="userStatus">
                      Status
                    </Form.Label>
                    <Form.Select
                      value={userStatus}
                      onChange={(e) => setUserStatus(e.target.value)}
                      className="mb-4"
                      id="userStatus"
                    >
                      <option value="true">Active</option>
                      <option value="false">In-active</option>
                    </Form.Select>
                  </Col>
                </>
              )}

              {editUser ? (
                <Col xs={12} className="button-color">
                  <Button variant="gray-800" className="animate-up-2 mt-2" size="lg" type="submit">
                    {' '}
                    Update{' '}
                  </Button>
                </Col>
              ) : (
                <Col xs={12} className="button-color">
                  <Button variant="gray-800" className="animate-up-2 mt-2" size="lg" type="submit">
                    {' '}
                    Confirm{' '}
                  </Button>
                </Col>
              )}
            </Row>
          </Form>
        </Card.Body>
      </Card>
      {adminAuthReducer.isLoading ? <Loader /> : ''}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    auth: bindActionCreators(authAction, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(AddUser);
