import React, { useState, useEffect } from "react";
import { HomeIcon, PlusIcon, SearchIcon } from "@heroicons/react/solid";
import {
  Col,
  Row,
  Form,
  Card,
  Button,
  Breadcrumb,
  InputGroup,
} from "react-bootstrap";

import { Routing } from "routes";
import { bindActionCreators } from "redux";
import { connect, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as authAction from "actions/authActions";

import ChainLevel from "./ChainLevel";
import ClinicLevel from "./ClinicLevel";
import DoctorLevel from "./DoctorLevel";

import "./style.css";
import DoctorConfirm from "./DoctorConfirm";
import ClinicConfirm from "./ClinicConfirm";
import ChainConfirm from "./ChainConfirm";
import KpiDashboard from "./KpiDashboard";
import { makeStyles } from "@mui/styles";
import { useLocation } from "react-router-dom";
import decode from "jwt-decode";
import _ from "lodash";
import * as kpiPlannerAction from "../../actions/kpiPlannerAction";
import swal from "sweetalert";
const useStyles = makeStyles(() => ({
  p: {
    fontFamily: "Poppins",
    fontWeight: "500",
    color: "#2f4858",
  },
}));

const MultiClinic = () => {
  const classes = useStyles();
  const location = useLocation();

  //state for steps
  const [step, setStep] = useState(1);
  const [name, setName] = useState("");
  const [isViewMode, setIsViewMode] = useState(false);

  const queryParams = new URLSearchParams(window.location.search);
  const viewMode = queryParams.get("viewMode");
  const _id = queryParams.get("_id");

  const [selectedYear, setSelectedYear] = useState(queryParams.get("year"));
  const [chainId, setChainId] = useState(null);
  const [kpiProgress, setKpiProgress] = useState(null);

  // function for going to next step by increasing step state by 1
  const nextStep = () => {
    window.scrollTo(0, 0);
    setStep(location.state.page + 1);
  };

  const setStepPage = (value) => {
    setStep(value);
  };

  // function for going to previous step by decreasing step state by 1
  const prevStep = () => {
    window.scrollTo(0, 0);
    setStep(location.state.page - 1);
  };

  // handling form input data by taking onchange value and updating our previous form data state
  const handleInputData = (input) => (e) => {
    // viewMode
    if (location.state?.viewMode) return;
    // input value from the form
    const { value } = e.target;

    //updating for data state taking previous state and then adding new value to create new object
    // setFormData(prevState => ({
    //     ...prevState,
    //     [input]: value
    // }));
  };

  const history = useHistory();
  const redirectUrl = (url) => {
    history.push(url);
  };

  useEffect(() => {
    kpiPlannerAction
      .getKpiProgressStatus(_id, selectedYear)
      .then((response) => {
        setKpiProgress(response);
      });

    if (viewMode && _id) {
      setIsViewMode(true);
      switch (viewMode) {
        case "chain": {
          setStep(6);
          return;
        }

        case "clinic": {
          setStep(5);
          return;
        }

        case "doctor": {
          setStep(4);
          return;
        }
      }
    } else {
      const accessToken = localStorage.getItem("accessToken");
      if (accessToken !== "undefined" && accessToken !== null) {
        const userProfile = decode(accessToken);
        if (
          userProfile.user &&
          !_.isEmpty(userProfile.user.chain) &&
          userProfile.user.chain._id
        ) {
          setChainId(userProfile.user.chain._id);
        } else {
          setChainId(1);
        }
        if (userProfile.user && !_.isEmpty(userProfile.user.clinic)) {
          setStep(2);
          return;
        }
        if (userProfile.user && !_.isEmpty(userProfile.user.doctor)) {
          setStep(3);
          return;
        }
      }
    }
  }, []);

  switch (step) {
    case 1:
      return (
        <>
          <div
            className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4"
            style={{ fontFamily: "Poppins", fontWeight: "500" }}
          >
            <div className="d-block mb-4 mb-md-0">
              <Breadcrumb
                className="d-none d-md-inline-block"
                listProps={{
                  className: "breadcrumb-dark breadcrumb-transparent",
                }}
              >
                <Breadcrumb.Item
                  onClick={() => redirectUrl(Routing.Dashboard.path)}
                >
                  <HomeIcon className="icon icon-xs" />
                </Breadcrumb.Item>
                <Breadcrumb.Item
                  onClick={() => redirectUrl(Routing.KpiPlanner.path)}
                  className={classes.p}
                >
                  KPI Planner
                </Breadcrumb.Item>
                <Breadcrumb.Item active className={classes.p}>
                  {" "}
                  Chain: KPI
                </Breadcrumb.Item>
              </Breadcrumb>
              <h4 className={classes.p}> Chain {name}: KPI Planning Process</h4>
            </div>
          </div>
          <ChainLevel
            step={location.state}
            nextStep={nextStep}
            setStepPage={setStepPage}
            setName={setName}
            handleFormData={handleInputData}
            className={classes.p}
            viewMode={isViewMode}
            year={selectedYear}
          />
        </>
      );
    case 2:
      return (
        <>
          <div
            className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4"
            style={{ fontFamily: "Poppins", fontWeight: "500" }}
          >
            <div className="d-block mb-4 mb-md-0">
              <Breadcrumb
                className="d-none d-md-inline-block"
                listProps={{
                  className: "breadcrumb-dark breadcrumb-transparent",
                }}
              >
                <Breadcrumb.Item
                  onClick={() => redirectUrl(Routing.Dashboard.path)}
                >
                  <HomeIcon className="icon icon-xs" />
                </Breadcrumb.Item>
                <Breadcrumb.Item
                  onClick={() => redirectUrl(Routing.KpiPlanner.path)}
                  className={classes.p}
                >
                  KPI Planner
                </Breadcrumb.Item>
                <Breadcrumb.Item active className={classes.p}>
                  Clinic: KPI
                </Breadcrumb.Item>
              </Breadcrumb>
              <h4 className={classes.p}>Clinic {name}: KPI Planning Process</h4>
            </div>
          </div>
          <ClinicLevel
            step={location.state}
            setName={setName}
            nextStep={nextStep}
            setStepPage={setStepPage}
            prevStep={prevStep}
            handleFormData={handleInputData}
            className={classes.p}
            viewMode={isViewMode}
            year={selectedYear}
          />
        </>
      );
    case 3:
      return (
        <>
          <div
            className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4"
            style={{ fontFamily: "Poppins", fontWeight: "500" }}
          >
            <div className="d-block mb-4 mb-md-0">
              <Breadcrumb
                className="d-none d-md-inline-block"
                listProps={{
                  className: "breadcrumb-dark breadcrumb-transparent",
                }}
              >
                <Breadcrumb.Item
                  onClick={() => redirectUrl(Routing.Dashboard.path)}
                >
                  <HomeIcon className="icon icon-xs" />
                </Breadcrumb.Item>
                <Breadcrumb.Item
                  onClick={() => redirectUrl(Routing.KpiPlanner.path)}
                  className={classes.p}
                >
                  KPI Planner
                </Breadcrumb.Item>
                <Breadcrumb.Item active className={classes.p}>
                  Doctor: KPI
                </Breadcrumb.Item>
              </Breadcrumb>
              <h4 className={classes.p}>Doctor {name}: KPI Planning Process</h4>
            </div>
          </div>
          <DoctorLevel
            step={location.state}
            setName={setName}
            nextStep={nextStep}
            setStepPage={setStepPage}
            prevStep={prevStep}
            handleFormData={handleInputData}
            className={classes.p}
            viewMode={isViewMode}
            year={selectedYear}
          />
        </>
      );
    case 4:
      return (
        <>
          <div
            className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4"
            style={{ fontFamily: "Poppins", fontWeight: "500" }}
          >
            <div className="d-block mb-4 mb-md-0">
              <Breadcrumb
                className="d-none d-md-inline-block"
                listProps={{
                  className: "breadcrumb-dark breadcrumb-transparent",
                }}
              >
                <Breadcrumb.Item
                  onClick={() => redirectUrl(Routing.Dashboard.path)}
                >
                  <HomeIcon className="icon icon-xs" />
                </Breadcrumb.Item>
                <Breadcrumb.Item
                  onClick={() => redirectUrl(Routing.KpiPlanner.path)}
                  className={classes.p}
                >
                  KPI Planner
                </Breadcrumb.Item>
                <Breadcrumb.Item active className={classes.p}>
                  Doctor: KPI
                </Breadcrumb.Item>
              </Breadcrumb>
              <h4 className={classes.p}>Doctor {name}: KPI Planning Process</h4>
            </div>
          </div>
          <DoctorConfirm
            kpiProgress={kpiProgress}
            step={location.state}
            setName={setName}
            nextStep={nextStep}
            prevStep={prevStep}
            handleFormData={handleInputData}
            className={classes.p}
            viewMode={isViewMode}
            year={selectedYear}
          />
        </>
      );

    case 5:
      return (
        <>
          <div
            className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4"
            style={{ fontFamily: "Poppins", fontWeight: "500" }}
          >
            <div className="d-block mb-4 mb-md-0">
              <Breadcrumb
                className="d-none d-md-inline-block"
                listProps={{
                  className: "breadcrumb-dark breadcrumb-transparent",
                }}
              >
                <Breadcrumb.Item
                  onClick={() => redirectUrl(Routing.Dashboard.path)}
                >
                  <HomeIcon className="icon icon-xs" />
                </Breadcrumb.Item>
                <Breadcrumb.Item
                  onClick={() => redirectUrl(Routing.KpiPlanner.path)}
                  className={classes.p}
                >
                  KPI Planner
                </Breadcrumb.Item>
                <Breadcrumb.Item active className={classes.p}>
                  Clinic: KPI
                </Breadcrumb.Item>
              </Breadcrumb>
              <h4 className={classes.p}>Clinic {name}: KPI Planning Process</h4>
            </div>
          </div>
          <ClinicConfirm
            kpiProgress={kpiProgress}
            step={location.state}
            setName={setName}
            nextStep={nextStep}
            prevStep={prevStep}
            handleFormData={handleInputData}
            className={classes.p}
            viewMode={isViewMode}
            year={selectedYear}
          />
        </>
      );

    case 6:
      return (
        <>
          <div
            className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4"
            style={{ fontFamily: "Poppins", fontWeight: "500" }}
          >
            <div className="d-block mb-4 mb-md-0">
              <Breadcrumb
                className="d-none d-md-inline-block"
                listProps={{
                  className: "breadcrumb-dark breadcrumb-transparent",
                }}
              >
                <Breadcrumb.Item
                  onClick={() => redirectUrl(Routing.Dashboard.path)}
                >
                  <HomeIcon className="icon icon-xs" />
                </Breadcrumb.Item>
                <Breadcrumb.Item
                  onClick={() => redirectUrl(Routing.KpiPlanner.path)}
                  className={classes.p}
                >
                  KPI Planner
                </Breadcrumb.Item>
                <Breadcrumb.Item active className={classes.p}>
                  Chain: KPI
                </Breadcrumb.Item>
              </Breadcrumb>
              <h4 className={classes.p}>Chain {name}: KPI Planning Process</h4>
            </div>
          </div>
          <ChainConfirm
            kpiProgress={kpiProgress}
            step={location.state}
            setName={setName}
            nextStep={nextStep}
            prevStep={prevStep}
            handleFormData={handleInputData}
            className={classes.p}
            viewMode={isViewMode}
            year={selectedYear}
          />
        </>
      );
    default:
      return (
        <>
          <div
            className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4"
            style={{ fontFamily: "Poppins", fontWeight: "500" }}
          >
            <div className="d-block mb-4 mb-md-0">
              <Breadcrumb
                className="d-none d-md-inline-block"
                listProps={{
                  className: "breadcrumb-dark breadcrumb-transparent",
                }}
              >
                <Breadcrumb.Item
                  onClick={() => redirectUrl(Routing.Dashboard.path)}
                >
                  <HomeIcon className="icon icon-xs" />
                </Breadcrumb.Item>
                {/* <Breadcrumb.Item onClick={() => redirectUrl(Routing.KpiPlanner.path)}>KPI Planner</Breadcrumb.Item> */}
                <Breadcrumb.Item active className={classes.p}>
                  KPI Planner
                </Breadcrumb.Item>
              </Breadcrumb>
              {/* <h4><KpiDashboard/></h4> */}
            </div>
          </div>
          <Card border="0" className="table-wrapper table-responsive shadow">
            <Card.Body>
              <Row
                className="justify-content-center-left"
                style={{ fontFamily: "Poppins", fontWeight: "500" }}
              >
                <Col xs={12} lg={8} style={{ marginLeft: "0.1em" }}>
                  <KpiDashboard />
                </Col>
                <Col xs={1} lg={1}></Col>
              </Row>
            </Card.Body>
          </Card>
        </>
      );
  }
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    adminAction: bindActionCreators(authAction, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(MultiClinic);
