import React from "react";
import moment from 'moment';
import "./availableDaytime.styles.css"
import {avaPeriodDayStart, avaPeriodDayEnd, 
    avaPeriodMonthStart, avaPeriodMonthEnd, 
    avaPeriodYearStart, avaPeriodYearEnd} from "../availablePeriod/availablePeriod.component";

let avaTimeRaw, avaTime;

const dayArrToNumArr = (darr) => {
    let dayArr = []
    for (let i = 0; i < darr.length; i++) {
        if (darr[i]==='Monday') {dayArr.push(1);}
        else if (darr[i]==='Tuesday') {dayArr.push(2);}
        else if (darr[i]==='Wednesday') {dayArr.push(3);}
        else if (darr[i]==='Thursday') {dayArr.push(4);}
        else if (darr[i]==='Friday') {dayArr.push(5);}
        else if (darr[i]==='Saturday') {dayArr.push(6);}
        else if (darr[i]==='Sunday') {dayArr.push(0);}
        else {dayArr.push();}
    }
    return dayArr
}

const numToDay = (numd) => {
    let days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    return days[numd];
}

const getDates = (startDate, endDate, dayNum) => {
    const results = []
    
    for (let i = 0; i < dayNum.length; i++) {
        let current = moment(startDate)
        const end = moment(endDate)
        // If current is on the wrong weekday, move it forward to the first matching weekday:
        if (current.weekday() !== dayNum[i]) {
            current.add(dayNum[i] >= current.weekday() ? ( dayNum[i] - current.weekday() ) : ( 7 - ( current.weekday() - dayNum[i] ) ), 'day')
        }
        
        while(current.isSameOrBefore(end)) {
            results.push(current.clone())
            current.add(7, 'day')
        }
    }
    return results
}

const AvailableDaytime = ({ target, timing }) => {
    let dstart = new Date(avaPeriodYearStart, avaPeriodMonthStart-1, avaPeriodDayStart)
    let dend = new Date(avaPeriodYearEnd, avaPeriodMonthEnd-1, avaPeriodDayEnd)
    let result = getDates(dstart, dend, dayArrToNumArr(target))
    let avaDay, avaDate, avaMonth, avaYear
    avaTime = []
    avaTimeRaw = []

    for (let i = 0; i < result.length; i++) {
        avaDay = moment(result[i]._d).toDate().getDay()
        avaDate = moment(result[i]._d).toDate().getDate()
        avaMonth = moment(result[i]._d).toDate().getMonth() 
        avaYear = moment(result[i]._d).toDate().getFullYear()
        avaTimeRaw.push(moment(result[i]._d).toDate()) //Array of Moment Date Objects 
        avaTime.push(`${avaDate}/${avaMonth+1}/${avaYear} (${numToDay(avaDay).substring(0,3)})`) //Array of Formatted Moment Dates 
    }
    // console.log({avaTime})

    // return (
    //     <div>
    //         {avaTime.map((avatime) => <h4 key={avatime}>{avatime} {timing}</h4>)}
    //     </div>
    // );
    return null
}

export default AvailableDaytime;
export {avaTime, avaTimeRaw};