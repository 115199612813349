import React, { useState, useEffect, useCallback } from "react";
import { useChainId } from "hooks/useChainId";
import { Col, Row, Form, Button as BootstrapButton } from "react-bootstrap";
import Button from "@mui/material/Button";
import { Routing } from "routes";
import { connect, useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import * as kpiPlannerAction from "actions/kpiPlannerAction";
import { getClinics } from "actions/authActions";
import "./style.css";
import ENVIRONMENT_VARIABLES from "../../config";
import axios from "axios";

import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import moment from "moment-timezone";
import { KPI_STATUS_CONFIRMED, KPI_STATUS_NOT_STARTED, KPI_STATUS_TO_CONFIRM } from "../../constants/actionsTypes";
import Loader from "../Loader";
const useStyles = makeStyles(() => ({
  txt: {
    fontFamily: "Poppins",
    color: "#2f4858",
  },
  p: {
    fontFamily: "Poppins",
    borderColor: "#2f4858",
    color: "#2f4858",
    fontWeight: "500",
  },
}));

export const KpiDashboard = ({ userKPISummary }) => {
  const chainId = useChainId();
  const dispatch = useDispatch();
  const loggedIN = useSelector((state) => state.adminAuthReducer.adminUser);
  const isChainManager = useSelector((state) => state.adminAuthReducer.adminUser.chain);
  const kpiData = useSelector((state) => state.kpiPlannerReducer.userKPISummary);
  console.log(kpiData)
  const clinics = useSelector((state) => state.adminAuthReducer.clinics);
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const queryParams = new URLSearchParams(window.location.search);
  const clinicId = queryParams.get("clinicId");
  console.log(clinicId)
  const [selectedClinic, setSelectedClinic] = useState(clinicId);
  const yearId = queryParams.get("year");
  const [selectedYear, setSelectedYear] = useState(yearId || moment().year());
  const [yearsList, setYearsList] = useState([
    moment().year(),
    moment().year() + 1,
    moment().year() + 2,
    moment().year() + 3,
  ]);

  const history = useHistory();
  const redirectUrl = (url, data) => {
    history.push({
      pathname: url,
      state: data,
    });
  };

  // test this
  const filterKpi = () => {
    dispatch(kpiPlannerAction.getUserKPISummary(0, selectedClinic, selectedYear));
  };

  const getNumbeOfYears = async () => {
    try {
      const token = "Bearer " + localStorage.getItem("accessToken");
      let api = {
        method: "get",
        headers: { Authorization: token },
        url: ENVIRONMENT_VARIABLES.Base_API_URL + "/Kpi/getKpiYears",
      };
      const response = await axios(api);

      if (response.status === 200) {
        let dataYearsList = [
          ...response.data.data,
          moment().year(),
          moment().year() + 1,
          moment().year() + 2,
          moment().year() + 3,
        ];
        dataYearsList = [...new Set(dataYearsList.sort((a, b) => a - b))];
        setYearsList(dataYearsList);
      }
    } catch (error) {}
  };

  useEffect(() => {
    dispatch(getClinics(chainId));
    getNumbeOfYears();
    filterKpi();
  }, []);

  if (loading) {
    return <Loader></Loader>;
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={0} md={3} lg={5}></Grid>
        <Grid item xs={12} md={9} lg={7}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4} lg={5} className="mb-4 justify-content-center">
              {loggedIN.Role.id !== 3 && (
                <>
                  <p className={classes.p}> Filter By: </p>
                  <Form.Group id="Reference" className="mb-4">
                    <Form.Select
                      value={selectedClinic}
                      onChange={(e) => {
                        e.preventDefault();
                        setSelectedClinic(e.target.value);
                      }}
                      className={classes.p}
                      id="filter-clinic"
                    >
                      <option value="">All clinics</option>
                      {clinics.map((c) => (
                        <option value={c.clinicId.toUpperCase()}>{c.clinicId}</option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </>
              )}
            </Grid>
            <Grid item xs={12} md={4} lg={5}>
              <p className={classes.p}> KPI Year:</p>
              <Form.Group id="year" className="mb-4">
                <Form.Select
                  value={selectedYear}
                  onChange={(e) => {
                    e.preventDefault();
                    setSelectedYear(e.target.value);
                  }}
                  className={classes.p}
                  id="filter-year"
                >
                  {yearsList?.map((y, i) => (
                    <option key={i} value={y}>
                      {y}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Grid>
            <Grid item xs={12} md={4} lg={2}>
              <p className={classes.p}></p>
              <span className="button-color">
                <BootstrapButton
                  onClick={filterKpi}
                  variant="primary"
                  className="animate-up-2 btn btn-gray-800 btn-lg mt-3"
                  type="button"
                >
                  Apply
                </BootstrapButton>
              </span>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={12} lg={12}>
          <Row className="justify-content-left">
            <Col xs={12} lg={12}>
              <table className="table table-bordered kpi-dashboard-table">
                <tbody className={classes.p}>
                  <tr className={classes.p}>
                    <th scope="col" style={{ width: "25%" }}>
                      ENTITY
                    </th>
                    <th scope="col" style={{ width: "25%" }}>
                      PARENT
                    </th>
                    <th scope="col">Date Created</th>
                    <th scope="col">ACTION</th>
                  </tr>
                  {kpiData?.map((item, index) => {
                    return (
                      <>
                        <tr
                          key={index}
                          style={{
                            backgroundColor:
                              item.status.text === "Confirmed"
                                ? "#33ff33"
                                : item.status.text === "To Confirm"
                                ? "#ffff33"
                                : item.status.text === "Proposed"
                                ? "#ffb833"
                                : item.status.text === "Not Started"
                                ? "#ff3333"
                                : "",
                            fontFamily: "Poppins",
                            color: "#2f4858",
                            fontSize: "2em",
                            borderColor: "#2f4858",
                          }}
                        >
                          <td>{item.entryName}</td>
                          <td>{item.parentName}</td>
                          <td>{moment(item.createdAt).format("DD/MM/YYYY H:m:s")}</td>
                          <td>
                            <tr
                              className={classes.p}
                              style={{
                                display: "flex",
                                justifyContent: "flex-start",
                              }}
                            >
                              <td>
                                <Button
                                  variant="outline-primary"
                                  size="sm"
                                  onClick={() => {
                                    let _url = Routing.MultiClinic.path + `?year=${selectedYear}&viewMode=`;
                                    switch (item.type) {
                                      case "chain_kpi": {
                                        _url += `chain&_id=${item._id}`;
                                        break;
                                      }

                                      case "clinic_kpi": {
                                        _url += `clinic&_id=${item._id}&clinicId=${item.entryName}`;
                                        break;
                                      }
                                      case "doctor_kpi": {
                                        _url += `doctor&_id=${item._id}&doctorId=${
                                          item.entryId ? item.entryId : item.entryName
                                        }&clinicId=${item?.parentName ? item.parentName : item.entryName}`;
                                        break;
                                      }
                                      default: {
                                        _url = _url + "";
                                      }
                                    }

                                    redirectUrl(_url);
                                  }}
                                  style={{ fontWeight: "bold" }}
                                >
                                  View
                                </Button>
                              </td>
                              {item.status._id === KPI_STATUS_NOT_STARTED && (
                                <td>
                                  <Button
                                    variant="outline-primary"
                                    size="sm"
                                    onClick={() =>
                                      redirectUrl(Routing.MultiClinic.path + `?year=${selectedYear}&_id=${item._id}`)
                                    }
                                    style={{ fontWeight: "bold" }}
                                  >
                                    Propose
                                  </Button>
                                </td>
                              )}
                              {item.status._id === KPI_STATUS_TO_CONFIRM &&
                                isChainManager &&
                                item.type === "chain_kpi" && (
                                  <td>
                                    <Button
                                      variant="outline-primary"
                                      size="sm"
                                      onClick={() =>
                                        redirectUrl(
                                          Routing.MultiClinic.path +
                                            `?year=${selectedYear}&viewMode=chain&confirmMode=1&_id=${item._id}`
                                        )
                                      }
                                      style={{ fontWeight: "bold" }}
                                    >
                                      {" "}
                                      Confirm{" "}
                                    </Button>
                                  </td>
                                )}
                            </tr>
                          </td>
                        </tr>
                        {item.Clinics?.map((clinic, clinicIndex) => {
                          if (clinic.KpiClinics.length) {
                            return (
                              <>
                                <tr
                                  key={clinicIndex}
                                  style={{
                                    backgroundColor:
                                      clinic.KpiStatus.statusName === "Confirmed"
                                        ? "#33ff33"
                                        : clinic.KpiStatus.statusName === "To Confirm"
                                        ? "#ffff33"
                                        : clinic.KpiStatus.statusName === "Proposed"
                                        ? "#ffb833"
                                        : clinic.KpiStatus.statusName === "Not Started"
                                        ? "#ff3333"
                                        : "",
                                    fontFamily: "Poppins",
                                    color: "#2f4858",
                                    fontSize: "2em",
                                    borderColor: "#2f4858",
                                  }}
                                >
                                  <td>{clinic.KpiClinics[0].clinicId}</td>
                                  <td>{item.entryName}</td>
                                  <td>{moment(clinic.createdAt).format("DD/MM/YYYY H:m:s")}</td>
                                  <td>
                                    <tr
                                      className={classes.p}
                                      style={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                      }}
                                    >
                                      <td>
                                        <Button
                                          variant="outline-primary"
                                          size="sm"
                                          onClick={() =>
                                            redirectUrl(
                                              Routing.MultiClinic.path +
                                                `?year=${selectedYear}&viewMode=clinic&_id=${clinic._id}&clinicId=${clinic.KpiClinics[0].clinicId}`
                                            )
                                          }
                                          style={{ fontWeight: "bold" }}
                                        >
                                          {" "}
                                          View{" "}
                                        </Button>
                                      </td>
                                      {clinic.KpiStatus._id === KPI_STATUS_TO_CONFIRM && (
                                        <td>
                                          <Button
                                            variant="outline-primary"
                                            size="sm"
                                            onClick={() =>
                                              redirectUrl(
                                                Routing.MultiClinic.path +
                                                  `?year=${selectedYear}&viewMode=clinic&confirmMode=1&_id=${clinic._id}&clinicId=${clinic.KpiClinics[0].clinicId}`
                                              )
                                            }
                                            style={{ fontWeight: "bold" }}
                                          >
                                            {" "}
                                            Confirm{" "}
                                          </Button>
                                        </td>
                                      )}
                                    </tr>
                                  </td>
                                </tr>

                                {clinic.Doctors?.map((doctor, doctorIndex) => {
                                  return (
                                    <>
                                      {!!doctor.KpiDoctors.length && (
                                        <tr
                                          key={doctorIndex}
                                          style={{
                                            backgroundColor:
                                              doctor.KpiStatus.statusName === "Confirmed"
                                                ? "#33ff33"
                                                : doctor.KpiStatus.statusName === "To Confirm"
                                                ? "#ffff33"
                                                : doctor.KpiStatus.statusName === "Proposed"
                                                ? "#ffb833"
                                                : doctor.KpiStatus.statusName === "Not Started"
                                                ? "#ff3333"
                                                : "",
                                            fontFamily: "Poppins",
                                            color: "#2f4858",
                                            fontSize: "2em",
                                            borderColor: "#2f4858",
                                          }}
                                        >
                                          <td>{doctor?.KpiDoctors[0]?.Doctor.name}</td>
                                          <td>{clinic.KpiClinics[0].clinicId}</td>
                                          <td>{moment(doctor.createdAt).format("DD/MM/YYYY H:m:s")}</td>
                                          <td>
                                            <tr
                                              className={classes.p}
                                              style={{
                                                display: "flex",
                                                justifyContent: "flex-start",
                                              }}
                                            >
                                              <td>
                                                <Button
                                                  variant="outline-primary"
                                                  size="sm"
                                                  onClick={() =>
                                                    redirectUrl(
                                                      Routing.MultiClinic.path +
                                                        `?year=${selectedYear}&viewMode=doctor&_id=${doctor._id}&doctorId=${doctor.KpiDoctors[0].doctorId}&clinicId=${clinic.KpiClinics[0].clinicId}`
                                                    )
                                                  }
                                                  style={{ fontWeight: "bold" }}
                                                >
                                                  {" "}
                                                  View{" "}
                                                </Button>
                                              </td>
                                            </tr>
                                          </td>
                                        </tr>
                                      )}
                                    </>
                                  );
                                })}
                              </>
                            );
                          }
                        })}

                        {item.Doctors?.map((doctor, doctorIndex) => {
                          return (
                            <>
                              <tr
                                key={doctorIndex}
                                style={{
                                  backgroundColor:
                                    doctor.KpiStatus.statusName === "Confirmed"
                                      ? "#33ff33"
                                      : doctor.KpiStatus.statusName === "To Confirm"
                                      ? "#ffff33"
                                      : doctor.KpiStatus.statusName === "Proposed"
                                      ? "#ffb833"
                                      : doctor.KpiStatus.statusName === "Not Started"
                                      ? "#ff3333"
                                      : "",
                                  fontFamily: "Poppins",
                                  color: "#2f4858",
                                  fontSize: "2em",
                                  borderColor: "#2f4858",
                                }}
                              >
                                <td>{doctor?.KpiDoctors[0]?.Doctor.name}</td>
                                <td>{doctor?.parentName ? doctor.parentName : item.entryName}</td>
                                <td>{moment(doctor.createdAt).format("DD/MM/YYYY H:m:s")}</td>
                                <td>
                                  <tr
                                    className={classes.p}
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-start",
                                    }}
                                  >
                                    <td>
                                      <Button
                                        variant="outline-primary"
                                        size="sm"
                                        onClick={() =>
                                          redirectUrl(
                                            Routing.MultiClinic.path +
                                              `?year=${selectedYear}&viewMode=doctor&_id=${doctor._id}&doctorId=${
                                                doctor.KpiDoctors[0].doctorId
                                              }&clinicId=${doctor?.parentName ? doctor.parentName : item.entryName}`
                                          )
                                        }
                                        style={{ fontWeight: "bold" }}
                                      >
                                        {" "}
                                        View{" "}
                                      </Button>
                                    </td>
                                    {doctor.status === KPI_STATUS_TO_CONFIRM && (
                                      <td>
                                        <Button
                                          variant="outline-primary"
                                          size="sm"
                                          onClick={() =>
                                            redirectUrl(
                                              Routing.MultiClinic.path +
                                                `?year=${selectedYear}&viewMode=doctor&confirmMode=1&_id=${
                                                  doctor._id
                                                }&doctorId=${doctor.KpiDoctors[0].doctorId}&clinicId=${
                                                  doctor?.parentName ? doctor.parentName : item.entryName
                                                }`
                                            )
                                          }
                                          style={{ fontWeight: "bold" }}
                                        >
                                          Confirm
                                        </Button>
                                      </td>
                                    )}
                                  </tr>
                                </td>
                              </tr>
                            </>
                          );
                        })}

                        {/* {item.clinics.map((clinic,i)=>(
                            <>
                            {
                                loggedIN.Role.id !== 4 && loggedIN.Role.id !== 5 &&

                                <tr key  ={i} className={classes.p} style={{
                                    backgroundColor: clinic.clinicKPIStatus === "Confirmed"? "#33ff33":  clinic.clinicKPIStatus === "To Confirm" ? "#ffb833": clinic.clinicKPIStatus === "Proposed" ? "#ffff33" : clinic.clinicKPIStatus === "Not Started" ? "#ff3333": "",
                                    }}>
                                    <td>{clinic.clinicName}</td>
                                    <td>{item.chainName}</td>
                                    <td>
                                        <tr style={{display: 'flex', justifyContent: 'space-between'}}>
                                            <td><Button variant="outline-primary"   size="sm" onClick={() => redirectUrl(Routing.MultiClinic.path, {page: clinic.clinicKPIStatus ==="Not Started"? 2 : 5, viewMode: true})} style={{fontWeight: "bold"}}>  View </Button></td>
                                            <td><Button variant="outline-primary" size="sm" onClick={() => redirectUrl(Routing.MultiClinic.path, {page: 2})} style={{fontWeight: "bold"}} disabled={clinic.clinicKPIStatus !== "Not Started"}> Propose  </Button></td>
                                            <td><Button  variant="outline-primary" size="sm" onClick={() => redirectUrl(Routing.MultiClinic.path, {page: 5})} style={{fontWeight: "bold"}} disabled={!(loggedIN.Role.id === 2) || clinic.clinicKPIStatus !== "To Confirm"}>    Confirm  </Button></td>
                                        </tr>
                                    </td>
                                </tr>
                            }

                            {clinic.doctors.map((doctor,j)=>(
                                <tr key = {j} className={classes.p} style={{
                                    backgroundColor: doctor.doctorKPIStatus === "Confirmed"? "#33ff33":  doctor.doctorKPIStatus === "To Confirm" ? "#ffb833": doctor.doctorKPIStatus === "Proposed" ? "#ffff33" : doctor.doctorKPIStatus === "Not Started" ? "#ff3333": "",
                                    fontFamily:'Poppins',
                                    color:'#2f4858',
                                    fontSize:"2em",
                                    borderColor:'#2f4858'
                                }}>
                                    <td >{doctor.doctorName}</td>
                                    <td>{doctor.nextAllocation}</td>
                                    <td>
                                        <tr style={{display: 'flex', justifyContent: 'space-between'}}>
                                            <td><Button variant="outline-primary"   size="sm" onClick={() => redirectUrl(Routing.MultiClinic.path, {page: doctor.doctorKPIStatus ==="Not Started"? 3 : 4, viewMode: true})} disabled={loggedIN.Role.id === 5 ? 'disabled': null} style={{fontWeight: "bold"}}>  View </Button></td>
                                            <td><Button variant="outline-primary"  size="sm" onClick={() => redirectUrl(Routing.MultiClinic.path, {page: 3})} disabled={doctor.doctorKPIStatus !== "Not Started"} style={{fontWeight: "bold"}}> Propose  </Button></td>
                                            <td><Button  variant="outline-primary"  size="sm" onClick={() => redirectUrl(Routing.MultiClinic.path, {page: 6})} disabled={!(loggedIN.Role.id === 2 || loggedIN.Role.id === 3)  || doctor.doctorKPIStatus !== "To Confirm"} style={{fontWeight: "bold"}}>    Confirm  </Button></td>
                                        </tr>
                                    </td>
                                </tr>
                            ))}
                            </>

                        ))} */}
                      </>
                    );
                  })}
                </tbody>
              </table>
              {isChainManager && kpiData?.findIndex((item) => item.status._id < KPI_STATUS_CONFIRMED) < 0 && (
                <Button
                  variant="outline-gray-600"
                  className="d-inline-flex align-items-center"
                  onClick={() =>
                    redirectUrl(Routing.MultiClinic.path + `?year=${selectedYear}`, {
                      page: 1,
                      chainId: 1,
                    })
                  }
                >
                  + KPI Planner
                </Button>
              )}
            </Col>
          </Row>
        </Grid>
      </Grid>
    </>
  );
};

export default connect(null, null)(KpiDashboard);
