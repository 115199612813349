import React, { useState, useEffect } from "react";
import { Col } from "react-bootstrap";
import _ from "lodash";
import Chart from "react-apexcharts";

import "../style.css";
import { formatAmount } from "utils/parser";

const PerHourOfTreatment = ({ isEmbedded, perHourTreatmentByInvoiceCount }) => {
  const [chartOptions, setChartOptions] = useState({
    chart: {
      type: "bar",
      stacked: true,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: "bottom",
            offsetX: -10,
            offsetY: 0,
          },
        },
      },
    ],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 0,
      },
    },
    xaxis: {
      labels: {
        show: !isEmbedded,
        rotate: -90,
        minHeight: 0,
        maxHeight: 400,
      },
      categories: [],
    },
    yaxis: {
      labels: {
        formatter: (value) => formatAmount(value),
        style: {
          fontSize: "14px",
        },
      },
    },
    legend: {
      show: !isEmbedded,
      position: "bottom",
      horizontalAlign: "center",
      offsetY: 0,
    },
    tooltip: {},
    fill: {
      opacity: 1,
    },
    colors: ["#9BA1A3", "#387F91"],
  });

  const customTooltip = ({ dataPointIndex }, sortedData) => {
    return `<div>
                <p> Treatment Name: <b>${sortedData[dataPointIndex].treatmentName}</b> </p>
                <p> Revenue/Hour: <b>${formatAmount(sortedData[dataPointIndex].revPerHour)}</b> </p>
                <p> Profit/Hour: <b>${formatAmount(sortedData[dataPointIndex].profitPerHour)}</b> </p>
                <p> Margin/Hour: <b>${parseFloat(sortedData[dataPointIndex].prcntMargin).toFixed(2)}%</b> </p>
            </div>`;
  };

  const [chartSeries, setChartSeries] = useState([
    {
      name: "Profit per hour",
      data: [],
    },
    {
      name: "Revenue per hour",
      data: [],
    },
  ]);

  useEffect(() => {
    if (!isEmbedded) { 
      window.history.replaceState(null, "Medtrik", "/finance/dashboard/per-hour-of-treatment");
      window.scrollTo(0, 0);
    }
  }, []);

  useEffect(() => {
    const data = perHourTreatmentByInvoiceCount.phtByInvoiceCountGraph;
    if (data) {
      const sortedData = data.sort((a, b) => {
        // if (a.revPerHour && b.revPerHour) {
        //   return +b.revPerHour - +a.revPerHour;
        // }
        // return +a.revPerHour || +b.revPerHour;
        return +b.revPerHour - +a.revPerHour;
      });
      console.log("sortedData #271: ", sortedData);
      const sortedTreatmentNames = [];
      let series1Data = [];
      let series2Data = [];

      sortedData.forEach((val, i) => {
        sortedTreatmentNames.push(val.treatmentName);
        series1Data.push(val.profitPerHour);
        series2Data.push(val.revPerHour - val.profitPerHour);
      });
      console.log("sortedTreatmentNames #271: ", sortedTreatmentNames);
      setChartOptions({
        ...chartOptions,
        xaxis: { categories: sortedTreatmentNames },
        tooltip: { custom: (args) => customTooltip(args, sortedData) },
      });
      setChartSeries([
        { ...chartSeries[0], data: _.compact(series1Data) },
        { ...chartSeries[1], data: _.compact(series2Data) },
      ]);
    }
  }, [perHourTreatmentByInvoiceCount]);

  return (
    <Col xs={12} lg={12} className={`${!isEmbedded ? "px-8 " : ""}overflow-chart`}>
      <Chart options={chartOptions} series={chartSeries} type="bar" height={isEmbedded ? 350 : 800} />
    </Col>
  );
};

export default PerHourOfTreatment;
