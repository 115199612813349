import React, { useState, useEffect } from 'react';
import { Col, Row, Card, Form, Button, ButtonGroup, Breadcrumb, InputGroup, Dropdown, Alert } from 'react-bootstrap';
import { AdjustmentsIcon, CheckIcon, CogIcon, HomeIcon, PlusIcon, SearchIcon } from '@heroicons/react/solid';

import { Routing } from 'routes';
import { bindActionCreators } from 'redux';
import { connect, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import swal from 'sweetalert';
import * as authAction from 'actions/authActions';
import Loader from '../Loader';
import Loading from './loading.component';

import './style.css';
import axios from 'axios';

function ImageComponent() {
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    // Fetch the image URL from the backend
    axios({
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/Oauths/check`,
    })
      .then((response) => {
        if (!response.data.data) {
          axios({
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
            method: 'GET',
            url: `${process.env.REACT_APP_API_URL}/Oauths/qrimage`,
          })
            .then((response) => {
              setImageUrl(response.data.data);
            })
            .catch((error) => {
              console.error('Error fetching image:', error);
            });
        }
      })
      .catch((error) => {
        // console.error('Error fetching twoFactor:', error);
        axios({
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
          method: 'GET',
          url: `${process.env.REACT_APP_API_URL}/Oauths/qrimage`,
        })
          .then((response) => {
            setImageUrl(response.data.data);
          })
          .catch((error) => {
            console.error('Error fetching image:', error);
          });
      });
  }, []);

  return <img id="qrImage" src={imageUrl} height="150" width="150" alt="QR Code" />;
}

const TwoFactor = (props) => {
  const history = useHistory();
  const { state } = props.location;
  const [twoFactor, setTwoFactor] = useState();
  const [isLoading, setLoading] = useState();
  const [secretCode, setSecretCode] = useState('');
  const [alert, setAlert] = useState(false);
  const [error, setError] = useState(false);

  const handleCloseAlert = () => {
    setAlert(false);
  };
  const handleCloseError = () => {
    setError(false);
  };

  useEffect(() => {
    setLoading(true);
    axios({
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/Oauths/check`,
    })
      .then((response) => {
        setTwoFactor(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching twoFactor:', error);
        setLoading(false);
      });
  }, [twoFactor]);

  const insertTwoFactor = () => {
    setLoading(true);
    axios({
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      method: 'POST',
      url: `${process.env.REACT_APP_API_URL}/Oauths/twofactor`,
      data: {
        secretKey: secretCode,
      },
    })
      .then((response) => {
        // console.log(response);
        setLoading(false);
        setAlert(true);
        setSecretCode('');
        setTwoFactor(true);
        setError(false);
      })
      .catch((error) => {
        // console.error('Error fetching twoFactor:', error);
        setLoading(false);
        setAlert(false);
        setError(true);
        setSecretCode('');
      });
  };

  return isLoading === true ? (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: 'breadcrumb-dark breadcrumb-transparent' }}
          >
            <Breadcrumb.Item onClick={() => redirectUrl(Routing.Dashboard.path)}>
              <HomeIcon className="icon icon-xs" />
            </Breadcrumb.Item>
            <Breadcrumb.Item onClick={() => redirectUrl(Routing.Users.path)}>Users</Breadcrumb.Item>
            <Breadcrumb.Item active>Account Management</Breadcrumb.Item>
          </Breadcrumb>
          <h4>2FA Setting</h4>
        </div>
      </div>
      <Card border="0" className="shadow p-3 pb-4 mb-4">
        <Card.Body className="p-0 p-md-4">
          <div className="d-flex justify-content-center align-items-center gap-3">
            <Loading />
          </div>
        </Card.Body>
      </Card>
    </>
  ) : (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: 'breadcrumb-dark breadcrumb-transparent' }}
          >
            <Breadcrumb.Item onClick={() => redirectUrl(Routing.Dashboard.path)}>
              <HomeIcon className="icon icon-xs" />
            </Breadcrumb.Item>
            <Breadcrumb.Item onClick={() => redirectUrl(Routing.Users.path)}>Users</Breadcrumb.Item>
            <Breadcrumb.Item active>Account Management</Breadcrumb.Item>
          </Breadcrumb>
          <h4>2FA Setting</h4>
        </div>
      </div>
      {alert && (
        <Alert variant="success" onClose={handleCloseAlert} dismissible>
          2FA setup successful
        </Alert>
      )}
      {error && (
        <Alert variant="danger" onClose={handleCloseError} dismissible>
          Failed to set up 2FA. Please remove from Google Authenticator & rescan the QR code.
        </Alert>
      )}
      <Card border="0" className="shadow p-3 pb-4 mb-4">
        <Card.Body className="p-0 p-md-4">
          {twoFactor === false ? (
            <Form className="mt-4">
              <div className="d-flex justify-content-center align-items-center gap-3">
                <div id="twoFAFormHolder" className="d-flex flex-column align-items-center gap-3">
                  <ImageComponent />
                  <form id="twoFAUpdateForm" className="d-flex flex-column gap-2">
                    <input
                      type="text"
                      name="code"
                      value={secretCode}
                      onChange={(event) => {
                        setSecretCode(event.target.value);
                      }}
                      placeholder="2FA Code"
                      className="form-control"
                    />
                    <button onClick={insertTwoFactor} className="btn btn-primary" type="button">
                      SET
                    </button>
                  </form>
                </div>
              </div>
            </Form>
          ) : (
            <div className="d-flex justify-content-center align-items-center gap-3">
              <h1>2FA Already Enabled</h1>
            </div>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    auth: bindActionCreators(authAction, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(TwoFactor);
