import React, { useState, useEffect, useRef } from 'react';
import './daytimePreference.styles.css';
import { Select as MUISelect, MenuItem, FormControl, InputLabel } from '@material-ui/core';
import AvailablePeriod from '../availablePeriod/availablePeriod.component';
import AvailableDaytime, { avaTime } from '../availableDaytime/availableDaytime.component';
import { styled } from '@mui/material/styles';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
// import { SingleInputTimeRangeField } from '@mui/x-date-pickers-pro/SingleInputTimeRangeField';

import { TimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Box, Button, Chip, Typography, Divider, TextField } from '@mui/material';
import { Checkbox, ListItemIcon, ListItemText } from '@material-ui/core';
import {
  MenuProps,
  useStyles,
  optionsDay,
  optionsPeriod,
  optionsTime,
  optionsWeekend,
  optionsWeekday,
} from './daytimePreference.utils';
import { set } from 'lodash';

const customParseFormat = require('dayjs/plugin/customParseFormat');
dayjs.extend(customParseFormat);

dayjs.extend(utc);
dayjs.extend(timezone);
// let tzLocal = dayjs.tz.guess();
dayjs.tz.setDefault('Asia/Singapore');

let daytimePref;

const DaytimePreference = ({ daytimeFilled, setDaytimeFilled, dayTimeSubmitRef }) => {
  const theStyle = {
    border: '1px solid red',
  };
  const TimeInputStart = styled(TextField)({
    width: '200px', // Adjust the width as per your requirement
  });

  const classes = useStyles();
  const [period, setPeriod] = useState('');
  const [periodNull, setPeriodNull] = useState(false);
  const todayDateRef = useRef(null);

  const [day, setDay] = useState([]);

  useEffect(() => {
    const noDay = document.querySelector('div.css-yi3mkw > :last-child');
    const todayDateElement = todayDateRef.current;
    if (noDay?.textContent === '') {
      noDay.style.display = 'none';
    }

    console.log(todayDateElement);
    if (todayDateElement && todayDateElement.textContent === 'today') {
      console.log('hallo test');
      document.querySelector('h4').style.display = 'none';
      if (day.length === 0) {
        // Update day state and hide h4 element
        const today = new Date().toLocaleDateString('en-US', { weekday: 'long' });
        setDay([today]);
        // document.querySelector('h4').style.display = 'none';
      }
    }
  }, [period, day]);

  // useEffect(() => {
  //   let todayDate = document.querySelector('div.makeStyles-formControlSingle-2 div');
  //   console.log(todayDate);
  //   if (todayDate !== null) {
  //     if (todayDate.textContent == 'today') {
  //       console.log('today test');
  //       let todayDate = new Date().toLocaleDateString('en-US', { weekday: 'long' });
  //       let arrToday = [];
  //       arrToday.push(todayDate);
  //       setDay(arrToday);
  //       document.querySelector('h4').style.display = 'none';
  //     }
  //   }
  // }, [period]);

  const isAllDaySelected = optionsDay.length > 0 && day.length === optionsDay.length;
  const isAllWeekendSelected =
    optionsWeekend.length > 0 &&
    day.length === optionsWeekend.length &&
    JSON.stringify(day.sort()) === JSON.stringify(['Saturday', 'Sunday'].sort());
  const isAllWeekdaySelected =
    optionsWeekday.length > 0 &&
    day.length === optionsWeekday.length &&
    JSON.stringify(day.sort()) === JSON.stringify(['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'].sort());

  const handleChangeDay = (event) => {
    const value = event.target.value;

    if (value[value.length - 1] === 'all') {
      setDay(day.length === optionsDay.length ? [] : optionsDay);
      return;
    }
    if (value[value.length - 1] === 'weekend') {
      setDay(day.length === optionsWeekend.length ? [] : optionsWeekend);
      return;
    }
    if (value[value.length - 1] === 'weekday') {
      setDay(day.length === optionsWeekday.length ? [] : ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', '']);
      return;
    }
    setDay(value);
  };

  const [timeValue, setTimeValue] = useState('');
  const [checked, setChecked] = useState(false);
  const changeHandler = (e) => {
    // alert(e.target.value);
    e.target.value == `${valuex}-${valuez}` ? setChecked(true) : setChecked(false);
    setTimeValue(e.target.value); //[2023-02-07] No difference in console.log before vs after update value
  };

  const [timeManualValueStart, setTimeManualValueStart] = useState(dayjs().add(1, 'hour').set('minute', 0));
  const [timeManualValueEnd, setTimeManualValueEnd] = useState(dayjs().add(1, 'hour').set('minute', 30));
  const [valuex, onChangex] = useState('09:00');
  const [valuez, onChangez] = useState('21:00');
  const [timelabels, setTimeLabels] = useState('Others');

  const openTime = dayjs().set('hour', 8).set('minute', 29);
  const closingTime = dayjs().set('hour', 18).set('minute', 1);

  const handleSubmitDaytime = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    daytimePref = {
      periodPreference: data.get('select-period'),
      daysPreference: data.get('select-days'),
      // timePreference: data.get('select-time'),
      timePreference: timeValue,
      dmyPreference: avaTime,
    };
  };

  const validate = () => {
    let event =
      (period.length > 0) &
      (day.length > 0) &
      timeManualValueStart.isBefore(timeManualValueEnd) &
      ((timeValue.length > 0) &
        ((typeof timeManualValueStart !== 'undefined') & (typeof timeManualValueEnd !== 'undefined')));
    // setDaytimeFilled(event);
    return event;
  };

  useEffect(() => {
    setDaytimeFilled(validate);
  }, [validate]);

  return (
    <div>
      {/* <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" /> */}
      <Box component="form" noValidate onSubmit={handleSubmitDaytime}>
        {/* <Typography variant="h5" component="h1" sx={{ mb: "2rem", fontWeight: "bold" }}>
          Day/Time Preference
        </Typography> */}
        <FormControl
          style={{ width: '350px' }}
          error={periodNull ? true : false}
          required
          className={classes.formControlSingle}
        >
          <InputLabel>Period</InputLabel>
          <MUISelect
            ref={todayDateRef}
            name="select-period"
            id="select-period"
            label="Period Preference"
            defaultValue={period}
            helpertext="Please select your Period Preference"
            onChange={(e) => {
              setPeriod(e.target.value);
            }}
          >
            {optionsPeriod.map((option) => (
              <MenuItem key={option.periodValue} value={option.periodValue}>
                {option.periodLabel}
              </MenuItem>
            ))}
          </MUISelect>
        </FormControl>
        {/* <p>Selected Period: {period}</p> */}
        <AvailablePeriod target={period} />
        <br />

        <FormControl style={{ width: '350px' }} required className={classes.formControlSingle}>
          <InputLabel id="select-days">Target Days</InputLabel>
          <MUISelect
            name="select-days"
            labelId="select-days"
            multiple
            value={day}
            onChange={handleChangeDay}
            renderValue={(day) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {day.map((value) => (
                  <Chip key={value} label={value} />
                ))}
              </Box>
            )}
            MenuProps={MenuProps}
          >
            <MenuItem value="all" classes={{ root: isAllDaySelected ? classes.selectedAll : '' }}>
              <ListItemIcon>
                <Checkbox
                  classes={{ indeterminate: classes.indeterminateColor }}
                  checked={isAllDaySelected}
                  indeterminate={day.length > 0 && day.length < optionsDay.length}
                />
              </ListItemIcon>
              <ListItemText classes={{ primary: classes.selectAllText }} primary="Select All" />
            </MenuItem>
            <MenuItem value="weekday" classes={{ root: isAllWeekdaySelected ? classes.selectedAll : '' }}>
              <ListItemIcon>
                <Checkbox
                  classes={{ indeterminate: classes.indeterminateColor }}
                  checked={isAllWeekdaySelected}
                  indeterminate={day.length > 0 && day.length < optionsWeekday.length}
                />
              </ListItemIcon>
              <ListItemText classes={{ primary: classes.selectAllText }} primary="Weekdays" />
            </MenuItem>
            <MenuItem value="weekend" classes={{ root: isAllWeekendSelected ? classes.selectedAll : '' }}>
              <ListItemIcon>
                <Checkbox
                  classes={{ indeterminate: classes.indeterminateColor }}
                  checked={isAllWeekendSelected}
                  indeterminate={day.length > 0 && day.length < optionsWeekend.length}
                />
              </ListItemIcon>
              <ListItemText classes={{ primary: classes.selectAllText }} primary="Weekends" />
            </MenuItem>
            <Divider />
            {optionsDay.map((option) => (
              <MenuItem key={option} value={option}>
                <ListItemIcon>
                  <Checkbox checked={day.indexOf(option) > -1} />
                </ListItemIcon>
                <ListItemText primary={option} />
              </MenuItem>
            ))}
          </MUISelect>
        </FormControl>
        <br />
        {/* {day && (
          <div style={{ marginTop: 20, lineHeight: "25px" }}>
            <div>Selected Days: {JSON.stringify(day, null, 2)}</div>
          </div>
        )} */}
        {!checked && <AvailableDaytime target={day} timing={timeValue.toString()} />}
        {checked && (
          <AvailableDaytime
            target={day}
            // timing={
            //   (timeManualValueStart ? dayjs(Number(timeManualValueStart)).tz().format('HH:mm') : '') +
            //   ' - ' +
            //   (timeManualValueEnd ? dayjs(Number(timeManualValueEnd)).tz().format('HH:mm') : '')
            // }
          />
        )}

        <FormControl style={{ width: '350px' }} required className={classes.formControlSingle}>
          <InputLabel>Time</InputLabel>
          <MUISelect
            name="select-time"
            id="select-time"
            label="Time Preference"
            defaultValue=""
            helpertext="Please select your Time Preference"
            onChange={changeHandler}
          >
            {optionsTime.map((time) => (
              <MenuItem
                key={time.timeValue.toString()}
                value={time.timeValue ? time.timeValue.toString() : valuex + '-' + valuez}
              >
                {time.timeLabel}
              </MenuItem>
            ))}
          </MUISelect>
        </FormControl>
        <br></br>
        <br></br>
        {checked && (
          <div>
            <FormControl required className={classes.formControlTime}>
              <TextField
                type="time"
                label="Start"
                value={valuex}
                onChange={(e) => {
                  onChangex(e.target.value);
                  setTimeValue(e.target.value + '-' + valuez);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  onAccept={(t) => {
                    setTimeValue(
                      dayjs(Number(timeManualValueStart)).tz().format('HH:mm') +
                        '-' +
                        dayjs(Number(timeManualValueEnd)).tz().format('HH:mm')
                    );
                  }}
                  // defaultValue="00:00"
                  ampmInClock
                  minutesStep={30}
                  // minTime={openTime}
                  // maxTime={closingTime?.subtract(30, 'minute')}
                  openTo="hours"
                  views={['hours', 'minutes']}
                  inputFormat="HH:mm"
                  mask="__:__"
                  label="Start"
                  value={timeManualValueStart}
                  onChange={(t) => {
                    setTimeManualValueStart(t);
                  }}
                  renderInput={(params) => <TextField {...params} helpertext={''} placeholder={''} />}
                />
              </LocalizationProvider> */}
            </FormControl>
            <FormControl style={{ width: '350px' }} required className={classes.formControlTime}>
              <TextField
                type="time"
                label="End"
                value={valuez}
                onChange={(e) => {
                  onChangez(e.target.value);
                  setTimeValue(valuex + '-' + e.target.value);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  onAccept={(t) => {
                    setTimeValue(
                      dayjs(Number(timeManualValueStart)).tz().format('HH:mm') +
                        '-' +
                        dayjs(Number(timeManualValueEnd)).tz().format('HH:mm')
                    );
                  }}
                  defaultValue={dayjs().hour(0).minute(0).toDate()}
                  // defaultValue="00:00"
                  ampmInClock
                  minutesStep={30}
                  minTime={timeManualValueStart?.add(30, 'minute')}
                  maxTime={closingTime}
                  openTo="hours"
                  views={['hours', 'minutes']}
                  inputFormat="HH:mm"
                  mask="__:__"
                  label="End"
                  value={timeManualValueEnd}
                  onChange={(t) => {
                    setTimeManualValueEnd(t);
                  }}
                  renderInput={(params) => <TextField {...params} helpertext={''} placeholder={''} />}
                />
              </LocalizationProvider> */}
            </FormControl>
          </div>
        )}
        {/* {!checked && <p>Selected Time: {timeValue}</p>} */}
        {/* {checked && (
          <p>
            Selected Time: {timeManualValueStart ? dayjs(Number(timeManualValueStart)).tz().format('HH:mm') : ''} -
            {timeManualValueEnd ? dayjs(Number(timeManualValueEnd)).tz().format('HH:mm') : ''}
          </p>
        )} */}
        <Button
          disabled={!validate()}
          type="submit"
          variant="outlined"
          ref={dayTimeSubmitRef}
          style={{ display: 'none' }}
        >
          {' '}
          Confirm{' '}
        </Button>
      </Box>
      {/* <Select
        className="dropdown"
        placeholder="Select Option"
        value={days.filter(obj => day.includes(obj.value))} // set selected values
        options={days} // set list of days
        onChange= {(e) => setDay(Array.isArray(e) ? e.map(x => x.value) : [])} // assign onChange function
        isMulti
        isClearable
      />
      {day && 
      <div style={{ marginTop: 20, lineHeight: '25px' }}>
        <div><b>Selected Value: </b> {JSON.stringify(day, null, 2)}</div>
      </div>}
      <AvailableDaytime target={day} timing={time}/>
      <div>
        <input onChange={(e) => setTime(e.target.value)} value={time}/>
      </div> */}
    </div>
  );
};

export default DaytimePreference;
export { daytimePref };
