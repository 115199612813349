/**
 * Bar chart data and render config
 * - Additional control: export btn (will show SegmentPatientExportModal on click)
 */

import { useEffect, useState } from "react";
import { Routing } from "routes";

import segmentOrderConfig from "./PatientSegmentationOrderConfig";
import { formatAmount } from "utils/parser";

import { Breadcrumb, Card, Row, Col, Button } from 'react-bootstrap';
import Chart from 'react-apexcharts';
import { IoMdInformationCircleOutline } from "react-icons/io";

import "./style.css"

import ExportModal from './SegmentPatientExportModal';
import PatientSegmentInfoModal from "./PatientSegmentInfoModal";



const SegmentCategoryHorizontalBarChart = (props) => {
    // 
    // STATES
    // 

    // info modal toggle
    const [ infoToggle, setInfoToggle ] = useState(false);

    // export modal toggle
    const [ exportToggle, setExportToggle ] = useState(false);
    // props child -> to pass to the modal component so they can access exportToggle along with the other props
    const childProps = Object.assign({}, props, {
        exportToggle
    });

    // chart options
    const [ chartOptions, setChartOptions ] = useState({
        chart: {
            type: 'bar',
            toolbar: {
                show: false
            },
            zoom: {
                enabled: true
            },
            animations: {
                enabled: false
            }    
        },
        dataLabels: {
            enabled: true,
            offsetX: 36,
            style: {
                fontSize: '14px'
            }
        },
        responsive: [{}],
        plotOptions: {
            bar: {
                horizontal: true,
                dataLabels: {
                    position: 'top'
                },
                distributed: true
            }
        },
        xaxis: {},
        yaxis: {},
        legend: {
            show: !props.isEmbedded,
            position: 'bottom',
            horizontalAlign: 'center',
            offsetY: 10
        },
        tooltip: {},
        fill: {
            opacity: 1
        },
        colors: []
    });

    // chart series
    const [ chartSeries, setChartSeries ] = useState([
        {
            data: []
        }
    ]);

    // 
    // HANDLE BUTTON CLICK
    // 

    const handleExportClick = () => {
        setExportToggle(!exportToggle);
    };

    // 
    // USE EFFECTS
    // 

    // on first render
    useEffect(() => {
        if(!props.isEmbedded) {
            window.history.replaceState(
                null,
                "Medtrik",
                Routing.SegmentCategoryHorizontalBarChart.path
            );
            window.scrollTo(0, 0);
        }
    }, []);

    // on API data update
    useEffect(() => {
        if (props.segmentCategoryHorizontalBarChart && (Array.isArray(props.segmentCategoryHorizontalBarChart) && props.segmentCategoryHorizontalBarChart.length>0)) {
            // get series (array of patient counts) and the max value (to be used for tickAmount in x-axis)
            const series = props.segmentCategoryHorizontalBarChart.map(cat => cat.segmentCount);
            console.log("ERROR series: ", series);
            const seriesMax = Math.max(...series);

            // get categories (segments' names; should be in the same order as series)
            const categories = props.segmentCategoryHorizontalBarChart.map(cat => cat.segmentCategory.split(" "));
            console.log("ERROR categories: ", categories);

            // get colors array according to the categories' names
            const colors = props.segmentCategoryHorizontalBarChart.map(cat => segmentOrderConfig[cat.segmentCategory].color);

            // set chart config and series
            setChartOptions({
                ...chartOptions,
                xaxis: {
                    tickAmount: seriesMax>20 ? 20 : seriesMax,
                    title: {
                        text: "Patient Count"
                    },

                    categories: categories
                },
                yaxis: {
                    title: {
                        text: "Segments",
                        offsetX: 10
                    },
                    labels: {
                        style: {
                            fontSize: '14px'
                        }
                    }
                },
                colors: colors,
                tooltip: {
                    custom: ({series, seriesIndex, dataPointIndex, w}) => {
                        return (
                            `
                                <div>
                                    <div class="highlight">
                                        <div class="circle" style="background-color:${segmentOrderConfig[categories[dataPointIndex].join(" ")].color}"></div>
                                        <p class="adjusted-fontsize-tooltip series-text"> <b> ${categories[dataPointIndex].join(" ")} </b> </p>
                                    </div>
                                    <div>
                                        <p class="adjusted-fontsize-tooltip"> Patient Count: <b> ${series[seriesIndex][dataPointIndex]} people </b> </p>
                                    </div>
                                </div>
                            `
                        )
                    }
                }
            });
            setChartSeries([{...chartSeries[0], data: series}]);
        }
    }, [props.segmentCategoryHorizontalBarChart]);



    // 
    // RENDER UI
    // 

    return (
        <Row className={!props.isEmbedded ? 'pb-5' : 'pb-4'}>
            {/* EXPORT CONTROL */}
            <div className="export">
                {/* BUTTON -> ON CLICK THEN SET MODAL TO BE VISIBLE */}
                <div className="button-color" style={{ position: 'relative', zIndex: 10, right: '10px', top: '-16px' }} onClick={(e) => e.stopPropagation()}>
                    <Button variant="primary" className="animate-up-2 btn btn-gray-800 btn-lg" type="button" onClick={handleExportClick} >
                        Export
                    </Button>
                </div>
                {/* EXPORT MODAL -> VISIBLE IF exportToggle=true */}
                <ExportModal {...childProps} handleCancelClick={handleExportClick} exportToggle={exportToggle}/>
            </div>
            {/* GRAPH */}
            <Col xs={12} lg={12}>
                <Chart options={chartOptions} series={chartSeries} type="bar" height={props.isEmbedded ? 500 : 800} />
            </Col>
            {/* INFO MODAL */}
            <div className="info-wrapper" style={{ marginTop: props.isEmbedded ? "-40px" : "0px" }}>
                <div>
                    <IoMdInformationCircleOutline className="info-icon" fontSize={"30px"} onMouseOver={() => setInfoToggle(true)} onMouseOut={() => setInfoToggle(false)}/>
                </div>
                <PatientSegmentInfoModal {...childProps} infoToggle={infoToggle}/>
            </div>
        </Row>
    )
}

export default SegmentCategoryHorizontalBarChart;