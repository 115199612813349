import React, { useContext, useState } from 'react';
import { useEffect } from 'react';
import moment from 'moment-timezone';
import {
  BellIcon,
  CogIcon,
  InboxIcon,
  MenuAlt1Icon,
  SearchIcon,
  SupportIcon,
  UserCircleIcon,
} from '@heroicons/react/solid';
import { LogoutIcon } from '@heroicons/react/outline';
import {
  Row,
  Col,
  Nav,
  Form,
  Image,
  Button,
  Navbar,
  Dropdown,
  Container,
  ListGroup,
  InputGroup,
} from 'react-bootstrap';
import * as authAction from '../../actions/authActions';
import { bindActionCreators } from 'redux';
import { connect, useSelector } from 'react-redux';
import { Routing } from 'routes';

import { userNotifications } from 'data/notifications';
import Profile3 from '../../assets/img/team/profile-picture-3.jpg';
import decode from 'jwt-decode';
import { useHistory } from 'react-router-dom';
import PDFPreview from './PDFPreview';
import axios from 'axios';
import ENVIRONMENT_VARIABLES from 'config';
import AvatarContext from 'actions/avatarContext';

const AfterLogin = (props) => {
  const history = useHistory();
  const { avatarUrl, setAvatarUrl } = useContext(AvatarContext);
  const [notifications, setNotifications] = useState(userNotifications);
  const allNotificationsRead = notifications.reduce((acc, notif) => acc && notif.read, true);
  const bellIconClasses = !allNotificationsRead ? 'unread' : '';

  const pdfUrl = ENVIRONMENT_VARIABLES.USER_MANUAL;

  const openMenu = (link, title) => {
    window.open(link, '_blank');
  };

  const handleOpenPdfInNewTab = () => {
    window.open(pdfUrl, '_blank');
  };

  const redirectUrl = (url) => {
    history.push(url);
  };

  const logout = () => {
    props.actions.auth.logout();
  };

  const adminAuthReducer = useSelector((state) => ({
    adminUser: state.adminAuthReducer.adminUser,
    loginUserID: state.adminAuthReducer.loginUserID,
  }));

  const markNotificationsAsRead = () => {
    setTimeout(() => {
      setNotifications(notifications.map((n) => ({ ...n, read: true })));
    }, 400);
  };

  // #334: MAKE SURE AVATAR LOADS AFTER LOGIN
  useEffect(() => {
    const updateDetail = {
      userId: adminAuthReducer.adminUser._id,
    };
    axios({
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      method: 'POST',
      url: `${process.env.REACT_APP_API_URL}/Oauths/get-user-by-id`,
      data: updateDetail,
    })
      .then((response) => {
        setAvatarUrl(`${process.env.REACT_APP_FILE_URL}/${response.data.data.avatar}`); // Set avatarUrl state
      })
      .catch((error) => {
        console.error('Error fetching user:', error);
        // setLoading(false);
      });
  }, []);

  const accessToken = localStorage.getItem('accessToken');
  let userRoleString = '';
  if (accessToken !== 'undefined' && accessToken !== null) {
    const userProfile = decode(accessToken);
    // console.log('USER PROFILE', userProfile);
    const user = userProfile.user;
    if (user.chain) {
      userRoleString = ` - Chain: ${user.chain.title}`;
    } else if (user.doctor) {
      userRoleString = ` - Doctor: ${user.doctor.name} | ${user.doctor.doctorId}`;
    } else if (user.clinic) {
      userRoleString = ` - Clinic: ${user.clinic.clinicId}`;
    }
  }

  const toggleContracted = () => props.toggleContracted && props.toggleContracted();

  const Notification = (props) => {
    const { link, sender, image, time, message, read = false } = props;
    const readClassName = read ? '' : 'text-danger';
    const receiveTime = moment(time).fromNow();

    return (
      <ListGroup.Item action href={link} className="list-group-item-action border-bottom">
        <Row className="align-items-center">
          <Col xs="auto">
            <Image src={image} className="avatar-md rounded" />
          </Col>
          <Col className="ps-0 ms-2">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h4 className="h6 mb-0 text-small">{sender}</h4>
              </div>
              <div className="text-end">
                <small className={readClassName}>{receiveTime}</small>
              </div>
            </div>
            <p className="font-small mt-1 mb-0">{message}</p>
          </Col>
        </Row>
      </ListGroup.Item>
    );
  };

  return (
    <Navbar expand variant="dark" className="navbar-top navbar-dashboard ps-0 pe-2 pb-0">
      <Container fluid className="px-0">
        <div className="d-flex justify-content-between w-100">
          <div className="d-flex align-items-center">
            <Button
              size="lg"
              id="sidebar-toggle"
              variant="icon-only"
              className="sidebar-toggle d-none d-lg-inline-block align-items-center justify-content-center me-3"
              onClick={toggleContracted}
            >
              <MenuAlt1Icon className="toggle-icon" />
            </Button>
          </div>
          <Nav className="align-items-center">
            <Dropdown as={Nav.Item} className="ms-lg-3">
              <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
                <div className="media d-flex align-items-center">
                  {/* #334: make sure avatar in on the to[] right side after login is rounded regardless of the image's actual image ratio */}
                  <Image src={avatarUrl} className="avatar rounded-circle" style={{ objectFit:'cover' }}/>
                  <div className="media-body ms-2 text-dark align-items-center d-none d-lg-block">
                    <span className="mb-0 font-small fw-bold text-gray-900">
                      {adminAuthReducer.loginUserID && adminAuthReducer.adminUser.fullName}
                    </span>
                    <span>{userRoleString}</span>
                  </div>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dashboard-dropdown dropdown-menu-end mt-2 py-1">
                {/* <Dropdown.Item className="d-flex align-items-center">
                  <UserCircleIcon className="dropdown-icon text-gray-400 me-2" /> My Profile
                </Dropdown.Item> */}
                {/* #334: open account page in the same tab */}
                <Dropdown.Item
                  onClick={() => {
                    redirectUrl(Routing.Account.path);
                  }}
                  className="d-flex align-items-center"
                >
                  <CogIcon className="dropdown-icon text-gray-400 me-2" /> Account
                </Dropdown.Item>
                <Dropdown.Item className="d-flex align-items-center">
                  <InboxIcon className="dropdown-icon text-gray-400 me-2" /> Notification
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    openMenu(Routing.userManual.path);
                  }}
                  className="d-flex align-items-center"
                >
                  <SupportIcon className="dropdown-icon text-gray-400 me-2" /> User Manual
                </Dropdown.Item>
                {/* <Dropdown.Item
                  onClick={() => redirectUrl(Routing.TwoFactor.path)}
                  className="d-flex align-items-center"
                >
                  <CogIcon className="dropdown-icon text-gray-400 me-2" /> 2FA
                </Dropdown.Item> */}

                <Dropdown.Divider as="div" className="my-1" />

                <Dropdown.Item className="d-flex align-items-center test" onClick={logout}>
                  <LogoutIcon className="dropdown-icon text-danger me-2" /> Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </div>
      </Container>
    </Navbar>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    auth: bindActionCreators(authAction, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(AfterLogin);
