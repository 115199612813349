import { Routing } from 'routes';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as authAction from 'actions/authActions';
import { Breadcrumb, Button, Card, Col, Container, Form, Image, Row } from 'react-bootstrap';
import { HomeIcon } from '@heroicons/react/solid';
import { useContext, useRef, useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { Toast } from 'react-bootstrap';
import AvatarContext from 'actions/avatarContext';

const EditProfile = (props) => {
  const [submit, setSubmit] = useState(false);
  const [showToast, setShowToast] = useState(false); // New state for controlling the toast

  const adminAuthReducer = useSelector((state) => ({
    adminUser: state.adminAuthReducer.adminUser,
  }));
  useEffect(() => {
    const updateDetail = {
      userId: id,
    };
    axios({
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      method: 'POST',
      url: `${process.env.REACT_APP_API_URL}/Oauths/get-user-by-id`,
      data: updateDetail,
    })
      .then((response) => {
        setUser(response.data.data);
        setFullName(response.data.data.fullName); // Set fullName state
        setEmailAddress(response.data.data.email); // Set emailAddress state
        setDisplayName(response.data.data.userName); // Set displayName state

        setAvatarUrl(`${process.env.REACT_APP_FILE_URL}/${response.data.data.avatar}`); // Set avatarUrl state

        const fullNameSplit = response.data.data.fullName.split(' ');
        const firstNameSplit = fullNameSplit[0];
        const lastNameSplit = fullNameSplit.slice(1).join(' ');
        setFirstName(firstNameSplit);
        setLastName(lastNameSplit);
        // setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching user:', error);
        // setLoading(false);
      });
  }, [submit]);
  useEffect(() => {
    setSubmit(!submit);
  }, [firstName, lastName]);
  const { adminUser } = adminAuthReducer;
  const [user, setUser] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [id, setId] = useState(adminUser._id);
  const [userRole, setUserRole] = useState(adminUser.Role.id);
  const [fullName, setFullName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [seeProfile, setSeeProfile] = useState(true);
  const redirectUrl = (url) => {
    history.push(url);
  };

  // Handle For Edit Profile
  const handleEditProfile = () => {
    setSeeProfile((prevSeeProfile) => !prevSeeProfile);
  };

  const handleDisplayNameChange = (event) => {
    setFullName(event.target.value);
  };

  const handleDisplayEmailChange = (event) => {
    setEmailAddress(event.target.value);
  };

  const handleDisplayUserNameChange = (event) => {
    setDisplayName(event.target.value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    let updateDetail = {};
    updateDetail = {
      userId: id,
      userRole: userRole,
      userFullName: fullName,
      userName: displayName,
      userEmail: emailAddress,
      userStatus: true,
    };
    axios({
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      method: 'PUT',
      url: `${process.env.REACT_APP_API_URL}/Oauths/update-user`,
      data: updateDetail,
    })
      .then((response) => {
        // setTwoFactor(response.data.data);
        // setLoading(false);
        setSubmit(!submit);
        setShowToast(true);
      })
      .catch((error) => {
        console.error('Error Update user:', error);
        // setLoading(false);
      });
    // console.log(test);
  };

  // Handle For Avatar
  // const [avatarUrl, setAvatarUrl] = useState('');

  const { avatarUrl, setAvatarUrl } = useContext(AvatarContext);
  const inputRef = useRef();

  const handleChangeAvatar = () => {
    inputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('avatar', file);
      formData.append('userId', adminUser._id);
      axios({
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/Oauths/update-avatar`,
        data: formData,
      })
        .then((response) => {
          setAvatarUrl(`${process.env.REACT_APP_FILE_URL}/${response.data.data}`);
        })
        .catch((error) => {
          console.error('Error updating avatar:', error);
        });
    }
  };
  return (
    <>
      <Toast
        style={{
          position: 'fixed',
          top: 90,
          right: 20,
          zIndex: 9999,
        }}
        onClose={() => setShowToast(false)}
        show={showToast}
        delay={3000}
        autohide
      >
        <Toast.Header closeButton={false} style={{ backgroundColor: '#5cb85c', color: 'white' }}>
          <strong className="mr-auto">Success</strong>
        </Toast.Header>
        <Toast.Body style={{ backgroundColor: '#5cb85c', color: 'white' }}>Data successfully updated!</Toast.Body>
      </Toast>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: 'breadcrumb-dark breadcrumb-transparent' }}
          >
            <Breadcrumb.Item onClick={() => redirectUrl(Routing.Dashboard.path)}>
              <HomeIcon className="icon icon-xs" />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Account</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Edit Account</h4>
        </div>
      </div>

      <Container
        style={{
          backgroundColor: '#fff',
          padding: '20px',
          borderRadius: '8px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        }}
        className="modal-edit-profile"
      >
        <Row>
          <Col>
            <header className="d-flex justify-content-between">
              <h2 className="font-weight-bold">{fullName}</h2>
              <h3
                onClick={handleEditProfile}
                style={{ cursor: 'pointer', userSelect: 'none' }}
                className="font-weight-bold"
              >
                Edit
              </h3>
            </header>
          </Col>
        </Row>
        <Row>
          <Row>
            <Col xs={1} className="d-flex align-items-center">
              {/* #334: make sure avatar in account page is rounded regardless of the image's actual image ratio */}
              <Image src={avatarUrl} alt="Avatar" className="avatar-lg rounded-circle" style={{ objectFit:'cover' }}/>
            </Col>
            <Col xs={2} className="d-flex align-items-center justify-content-center">
              <div>
                <Button variant="secondary" className="btn-sm" onClick={handleChangeAvatar}>
                  Change Avatar
                </Button>
                <input ref={inputRef} type="file" style={{ display: 'none' }} onChange={handleFileChange} />
              </div>
            </Col>
          </Row>

          <Row>
            <Col sm={4} className="mb-3">
              <Form.Group id="displayName">
                <Form.Label>Display Name</Form.Label>
                <Form.Control
                  onChange={handleDisplayNameChange}
                  disabled={seeProfile}
                  value={fullName}
                  required
                  type="text"
                />
              </Form.Group>
            </Col>
            <Col sm={4} className="mb-3">
              <Form.Group id="firstName">
                <Form.Label>First Name</Form.Label>
                <Form.Control disabled={true} value={firstName} required type="text" />
              </Form.Group>
            </Col>
            <Col sm={4}>
              <Form.Group id="lastName">
                <Form.Label>Last Name</Form.Label>
                <Form.Control disabled={true} required value={lastName} type="text" />
              </Form.Group>
            </Col>
          </Row>

          {/* #334: DISABLE BUTTONS BY KEN'S REQUEST */}
          <Row>
            <Col sm={6} className="mb-3">
              <Form.Group id="userName">
                <Form.Label>Username</Form.Label>
                <Form.Control
                  onChange={handleDisplayUserNameChange}
                  disabled={true}
                  value={displayName}
                  required
                  type="text"
                />
              </Form.Group>
            </Col>
            {/* <Col sm={6} className="mb-3">
              <Form.Group id="userName">
                <Form.Label>Username</Form.Label>
                <Form.Control
                  onChange={handleDisplayUserNameChange}
                  disabled={seeProfile}
                  value={displayName}
                  required
                  type="text"
                />
              </Form.Group>
            </Col>             */}
            <Col sm={6}>
              <Form.Group id="emailAddress">
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  disabled={true}
                  onChange={handleDisplayEmailChange}
                  required
                  value={emailAddress}
                  type="text"
                />
              </Form.Group>
            </Col>
            {/* <Col sm={6}>
              <Form.Group id="emailAddress">
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  disabled={seeProfile}
                  onChange={handleDisplayEmailChange}
                  required
                  value={emailAddress}
                  type="text"
                />
              </Form.Group>
            </Col> */}
          </Row>

          {/* 2FA by company for now */}
          {/* <Row>
            <Col>
              <h3 className="font-weight-bold">Two-Factor Authentication:</h3>
              <Form.Group controlId="formEnable2FA">
                <Form.Check
                  type="switch"
                  disabled={seeProfile}
                  checked={twoFactor}
                  label="Enable 2FA"
                  className="text-dark"
                />
              </Form.Group>
              <Form.Group controlId="formReset2FA">
                <Form.Check type="switch" disabled label="Reset 2FA" className="text-dark" />
              </Form.Group>
            </Col>
          </Row> */}

          <Row className="justify-content-end">
            <Col xs={1}>
              {/* Footer Section */}
              <Button
                variant="secondary"
                disabled={seeProfile}
                type="button"
                className="mb-2"
                onClick={() => window.location.reload()}
              >
                Cancel
              </Button>
            </Col>
            <Col xs={1}>
              <Button disabled={seeProfile} onClick={handleSubmit} variant="primary" type="submit" className="mb-2">
                Save
              </Button>
            </Col>
          </Row>
        </Row>
      </Container>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    auth: bindActionCreators(authAction, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(EditProfile);
