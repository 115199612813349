import { useEffect, useState } from 'react';
// import _ from 'underscore';
import { formatAmount, formatNumber } from 'utils/parser';
import moment from 'moment-timezone';
import { IconButton } from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import _ from 'lodash';

const TreatmentMix = ({ doctorName, selectedYear, summaryData, isEmbedded }) => {
  let title = `${doctorName ? doctorName + "'s " : ''} Report Card (II): Treatment Mix (${
    selectedYear === moment().format('YYYY') ? 'YTD ' : ''
  }${selectedYear})`;
  const [summary, setSummary] = useState([]);

  useEffect(async () => {
    setSummary(await formatData(summaryData));
    if (!isEmbedded) window.scrollTo(0, 0);
  }, [summaryData]);

  useEffect(() => {
    if (!isEmbedded) window.history.replaceState(null, 'Medtrik', '/dr-performance-report/treatment-mix');
  }, []);

  return <SimpleTable title={title} summary={summary} isEmbedded={isEmbedded} />;
};

const SimpleTable = ({ title, summary, isEmbedded }) => {
  const [sortColumn, setSortColumn] = useState('revenue.actual');
  const [sortDirection, setSortDirection] = useState('desc');

  const handleSort = (column, direction) => {
    setSortColumn(column);
    setSortDirection(direction);
  };

  let sortedSummary = [...summary];
  console.log(sortedSummary);
  if (sortColumn) {
    sortedSummary.sort((a, b) => {
      const valA = _.get(a, sortColumn);
      const valB = _.get(b, sortColumn);

      if (typeof valA === 'string') {
        // Sort alphabetically
        const nameA = valA.toUpperCase(); // ignore upper and lowercase
        const nameB = valB.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return sortDirection === 'asc' ? -1 : 1;
        }
        if (nameA > nameB) {
          return sortDirection === 'asc' ? 1 : -1;
        }
      } else {
        // Sort numerically
        if (valA < valB) {
          return sortDirection === 'asc' ? -1 : 1;
        }
        if (valA > valB) {
          return sortDirection === 'asc' ? 1 : -1;
        }
      }
      return 0;
    });

    // #380: make sure 'Total' column is last 
    const totalKey = _.findIndex(sortedSummary, ['name','Total']);
    if (totalKey !== -1) {
      sortedSummary.push(sortedSummary[totalKey]);
      sortedSummary.splice(totalKey, 1);
    }
  }
  
  return (
    <div className="position-relative pt-1 pb-5">
      {!isEmbedded && <div className="page-tittle">{title}</div>}
      <section className={`position-relative${!isEmbedded ? ' px-1 mt-5' : ''}`}>
        <table className="table rounded mb-0 app-font-class-0">
          <thead className="thead-light">
            <tr>
              <th className="p-1" style={{ textTransform: 'none', position: 'relative' }}>
                <span className="ml-10" style={{ paddingRight: '30px' }}>
                  Treatments
                </span>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '0px',
                    position: 'absolute',
                    right: '10px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                  }}
                >
                  <IconButton size="small" onClick={() => handleSort('name', 'asc')} style={{ marginBottom: '-5px' }}>
                    <ArrowDropUpIcon fontSize="inherit" />
                  </IconButton>
                  <IconButton size="small" onClick={() => handleSort('name', 'desc')} style={{ marginTop: '-5px' }}>
                    <ArrowDropDownIcon fontSize="inherit" />
                  </IconButton>
                </div>
              </th>
              <th className="p-1" style={{ textTransform: 'none', position: 'relative' }}>
                <span className="ml-10" style={{ paddingRight: '30px' }}>
                  No. of Rendered
                </span>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '0px',
                    position: 'absolute',
                    right: '10px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                  }}
                >
                  <IconButton
                    size="small"
                    onClick={() => handleSort('noRendered', 'asc')}
                    style={{ marginBottom: '-5px' }}
                  >
                    <ArrowDropUpIcon fontSize="inherit" />
                  </IconButton>
                  <IconButton
                    size="small"
                    onClick={() => handleSort('noRendered', 'desc')}
                    style={{ marginTop: '-5px' }}
                  >
                    <ArrowDropDownIcon fontSize="inherit" />
                  </IconButton>
                </div>
              </th>
              <th className="p-1" style={{ textTransform: 'none', position: 'relative' }}>
                <span className="ml-10" style={{ paddingRight: '30px' }}>
                  Billing Hours
                </span>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '0px',
                    position: 'absolute',
                    right: '10px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                  }}
                >
                  <IconButton
                    size="small"
                    onClick={() => handleSort('hourWorked', 'asc')}
                    style={{ marginBottom: '-5px' }}
                  >
                    <ArrowDropUpIcon fontSize="inherit" />
                  </IconButton>
                  <IconButton
                    size="small"
                    onClick={() => handleSort('hourWorked', 'desc')}
                    style={{ marginTop: '-5px' }}
                  >
                    <ArrowDropDownIcon fontSize="inherit" />
                  </IconButton>
                </div>
              </th>
              <th className="p-1" colSpan={3} style={{ textTransform: 'none' }}>
                Revenue
              </th>
              <th className="p-1" colSpan={3} style={{ textTransform: 'none' }}>
                Revenue/Treatment Hour
              </th>
            </tr>
          </thead>
          {summary?.length > 0 && (
            <tbody>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td className="p-1" style={{ fontWeight: 'bold', position: 'relative' }}>
                  <span style={{ paddingRight: '30px' }}>
                    Actual
                  </span>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      gap: '0px',
                      position: 'absolute',
                      right: '10px',
                      top: '50%',
                      transform: 'translateY(-50%)',
                    }}
                  >
                    <IconButton
                      size="small"
                      onClick={() => handleSort('revenue.actual', 'asc')}
                      style={{ marginBottom: '-5px' }}
                    >
                      <ArrowDropUpIcon fontSize="inherit" />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={() => handleSort('revenue.actual', 'desc')}
                      style={{ marginTop: '-5px' }}
                    >
                      <ArrowDropDownIcon fontSize="inherit" />
                    </IconButton>
                  </div>
                </td>
                <td className="p-1" style={{ fontWeight: 'bold' }}>
                  Target
                </td>
                <td className="p-1" style={{ fontWeight: 'bold' }}>
                  Variance
                </td>
                <td className="p-1" style={{ fontWeight: 'bold' }}>
                  Actual
                </td>
                <td className="p-1" style={{ fontWeight: 'bold' }}>
                  Target
                </td>
                <td className="p-1" style={{ fontWeight: 'bold' }}>
                  Variance
                </td>
              </tr>
              {sortedSummary.map((treatment, index) => (
                // <tr key={index} style={{ backgroundColor: treatment?.name === 'Total' && '#c1dadf' }}>
                <tr key={index}>
                  <td className="text-start p-3" style={{ fontWeight: treatment?.name === 'Total' && 'bold'}}>{treatment?.name}</td>
                  <td className="text-center" style={{ fontWeight: treatment?.name === 'Total' && 'bold'}}>{formatNumber(treatment?.noRendered)}</td>
                  <td className="text-center" style={{ fontWeight: treatment?.name === 'Total' && 'bold'}}>{formatNumber(treatment?.hourWorked)}</td>
                  <td className="text-center" style={{ fontWeight: treatment?.name === 'Total' && 'bold'}}>{formatAmount(treatment?.revenue?.actual)}</td>
                  <td className="text-center" style={{ fontWeight: treatment?.name === 'Total' && 'bold'}}>{formatAmount(treatment?.revenue?.target)}</td>
                  <td className="text-center" style={{ fontWeight: treatment?.name === 'Total' && 'bold'}}>{parseInt(treatment?.revenue?.variance)}%</td>
                  <td className="text-center" style={{ fontWeight: treatment?.name === 'Total' && 'bold'}}>{formatAmount(treatment?.revenuePerHour?.actual)}</td>
                  <td className="text-center" style={{ fontWeight: treatment?.name === 'Total' && 'bold'}}>{formatAmount(treatment?.revenuePerHour?.target)}</td>
                  <td className="text-center" style={{ fontWeight: treatment?.name === 'Total' && 'bold'}}>{parseInt(treatment?.revenuePerHour?.variance)}%</td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </section>
    </div>
  );
};

let formatData = async (data) => {
  if (!data?.treatments?.length) return [];
  let summary = data?.treatments?.map((each) => ({
    name: each?.treatmentName,
    noRendered: each?.totalNoRendered,
    hourWorked: each?.totalHrsWorked,
    revenue: {
      actual: each?.totalRevActual,
      target: each?.totalRevTarget,
      variance: each.totalRevVariance,
    },
    revenuePerHour: {
      actual: each?.totalRevPerTrtmntHrActual,
      target: each?.totalRevPerTrtmntHrTarget,
      variance: each?.totalRevPerTrtmntHrVariance,
    },
  }));

  console.log('Summary treatmentmix (before total): ', summary);

  summary.push({
    name: 'Total',
    noRendered: data?.totalNoRendered,
    hourWorked: data?.totalHrsWorked,
    revenue: {
      actual: data?.totalRevActual,
      target: data?.totalRevTarget,
      variance: data?.totalRevVariance,
    },
    revenuePerHour: {
      actual: data?.totalRevPerTrtmntHrActual,
      target: data?.totalRevPerTrtmntHrTarget,
      variance: data?.totalRevPerTrtmntHrVariance,
    },
  });

  console.log('Summary treatmentmix (after total): ', summary);

  return summary;
};

export default TreatmentMix;
