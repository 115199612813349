/**
 * Scatter plot data and render config
 * - Additional controls: x-axis and y-axis single select drop down
 */

import { Routing } from "routes";
import { useEffect, useState } from "react";

import segmentOrderConfig from "./PatientSegmentationOrderConfig";
import { formatAmount } from "utils/parser";

import { Breadcrumb, Card, Row, Col, Button } from 'react-bootstrap';
import Select from 'react-select';
import Chart from 'react-apexcharts';
import { IoMdInformationCircleOutline } from "react-icons/io";

import "./style.css"
import RFMInfoModal from "./RFMInfoModal";



const RFMScatterPlot = (props) => {
    // 
    // STATES
    // 

    // info modal toggle
    const [ infoToggle, setInfoToggle ] = useState(false);
    // props child -> to pass to the modal component so they can access other props
    const childProps = Object.assign({}, props, {
        infoToggle
    });

    // axes
    const axesOptions = [
        { value: 'recency', label: 'Recency' },
        { value: 'frequency', label: 'Frequency' },
        { value: 'monetary', label: 'Monetary' }
    ];
    const [xAxis, setXAxis] = useState({ value: 'recency', label: 'Recency' });     // set recency as default
    const [yAxis, setYAxis] = useState({ value: 'frequency', label: 'Frequency' });     // set frequency as default

    // chart options
    const baseOptions = {
        chart: {
            type: 'scatter',
            toolbar: {
                show: false
            },
            zoom: {
                enabled: true
            },
            animations: {
                enabled: false
            }
        },
        dataLabels: {
            enabled: false
        },
        responsive: [{}],
        plotOptions: {},
        xaxis: {},
        yaxis: {},
        legend: {
            show: !props.isEmbedded,
            position: 'bottom',
            horizontalAlign: 'center',
            offsetY: 10
        },
        tooltip: {},
        fill: {
            opacity: 1
        },
        colors: []
    };
    const [ chartOptions, setChartOptions ] = useState(baseOptions);

    // chart series
    const [ chartSeries, setChartSeries ] = useState([]);

    // 
    // USE EFFECTS
    // 

    // on first render
    useEffect(() => {
        if(!props.isEmbedded) {
            window.history.replaceState(
                null,
                "Medtrik",
                Routing.RFMScatterPlot.path
            );
            window.scrollTo(0, 0);
        }
    }, []);   

    // on filters change -> call API (done here instead of in index because it also needs the xAxis and yAxis values where the controls for them are inside this file)
    useEffect(() => {
        if (props.selectedDateRange.startDate && props.selectedDateRange.endDate && props.selectedSegments && (props.selectedSegments.length>0)) {
            props.actions.PatientSegmentationActions.getRFMScatterPlot(props.selectedDateRange.startDate, props.selectedDateRange.endDate, props.selectedSegments.map(segment => { return segment.value }), xAxis.value, yAxis.value);
        }
    }, [props.selectedDateRange, props.selectedSegments, xAxis, yAxis]);
    
    // on API data update
    useEffect(() => {
        if (props.rfmScatterPlot && (Array.isArray(props.rfmScatterPlot) && props.rfmScatterPlot.length>0)) {
            // get series ([x, y] format) and x-only series (then get unique x values to decide tickAmount in x-axis)
            let xSeries = [];
            let series = props.rfmScatterPlot.map(cat => {
                return {
                    name: cat.segmentCategory,
                    data: cat.segmentData.map( data => { xSeries.push(data.x); return [data.x, data.y] } )
                }
            });
            console.log("ERROR series: ", series);
            const xUniqueCount = [...new Set(xSeries)].length;

            // get colors array according to the categories' names
            const colors = series.map(obj => segmentOrderConfig[obj.name].color);

            if (series.filter(obj => obj.data.length>0).length>0) {
                // set chart config and series
                setChartOptions({
                    ...baseOptions,
                    xaxis: {
                        type: 'numeric',
                        tickAmount: xUniqueCount ? (xUniqueCount>25 ? 25 : xUniqueCount) : null,
                        title: {
                            text: xAxis.label
                        },
                        // if x-Axis is monetary then format it as currency
                        labels: {
                            formatter: (val) => { return xAxis.value==='monetary' ? formatAmount(val) : parseFloat(val).toFixed(1) }
                        }                
                    },
                    yaxis: {
                        type: 'numeric',
                        title: {
                            text: yAxis.label
                        },
                        // if y-Axis is monetary then format it as currency
                        labels: {
                            formatter: (val) => { return yAxis.value==='monetary' ? formatAmount(val) : parseFloat(val).toFixed(1) }
                        }
                    },
                    colors: colors,
                    tooltip: {
                        // conditionals are done this way so the correct text can be shown when either x/y axis fits the relevant value
                        // ie. no matter whether xAxis='freq' or yAxis='freq' the correct element will be accessed and the text will be shown
                        custom: ({series, seriesIndex, dataPointIndex, w}) => {
                            return (
                                `
                                    <div>
                                        <div class="highlight">
                                            <div class="circle" style="background-color:${segmentOrderConfig[w.config.series[seriesIndex].name].color}"></div>
                                            <p class="adjusted-fontsize-tooltip series-text"> <b> ${w.config.series[seriesIndex].name} </b> </p>
                                        </div>
                                        <div>
                                            ${ (xAxis.value=='recency' ? `<p class="adjusted-fontsize-tooltip"> Recency period: <b> ${w.config.series[seriesIndex].data[dataPointIndex][0]} days </b> </p>` : 
                                                    (yAxis.value=='recency' ? `<p class="adjusted-fontsize-tooltip"> Recency period: <b> ${w.config.series[seriesIndex].data[dataPointIndex][1]} days </b> </p>` : "")
                                                )
                                            }
                                            ${ (xAxis.value=='frequency' ? `<p class="adjusted-fontsize-tooltip"> Visitation frequency: <b> ${w.config.series[seriesIndex].data[dataPointIndex][0]} visits </b> </p>` : 
                                                    (yAxis.value=='frequency' ? `<p class="adjusted-fontsize-tooltip"> Visitation frequency: <b> ${w.config.series[seriesIndex].data[dataPointIndex][1]} visits </b> </p>` : "")
                                                )
                                            }
                                            ${ (xAxis.value=='monetary' ?  `<p class="adjusted-fontsize-tooltip"> Monetary value: <b> ${formatAmount(w.config.series[seriesIndex].data[dataPointIndex][0])} </b> </p>` : 
                                                    (yAxis.value=='monetary' ?  `<p class="adjusted-fontsize-tooltip"> Monetary value: <b> ${formatAmount(w.config.series[seriesIndex].data[dataPointIndex][1])} </b> </p>` : "")
                                                ) 
                                            }
                                    </div>
                                    </div>
                                `
                            )
                        }
                    }
                });
                setChartSeries(series);
            } else {
                setChartSeries([]);
                setChartOptions({
                    ...baseOptions,
                    xaxis: {
                        type: 'numeric',
                        title: {
                            text: xAxis.label
                        }
                    },
                    yaxis: {
                        type: 'numeric',
                        title: {
                            text: yAxis.label
                        }
                    }
                });
            }
        }
    }, [props.rfmScatterPlot]);



    // 
    // RENDER UI
    // 

    return (
        <Row className={!props.isEmbedded ? 'pb-5' : 'pb-4'}>
            {/* AXES FILTERS */}
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                {/* X AXIS */}
                <div style={{ marginTop: 'auto', marginBottom: 'auto', marginRight: '15px' }}>
                    <h6 className='mb-0'>X-Axis: </h6>
                </div>
                <div onClick={(e) => e.stopPropagation()} style={{ width: '200px', fontSize: '16px' }}>
                    <Select
                        value={xAxis['value'].length>0 ? xAxis : null}
                        options={axesOptions}
                        placeholder='X-Axis'
                        onValueClick
                        onChange={(x) => setXAxis(x)}
                    />
                </div>
                {/* divider */}
                <div style={{ width: '60px' }}/>
                {/* Y AXIS */}
                <div style={{ marginTop: 'auto', marginBottom: 'auto', marginRight: '15px' }}>
                    <h6 className='mb-0'>Y-Axis: </h6>
                </div>
                <div onClick={(e) => e.stopPropagation()} style={{ width: '200px', fontSize: '16px' }}>
                    <Select
                        value={yAxis['value'].length>0 ? yAxis : null}
                        options={axesOptions}
                        placeholder='Y-Axis'
                        onChange={(y) => setYAxis(y)}
                    />
                </div>
            </div>
            {/* GRAPH */}
            <Col xs={12} lg={12}>
                <Chart options={chartOptions} series={chartSeries} type="scatter" height={props.isEmbedded ? 350 : 500} />
            </Col>
            {/* INFO MODAL */}
            <div className="info-wrapper" style={{ marginTop: props.isEmbedded ? "-40px" : "0px" }}>
                <div>
                    <IoMdInformationCircleOutline className="info-icon" fontSize={"30px"} onMouseOver={() => setInfoToggle(true)} onMouseOut={() => setInfoToggle(false)}/>
                </div>
                <RFMInfoModal {...childProps} infoToggle={infoToggle}/>
            </div>
        </Row>
    )
};

export default RFMScatterPlot;