import React, { useState, useEffect } from "react";
import { HomeIcon } from "@heroicons/react/solid";
import { Col, Row, Card, Breadcrumb } from 'react-bootstrap';
import { Checkbox } from "@mui/material";

import { Routing } from "routes";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import * as financeActions from "actions/financeActions";
import MonthSlider from "components/filters/MonthSlider";
import { default as Select } from "react-select";
import Chart from "react-apexcharts";
import { components } from "react-select";

import './style.css';
import { MONTH_SLIDER } from "constants/date";
import { format, minTime, parse, sub, startOfMonth } from "date-fns";
import { SYSTEM_DATE_DISPLAY_FORMAT2_DF, SYSTEM_DATE_FORMAT_DF } from "constants/common";
import { formatAmount } from "utils/parser";
import { useChainId } from "hooks/useChainId";

const Option = (props) => {
    return (
        <div>
            <components.Option {...props} className="react-select-option">
                <Checkbox
                    checked={props.isSelected}
                    color="default"
                    sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                />
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};

const FinanceForecast = (props) => {

    const history = useHistory();
    const redirectUrl = (url) => {
        history.push(url);
    };

    const chainId = useChainId()
    const [selectedDate, setSelectedDate] = useState({});
    const [clinics, setClinics] = useState([]);
    const [showFilters, setShowFilters] = useState([]);
    const [selectedClinics, setSelectedClinics] = useState([]);
    const [clinicIds, setClinicIds] = useState([]);
    const [chartOptions, setChartOptions] = useState({
        chart: {
            type: 'rangeBar',
            height: 350
        },
        legend: {
            show: false
        },
        plotOptions: {
            bar: {
                horizontal: false,
                dataLabels: {
                    position: 'top'
                }
            },
        },
        fill: {
            opacity: 0,
        },
        xaxis: {
            labels: {
                style: {
                    fontSize: '14px'
                }
            }
        },
        yaxis: {
            labels: {
                formatter: (value) => formatAmount(value),
                style: {
                    fontSize: '14px'
                }
            },
        },
        colors: ['#C7C7C7', '#53C1DE'],
        stroke: {
            colors: ["transparent"],
            width: 25
        },
        dataLabels: {
            enabled: true,
            style: {
                colors: ['#333'],
                fontSize: '14px'
            },
            offsetY: -42,
            formatter: function (val, opt) {
                if (opt.seriesIndex === 1) {
                    return ['Revenue Gap:', formatAmount(opt.w.globals.series[0][opt.dataPointIndex + 1] - opt.w.globals.series[0][opt.dataPointIndex])];
                } else {
                    return '';
                }
            }
        },
        tooltip: {
            custom: function({ series, seriesIndex, dataPointIndex, w }) {
                return (
                    `<div>
                        <p> ${w.globals.labels[dataPointIndex]}: <b>${formatAmount(series[0][dataPointIndex])}</b> </p>` +
                        (series[0][dataPointIndex + 1] ? `<p> Revenue Gap: <b>${formatAmount(series[0][dataPointIndex + 1] - series[0][dataPointIndex ])}</b> </p>` : '') +
                    `</div>`
                );
            }
        }
    });

    const [chartData, setChartData] = useState([]);
    const [chartSeries, setChartSeries] = useState([{ data: [] }, { data: [] }]);

    const handleShowFilterChange = (selectedOptions) => {
        if (!selectedOptions || !selectedOptions.length) return;
        setChartSeries([{ ...chartSeries[0], data: [] }, { ...chartSeries[1], data: [] }]);
        let chartSeriesData = [];
        for (let selectedOption of selectedOptions) {
            if (selectedOption.value == 1) {
                chartSeriesData.push(chartData[0])
            }
            if (selectedOption.value == 2) {
                chartSeriesData.push(chartData[1])
            }
            // if(selectedOption.value == 3) {
            //     chartSeriesData.push(chartData[2])
            // }
            if (selectedOption.value == 4) {
                chartSeriesData.push(chartData[2])
            }
        }
        const series1 = [];
        const series2 = [];
        chartSeriesData.forEach((forecast, index) => {
            series1.push({
                x: forecast.x,
                y: forecast.y,
            });

            // #358: change the y value to [value of current bar, value of the next bar]
            if (chartSeriesData[index + 1]) {
                // const revenueGap = Number(chartSeriesData[index + 1].y[1]) - Number(forecast.y[1]);
                series2.push({
                    x: forecast.x,
                    // y: [revenueGap < 0 ? 0 : Number(forecast.y[1]), Math.abs(revenueGap)]
                    y: [Number(forecast.y[1]), Number(chartSeriesData[index + 1].y[1])]
                });
            }
        });
        setChartSeries([{ ...chartSeries[0], data: series1 }, { ...chartSeries[1], data: series2 }]);
    };

    const handleMonthChange = (date) => {
        setSelectedDate(date);
    };

    const handleClinicChange = (selectedClinics) => {
        let selectedClinicIds = selectedClinics.map(selectedClinic => selectedClinic.value.toUpperCase());
        if (selectedClinicIds.includes('ALL CLINICS')) {
            selectedClinicIds = props.clinics.map(clinic => clinic.clinicId.toUpperCase());
            setSelectedClinics([{ value: 'All Clinics', label: 'All Clinics' }]);
        }
        else {
            setSelectedClinics(selectedClinics);
        }
        setClinicIds(selectedClinicIds);
    };

    useEffect(() => {
        if (chainId)
            props.actions.financeActions.getClinics(chainId);
    }, [chainId]);

    useEffect(() => {
        let forecastFilters = [];
        if (props.revenueForecastShow) {
            props.revenueForecastShow.forEach(filter => {
                forecastFilters.push({ value: filter.showId, label: filter.showName })
            });
            setShowFilters(forecastFilters);
        }
    }, [props.revenueForecastShow]);

    useEffect(() => {
        let clinics = [];
        if (props.user.Role.id == 3 && props.user.clinic) {
            props.clinics.forEach(clinic => {
                if (props.user.clinic.clinicId == clinic.clinicId)
                    clinics.push({ value: clinic.clinicId, label: clinic.clinicId })
            });
        }
        else {
            clinics = [{ value: 'All Clinics', label: 'All Clinics' }];
            props.clinics.forEach(clinic => {
                clinics.push({ value: clinic.clinicId, label: clinic.clinicId })
            });
            setClinics(clinics);
        }
        handleClinicChange(clinics);
    }, [props.clinics]);

    useEffect(() => {
        if (clinicIds.length) {
            props.actions.financeActions.getFinanceRevenueForecast(chainId, clinicIds, selectedDate.startDate, selectedDate.endDate);
            props.actions.financeActions.getFinanceRevenueForecastShow(chainId, clinicIds);
        }
    }, [chainId, clinicIds, selectedDate]);

    useEffect(() => {
        if (props.revenueForecastData.revenueForecast) {
            const series1 = [];
            const series2 = [];
            props.revenueForecastData.revenueForecast.forEach((forecast, index) => {
                series1.push({
                    x: forecast.label,
                    y: [0, Number(forecast.value)]
                });

                // #358: change the y value to [value of current bar, value of the next bar]
                if (props.revenueForecastData.revenueForecast[index + 1]) {
                    // const revenueGap = Number(props.revenueForecastData.revenueForecast[index + 1].value) - Number(forecast.value);
                    series2.push({
                        x: forecast.label,
                        // y: [revenueGap < 0 ? 0 : Number(forecast.value), Math.abs(revenueGap)]
                        y: [Number(forecast.value), Number(props.revenueForecastData.revenueForecast[index + 1].value)]
                    });
                }
            });
            setChartSeries([{ ...chartSeries[0], data: series1 }, { ...chartSeries[1], data: series2 }]);
            setChartData(series1);
            setChartOptions({ ...chartOptions, fill: { opacity: 1 } });
        }
    }, [props.revenueForecastData]);

    return (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-md-0">
                    <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
                        <Breadcrumb.Item onClick={() => redirectUrl(Routing.Dashboard.path)}><HomeIcon className="icon icon-xs" /></Breadcrumb.Item>
                        <Breadcrumb.Item active>Forecast</Breadcrumb.Item>
                    </Breadcrumb>
                    <h4>Revenue Forecast</h4>
                </div>
            </div>
            <Card border="0" className="table-wrapper table-responsive shadow">
                <Card.Body>
                    <Row className="finance-dashboard">
                        <Col xs={12} lg={3}>
                            {props.user.Role.id != 3 &&
                                <Select
                                    value={selectedClinics}
                                    className="medtrik-dropdown"
                                    placeholder="Select Clinic(s)..."
                                    isMulti
                                    components={{ Option }}
                                    onChange={handleClinicChange}
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    options={clinics} />
                            }
                        </Col>
                        <Col xs={12} lg={3}>
                            <Select
                                className="medtrik-dropdown"
                                onChange={handleShowFilterChange}
                                placeholder="Show:"
                                isMulti
                                components={{ Option }}
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                options={showFilters}
                                isOptionDisabled={(option) => option.label === 'F3 Statistical Prediction'} />
                        </Col>
                        <Col xs={12} lg={1}></Col>
                        <Col xs={12} lg={5} className="justify-content-end text-end mb-6 px-4">
                            <h6 className="m-0">Selected Month Range {selectedDate?.startDate && format(parse(selectedDate.startDate, SYSTEM_DATE_FORMAT_DF, minTime), SYSTEM_DATE_DISPLAY_FORMAT2_DF)} - {selectedDate?.endDate && format(parse(selectedDate.endDate, SYSTEM_DATE_FORMAT_DF, minTime), SYSTEM_DATE_DISPLAY_FORMAT2_DF)}</h6>
                            <MonthSlider {...Object.assign({}, MONTH_SLIDER, { display: true, reverse: true, startDate: startOfMonth(sub(Date.now(), { months: 1 })), type: 2, max: 24 })} handleMonthChange={handleMonthChange} />
                        </Col>
                        <Col xs={12} lg={12} className="px-8">
                            <Chart options={chartOptions} series={chartSeries} type="rangeBar" height={350} />
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </>
    )
}

const mapStateToProps = (state) => {
    const { adminAuthReducer, financeReducer } = state;
    return {
        user: adminAuthReducer.adminUser,
        clinics: financeReducer.clinics,
        revenueForecastData: financeReducer.revenueForecastData,
        revenueForecastShow: financeReducer.revenueForecastShow,
    }
};

const mapDispatchToProps = dispatch => ({
    actions: {
        financeActions: bindActionCreators(financeActions, dispatch)
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(FinanceForecast)
