import React, { useEffect, useState, useMemo } from "react";
import { Form, Card, Button, Row, Col, Table } from "react-bootstrap";

import { Routing } from "routes";
import { bindActionCreators } from 'redux';
import { connect, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import * as kpiPlannerAction from "actions/kpiPlannerAction";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { makeStyles } from "@mui/styles";
import { Slider } from "@mui/material";
import Loader from "components/Loader"
import { numberWithCommas, roundToTwo } from "utils/parser.js"
import swal from 'sweetalert';
import decode from "jwt-decode";
import _ from "lodash";
import {
    KPI_STATUS_CONFIRMED,
    KPI_STATUS_NOT_STARTED,
    KPI_STATUS_PROPOSED,
    KPI_STATUS_TO_CONFIRM
} from "../../constants/actionsTypes";
import clsx from 'clsx'

const useStyles = makeStyles(() => ({
    root: {
        "& .Mui-active .MuiStepIcon-root": { color: "white" },
        "& .Mui-completed .MuiStepIcon-root": { color: "#2f4858" },
        "& .Mui-disabled .MuiStepIcon-root": { color: "#dfe4ed" },
        "& .MuiStepLabel-root .MuiStepLabel-label": { fontFamily: 'Poppins', fontSize: '1em', paddingBottom: '1em', fontWeight: '500', color: '#2f4858' }
    },
    cols: {
        paddingLeft: '2em',
        paddingRight: '2em'
    }
}));
const DoctorLevel = ({ kpiProgress, step, nextStep, setName, prevStep, handleFormData, values, getDoctorKPIPlanningData, updateDocktorKpi, singleProcess, viewMode, getReferences, getAllocates, year }) => {
    const classes = useStyles();
    const history = useHistory();
    const doctorKPIData = useSelector(state => state.kpiPlannerReducer.doctorKPIData);
    const doctorReference = useSelector((state) => state.kpiPlannerReducer.referencesClinic);
    const allocate = useSelector((state) => state.kpiPlannerReducer.allocatedByClinic)
    const [treatmentsData, setTreatmentsData] = useState([])
    const [loading, setLoading] = useState(true)

    const queryParams = new URLSearchParams(window.location.search);
    const confirmMode = queryParams.get('confirmMode');

    const steps = [
        'Propose Chain Target',
        'Propose Clinic Target',
        'Propose Doctor Target',
        'Confirm Doctor Target',
        'Confirm Clinic Target',
        'Confirm Chain Target'
    ];
    const [optionReference, setOptionReference] = useState(doctorKPIData?.reference?._id);
    const [allocateBy, setAllocateBy] = useState(doctorKPIData?.allocate?._id);
    const submitFormData = (e) => {
        e.preventDefault();
        updateDocktorKpi(1, "confirmed");
        setTimeout(() => { prevStep1() }, 5000);
        // prevStep1();
    }
    const prevStep1 = () => {
        history.push(Routing.KpiPlanner.path);
    }

    const doctorId = useMemo(() => {
        const accessToken = localStorage.getItem("accessToken")
        if (accessToken !== "undefined" && accessToken !== null) {
            const userProfile = decode(accessToken)
            const user = userProfile.user;
            if (user.doctor) {
                return user.doctor.doctorId;
            }
        }
    }, [])

    const [marks, setMarks] = useState([]);
    const [maxMark, setMaxMark] = useState(0);

    const [targetTotalRevenue, setTargetTotalRevenue] = useState(doctorKPIData?.targetTtlRev)
    const [targetTotalHours, setTargetTotalHours] = useState(doctorKPIData?.targetTtlHrs)
    const [targetRevenueHours, setTargetRevenueHours] = useState(doctorKPIData?.targetRevHrs)

    const [errors, setErrors] = useState({
        targetTotalRevenue: null,
        targetTotalHours: null
    });

    const handleChangeOptionsReference = (e) => {
        const optionReference = e.target.value
        setOptionReference(Number(optionReference))
        setLoading(true)

        const queryParams = new URLSearchParams(window.location.search);
        const _id = queryParams.get('_id');
        getDoctorKPIPlanningData(doctorId, Number(optionReference), Number(_id), year);
    }

    const handleChangeAllocateInput = (e) => {
        setAllocateBy(e.target.value)
        const totalRevenueHours = Number(targetTotalHours) ? Number(targetTotalRevenue) / Number(targetTotalHours) : 0
        let shallowTreatmensData = _.cloneDeep(treatmentsData)
        shallowTreatmensData = changeDataTreatementAcordingToAllocateId(shallowTreatmensData, Number(targetTotalRevenue), Number(targetTotalHours), totalRevenueHours, Number(e.target.value))
        shallowTreatmensData = _.sortBy(shallowTreatmensData, ['referenceTotalRevenue']).reverse()
        setTreatmentsData(shallowTreatmensData)
    }

    const validateFormData = (data) => {
        let errors = {};
        let formIsValid = true;
        // inputAmount

        if (!data?.targetTotalRevenue) {
            errors.targetTotalRevenue = "Please type total hours"
        }

        // referenceId

        if (!data?.targetTotalHours) {
            errors.targetTotalHours = "Please type  total hours"
        }

        if (Object.keys(errors)?.length) {
            formIsValid = false;
            setErrors(errors)
        } else {
            setErrors({
                targetTotalRevenue: null,
                targetTotalHours: null
            })
        }

        return formIsValid
    }

    const handleInputTargetTotalRevenue = (e) => {
        let { value } = e.target
        let shallowTreatmensData = _.cloneDeep(treatmentsData)
        const totalRevenueHours = Number(targetTotalHours) ? value / targetTotalHours : 0
        shallowTreatmensData = changeDataTreatementAcordingToAllocateId(shallowTreatmensData, Number(value), Number(targetTotalHours), totalRevenueHours, Number(allocateBy))
        shallowTreatmensData = _.sortBy(shallowTreatmensData, ['referenceTotalRevenue']).reverse()
        setTreatmentsData(shallowTreatmensData)
        setTargetTotalRevenue(e.target.value)
    }

    const handleInputTargetTotalHours = (e) => {
        let { value } = e.target
        setMaxMark(Number(value))
        const shallowMarks = _.cloneDeep(marks)
        shallowMarks[1] = {
            value: Number(value),
            label: `${Number(value)} Hours`
        }
        setMarks(shallowMarks)
        // let shallowTreatmensData = _.cloneDeep(treatmentsData)
        const totalRevenueHours = Number(value) ? targetTotalRevenue / value : 0
        // shallowTreatmensData = changeDataTreatementAcordingToAllocateId(shallowTreatmensData, Number(targetTotalRevenue), Number(value), totalRevenueHours, Number(allocateBy))
        // setTreatmentsData(shallowTreatmensData)
        setTargetTotalHours(e.target.value)
        setTargetRevenueHours(totalRevenueHours)
    }

    const handleInputTargetRevenueHours = (e) => {
        let { value } = e.target
        let shallowTreatmensData = _.cloneDeep(treatmentsData)
        shallowTreatmensData = shallowTreatmensData.map(el => {
            if (!el.percentage) {
                return {
                    ...el,
                    percentage: 100,
                    targetRevenueHours: Number(value)
                }
            } else {
                return {
                    ...el,
                    targetRevenueHours: el.percentage * Number(value) / 100
                }
            }

        })
        shallowTreatmensData = _.sortBy(shallowTreatmensData, ['referenceTotalRevenue']).reverse()
        setTreatmentsData(shallowTreatmensData)
        setTargetRevenueHours(e.target.value)
    }

    const changeDataTreatementAcordingToAllocateId = (tempTreatmentData, totalRevenue, totalHours, totalRevenueHours, allocateId) => {
        let shallowListTreatments = _.cloneDeep(tempTreatmentData)
        if (allocateId === 1) {
            const totalTreatments = treatmentsData.length;
            let percentage = roundToTwo(100 / totalTreatments);
            if (percentage > 100) {
                percentage = 100
            }
            let totalTargetHours = roundToTwo(targetTotalHours / totalTreatments);
            if (totalTargetHours > targetTotalHours) {
                totalTargetHours = targetTotalHours
            }
            shallowListTreatments = shallowListTreatments.map(el => {
                return {
                    ...el,
                    percentage: percentage,
                    targetTotalHours: totalTargetHours,
                    targetRevenueHours: doctorKPIData?.targetRevHrs
                }
            })
        } else if (allocateId === 2 && doctorKPIData?.refRevHrs) {
            shallowListTreatments = shallowListTreatments.map(el => {
                let percentage = roundToTwo(el.referenceRevenueHours / doctorKPIData.refRevHrs * 100);
                if (percentage > 100) {
                    percentage = 100
                }
                let totalTargetHours = roundToTwo(el.referenceRevenueHours / doctorKPIData.refRevHrs * targetTotalHours);
                if (totalTargetHours > targetTotalHours) {
                    totalTargetHours = targetTotalHours
                }
                return {
                    ...el,
                    percentage: percentage,
                    targetTotalHours: totalTargetHours,
                    targetRevenueHours: doctorKPIData?.targetRevHrs
                }
            })
        } else if (allocateId === 4) {
            shallowListTreatments = shallowListTreatments.map(el => {
                return {
                    ...el,
                    targetTotalHours: el.percentage,
                    targetRevenueHours: doctorKPIData?.targetRevHrs
                }
            })
        }

        shallowListTreatments = shallowListTreatments.map(el => {
            return {
                ...el,
                targetTotalRevenue: roundToTwo(roundToTwo(el.referenceTotalRevenue / el.referenceTotalHours) * el.targetTotalHours),
            }
        })

        return shallowListTreatments
    }

    const handleChangeRanges = (e) => {
        const { name: index, value } = e.target
        let shallowTreatments = _.cloneDeep(treatmentsData)

        shallowTreatments[index].percentage = value
        shallowTreatments[index].targetTotalRevenue = roundToTwo(value * roundToTwo(shallowTreatments[index].referenceTotalRevenue / shallowTreatments[index].referenceTotalHours))

        shallowTreatments[index].targetTotalHours = value
        if (shallowTreatments[index].targetTotalHours) {
            shallowTreatments[index].targetRevenueHours = roundToTwo(shallowTreatments[index].targetTotalRevenue / shallowTreatments[index].targetTotalHours)
        }
        shallowTreatments = _.sortBy(shallowTreatments, ['referenceTotalRevenue']).reverse()
        setTreatmentsData(shallowTreatments)
        setAllocateBy(4)
    }

    const handleTotalRevenue = (data, numberReturn = false) => {
        if (data && data.length) {
            const total = data.reduce((previousValue, currentValue) => {
                return previousValue + Number(currentValue.targetTotalRevenue)
            }, 0)
            return numberReturn ? roundToTwo(total) : numberWithCommas(roundToTwo(total))
        } else {
            return 0
        }
    }

    const handleTotalHours = (data, numberReturn = false) => {
        if (data && data.length) {
            const total = data.reduce((previousValue, currentValue) => {
                return previousValue + Number(currentValue.targetTotalHours)
            }, 0)
            return numberReturn ? roundToTwo(total) : numberWithCommas(roundToTwo(total))
        } else {
            return 0
        }
    }

    const sendAPiPostDoctorProposeKpi = (e) => {
        const dataRequest = {
            "_id": doctorKPIData?._id,
            "kpiStatusId": KPI_STATUS_TO_CONFIRM,
            "doctorId": doctorKPIData.doctorId,
            "referenceId": doctorKPIData.reference._id,
            "allocateId": allocateBy,
            "referenceTotalRevenue": doctorKPIData?.refTtlRev,
            "referenceTotalHours": doctorKPIData?.refTtlHrs,
            "targetTotalHours": parseInt(targetTotalHours),
            "targetTotalRevenue": handleTotalRevenue(treatmentsData, true),
            "referenceRevenueHours": doctorKPIData?.refRevHrs,
            "targetRevenueHours": targetRevenueHours,
            "treatments": treatmentsData
        }
        e.preventDefault();
        if (validateFormData(dataRequest)) {
            setLoading(true);
            kpiPlannerAction.postUpdateDoctorKpiConfirm(dataRequest).then(response => {
                setLoading(false)
                if (response) {
                    swal(response.message, {
                        icon: "success",
                    }).then(() => {
                        prevStep1()
                    });
                }
            })
        }
    }

    useEffect(() => {
        setName(doctorKPIData?.doctorName);
        getReferences()
        getAllocates()
    }, []);

    useEffect(() => {
        if (optionReference) {
            const queryParams = new URLSearchParams(window.location.search);
            const _id = queryParams.get('_id');
            getDoctorKPIPlanningData(doctorId, Number(optionReference), Number(_id), year);
        }
    }, [optionReference])

    useEffect(() => {
        if (!_.isEmpty(doctorKPIData)) {
            let shallowTreatment = _.cloneDeep(doctorKPIData.treatments)
            shallowTreatment = shallowTreatment.map(el => {
                return {
                    treatmentId: el.treatmentId,
                    treatmentName: el.treatmentName,
                    referenceTotalRevenue: el.trtmntRefTtlRev,
                    referenceTotalHours: el.trtmntRefTtlHrs,
                    referenceRevenueHours: el.trtmntRefRevHrs,
                    targetTotalRevenue: el.trtmntTargetTtlRev,
                    targetTotalHours: el.trtmntTargetTtlHrs,
                    targetRevenueHours: el.trtmntTargetRevHrs,
                    percentage: el?.percentage || 0
                }
            })
            setTreatmentsData(shallowTreatment)
            setTargetTotalRevenue(doctorKPIData?.targetTtlRev)
            setTargetTotalHours(doctorKPIData?.targetTtlHrs)
            setTargetRevenueHours(doctorKPIData?.targetRevHrs)

            setOptionReference(doctorKPIData?.reference?._id)
            setAllocateBy(doctorKPIData?.allocate?._id)
            setMarks(
                [
                    {
                        value: 0,
                        label: '0 Hour',
                    },
                    {
                        value: doctorKPIData?.targetTtlHrs,
                        label: `${doctorKPIData?.targetTtlHrs} Hours`,
                    },
            ])
            setMaxMark(doctorKPIData?.targetTtlHrs)

            // if (doctorKPIData?.status?._id < KPI_STATUS_TO_CONFIRM && !viewMode) {
            //     const totalRevenueHours = Number(targetTotalHours) ? targetTotalRevenue / targetTotalHours : 0
            //     let shallowTreatmensData = changeDataTreatementAcordingToAllocateId(shallowTreatment, Number(targetTotalRevenue), Number(targetTotalHours), totalRevenueHours, Number(doctorKPIData?.allocate?._id))
            //     setTreatmentsData(shallowTreatmensData)
            // }
            setLoading(false)
        }
    }, [doctorKPIData])

    useEffect(() => {
        if (viewMode) {
            const queryParams = new URLSearchParams(window.location.search);
            const _id = queryParams.get('_id');
            getDoctorKPIPlanningData(queryParams.get('doctorId'), null, Number(_id), year);
        }
    }, [viewMode])

    const getButtonLabel = () => {
        switch (doctorKPIData.status._id) {
            case 1: return 'Propose';
            case 2: return 'Update';
            case 3: return 'Confirm';
            case 4: return 'Confirmed';
            default: return 'N/A';
        }
    }

    const confirmKpi = (id) => {
        swal("Confirm this KPI?", {
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((confirmed) => {
            if (confirmed) {
                setLoading(true);
                kpiPlannerAction.confirmKpi(id).then(response => {
                    setLoading(false)
                    if (response) {
                        swal(response.message, {
                            icon: "success",
                        }).then(() => {
                            history.push({
                                pathname: Routing.KpiPlanner.path
                            })
                        })
                    }
                })
            }
        });
    }

    if (loading) {
        return <Loader></Loader>
    }

    return (
        <>
            {updateDocktorKpi?.success &&
                <div className="alert alert-success" role="alert">
                    {updateDocktorKpi.message}
                </div>
            }

            <Box sx={{ width: '100%' }}>
                <div className="row">
                    <div className="col-12 mb-3">
                        <div className="wrapper-progressBar">
                            <ul className="progressBar">
                                {steps.map((el, index) =>
                                    <li
                                        key={index}
                                        className={clsx({
                                            'finalize-progress': kpiProgress && kpiProgress[Number(index) + 1],
                                            'selected-progress':
                                                index === 2 && doctorKPIData?.status?._id === KPI_STATUS_NOT_STARTED
                                                || index === 3 && doctorKPIData?.status?._id === KPI_STATUS_PROPOSED
                                                || index === 3 && doctorKPIData?.status?._id === KPI_STATUS_TO_CONFIRM
                                        })}>
                                        {el}
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
            </Box>            
            <Form onSubmit={submitFormData} style={{ height: '75vh' }}>
                <Card className="table-wrapper shadow mb-3">
                    <Card.Body>
                        <Row className="chain-level" style={{ fontFamily: 'Poppins', fontWeight: '500' }}>
                            <Col sm={12} md={12} lg={3} className={classes.cols}>
                                <Form.Group id="Reference" className="mb-4">
                                    <Form.Label> Select reference: </Form.Label>
                                    <Form.Select
                                        className="mb-4" id="financialYears" value={optionReference}
                                        disabled={true}
                                        onChange={(e) => handleChangeOptionsReference(e)}
                                    >
                                        {doctorReference?.references.map((item, index) => (
                                            <option key={index} value={item.referenceId}>{item.referenceName}</option>
                                        ))}

                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col sm={12} md={12} lg={9}>
                                <Row className="align-items-end">
                                    <Col sm={12} md={12} lg={9} className="mb-md-3 mb-lg-0">
                                        <Form.Label>{doctorKPIData?.doctorName}: KPI</Form.Label>
                                        <Table className="align-items-center table-bordered table-condensed">
                                            <thead>
                                                <tr className="table-active">
                                                    <th></th>
                                                    <th>Total Revenue</th>
                                                    <th>Total Hours</th>
                                                    <th>Revenue /Hours</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className="table-active">
                                                    <td>Reference:</td>
                                                    <td>${numberWithCommas(roundToTwo(doctorKPIData?.refTtlRev))}</td>
                                                    <td>{numberWithCommas(roundToTwo(doctorKPIData?.refTtlHrs))}</td>
                                                    <td>${numberWithCommas(roundToTwo(doctorKPIData?.refRevHrs))}</td>
                                                </tr>
                                                <tr className="table-active">
                                                    <td>Input Target:</td>
                                                    <td>
                                                        ${numberWithCommas(roundToTwo(doctorKPIData?.targetTtlRev))}
                                                    </td>
                                                    <td>
                                                        <Form.Group controlId="inputTarget">
                                                            <Form.Control
                                                                type="number"
                                                                min="0"
                                                                required={true}
                                                                disabled={viewMode || doctorKPIData.status._id >= KPI_STATUS_TO_CONFIRM}
                                                                value={targetTotalHours}
                                                                onChange={(e) => handleInputTargetTotalHours(e)}
                                                            />
                                                        </Form.Group>
                                                        {
                                                            errors?.targetTotalHours && <span style={{ color: 'red', fontSize: '15px' }}>
                                                                {errors.targetTotalHours}
                                                            </span>
                                                        }
                                                    </td>
                                                    <td >
                                                        ${numberWithCommas(roundToTwo(targetRevenueHours))}
                                                    </td>
                                                </tr>
                                                <tr className="table-active">
                                                    <td>Adjusted Target:</td>
                                                    <td >
                                                        ${handleTotalRevenue(treatmentsData, false)}
                                                    </td>
                                                    <td>
                                                        {handleTotalHours(treatmentsData, true)}
                                                    </td>
                                                    <td >
                                                        ${numberWithCommas(roundToTwo(handleTotalRevenue(treatmentsData, true) / handleTotalHours(treatmentsData, true)))}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Col>
                                    <Col sm={12} md={12} lg={3} className="button-color text-end">
                                        {!viewMode &&
                                            <Button
                                                onClick={sendAPiPostDoctorProposeKpi}
                                                variant="primary"
                                                className="animate-up-2 btn btn-gray-800 btn-lg float-none ms-5"
                                                type="submit"
                                                disabled={viewMode || doctorKPIData.status._id > KPI_STATUS_TO_CONFIRM}>
                                                {getButtonLabel()}
                                            </Button>
                                        }
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                <Card className="table-wrapper shadow mb-4" style={{ overflow: 'scroll', height: '33vh' }}>
                    <Card.Body>
                        {_.sortBy(treatmentsData, ['referenceTotalRevenue']).reverse().map((item, index) => (
                            <Row key={index} className="chain-level pt-5 pb-5" style={{ fontFamily: 'Poppins', fontWeight: '500' }}>
                                <Col xs={12} md={12} lg={8} xl={8} className="table-responsive me-6">
                                    <Form.Label>{item.treatmentName}</Form.Label>
                                    <Table className="align-items-center table-bordered">
                                        <thead>
                                            <tr className="table-active">
                                                <th></th>
                                                <th>Total Revenue</th>
                                                <th>Total Hours</th>
                                                <th>Revenue /Hours</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className="table-active">
                                                <td>Reference:</td>
                                                <td>${numberWithCommas(roundToTwo(item.referenceTotalRevenue))}</td>
                                                <td>{numberWithCommas(roundToTwo(item.referenceTotalHours))}</td>
                                                <td rowSpan={2}>${numberWithCommas(roundToTwo(item.referenceTotalRevenue / item.referenceTotalHours))} </td>
                                            </tr>
                                            <tr className="table-active">
                                                <td>Target:</td>
                                                <td>${numberWithCommas(roundToTwo(item.targetTotalRevenue))}</td>
                                                <td>{numberWithCommas(roundToTwo(item.targetTotalHours))}</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Col>
                                <Col xs={12} md={12} lg={3} xl={3} className="mt-6">
                                    <Slider
                                        value={item.targetTotalHours}
                                        onChange={handleChangeRanges}
                                        name={index}
                                        min={0} max={maxMark}
                                        marks={marks}
                                        valueLabelDisplay="on"
                                        disabled={viewMode || doctorKPIData.status._id > KPI_STATUS_PROPOSED}
                                    />
                                </Col>
                            </Row>
                        ))}

                        <Row className="justify-content-center clinic-chain-level pt-1 pb-1" style={{ fontFamily: 'Poppins', fontWeight: '500' }}>
                            <Col xs={12} lg={4} className={classes.cols}>
                                <Form.Group id="AllocatedBy" className="mb-4">
                                    <Form.Label> Allocate By: </Form.Label>
                                    <Form.Select value={allocateBy}
                                        onChange={handleChangeAllocateInput}
                                        className="mb-4" id="financialYears" disabled={viewMode}>
                                        {allocate?.allocateBy.map((item, index) => (
                                            <option key={index} value={item.allocateById}>{item.allocateByName}</option>

                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col xs={12} lg={8}></Col>
                        </Row>


                        <Row className="justify-content-center pt-5 pb-5" style={{ fontFamily: 'Poppins', fontWeight: '500' }}>
                            <Col xs={12} lg={9}></Col>
                            <Col xs={12} lg={3} className="button-color">
                                <Table className="align-items-center mb-2">
                                    <tbody>
                                        <tr className="table-active">
                                            <td>Total:</td>
                                            <td>${handleTotalRevenue(treatmentsData)}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                                <div style={{ display: "flex", justifyContent: "space-around" }}>
                                    {/* <Button variant="primary" className="animate-up-2 btn btn-gray-800 btn-lg" onClick={updateDocktorKpi(1,"Confirmed")}> Back </Button> */}
                                    {viewMode &&
                                        <>
                                            {confirmMode
                                                ?
                                                    <Button onClick={() => confirmKpi(doctorKPIData?._id)}
                                                            variant="primary" className="animate-up-2 btn btn-gray-800 btn-lg" type="submit"
                                                    > Confirm </Button>
                                                :
                                                    <Button
                                                        variant="primary"
                                                        onClick={() => {
                                                            history.push({
                                                                pathname: Routing.KpiPlanner.path,
                                                            })
                                                        }}
                                                        className="animate-up-2 btn btn-gray-800 btn-lg"
                                                        type="submit"
                                                        style={{ marginLeft: "1em" }}
                                                    >
                                                        Back to Dashboard
                                                    </Button>
                                            }
                                        </>
                                    }
                                </div>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Form>
                
        </>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        getDoctorKPIPlanningData: (doctorId, referenceId, _id, year) => dispatch(kpiPlannerAction.getDoctorKPIPlanningData(doctorId, referenceId, _id, year)),
        getReferences: () => dispatch(kpiPlannerAction.getReferenceByClinic()),
        getAllocates: () => dispatch(kpiPlannerAction.getAllocatedByClinic())
    }
};

export default connect(null, mapDispatchToProps)(DoctorLevel)
